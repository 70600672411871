import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { createSelector } from 'reselect';
import _get from 'lodash/get';

import Select from '../../../superfield/form/SuperSelect';
import Toggle from '../../../formFields/Toggle';
import RequiredCriteriaToggle from './RequiredCriteriaToggle';

import { valueHoldersToValues } from '../../../forms/FormUtils';
import { getProgress } from '../Utils';
import { EBool } from '../../../../constants/enum';
import { selectOpportunityPostToEdit } from '../../../../selectors/opportunityBuilder';

export const ID = 'opportunityEducationPartForm';

const messages = {
  requiredCriteriaLabel: 'Toto kritérium MUSÍ splňovat',
  suitableForStudentsLabel: 'Vhodné pro studující',
  minimumEducationLabel: 'Dosažené minimální vzdělání',
  educationFieldsLabel: 'Vzdělání v oborech'
};

const EducationPart = props => {
  const { dataSources, handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-sm-6">
          <div className="mui-fields-container">
            <Field
              name="suitableForStudents"
              component={Toggle}
              label={messages.suitableForStudentsLabel}
              labelPosition="right"
            />
          </div>
        </div>
      </div>
      {/*<div className="row">*/}
      {/*  <div className="col-sm-12">*/}
      {/*    <Field*/}
      {/*      name="minimumEducation"*/}
      {/*      label={messages.minimumEducationLabel}*/}
      {/*      component={Select}*/}
      {/*      dataSource={dataSources.educationLevels}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  <div className="col-sm-6">*/}
      {/*    <div className="mui-padded-horizontal">*/}
      {/*      <Field*/}
      {/*        name="minimumEducationRequired"*/}
      {/*        component={RequiredCriteriaToggle}*/}
      {/*        label={messages.requiredCriteriaLabel}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="educationFields"
            label={messages.educationFieldsLabel}
            component={Select}
            dataSource={dataSources.educationFields}
            multi
            filterable
          />
        </div>
        <div className="col-sm-6">
          <div className="mui-padded-horizontal">
            <Field
              name="educationFieldsRequired"
              component={RequiredCriteriaToggle}
              label={messages.requiredCriteriaLabel}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

const validate = () => {
  return {};
};

const removeHeader = ds => {
  const toQ = (q, parent, items) => {
    for (let i = 0; i < items.length; i += 1) {
      q.push({ item: items[i], parent });
    }
    return q;
  }
  const q = toQ([], null, ds);
  const res = [];
  while (q.length) {
    const { item: { header, ...newItem }, parent } = q.shift();
    if (newItem.children?.length) {
      toQ(q, newItem, newItem.children);
      newItem.children = [];
    }
    if (!parent) {
      res.push(newItem);
    } else {
      parent.children.push(newItem);
    }
  }
  return res;
};

const baseLocalSelectDataSources = state => state.options.dataSources.all;
const localSelectDataSources = createSelector([baseLocalSelectDataSources], ds => {
  // const enumMessages = intl.messages.constants.enums;
  return {
    // educationLevels: enumToDataSource(EEducation, enumMessages.EEducation),
    educationFields: removeHeader((ds && ds.educationFields) || [])
  };
});

const mapStateToProps = (state, ownProps) => {
  const { intl } = state;
  const post = selectOpportunityPostToEdit(state, ownProps);
  const opportunity = _get(post, 'opportunity') || {};
  const matchConfig = opportunity.matchConfig || {};
  return {
    intl,
    dataSources: localSelectDataSources(state, ownProps),
    initialValues: {
      suitableForStudents: _get(matchConfig, 'education.currentlyStudying.bool.value') !== EBool.NO.key,
      minimumEducation: _get(matchConfig, 'education.level.range.lower.value'),
      minimumEducationRequired:
        _get(matchConfig, 'education.level.range.lower.eliminating') ||
        _get(matchConfig, 'education.level.range.eliminating') ||
        _get(matchConfig, 'education.level.eliminating'),
      educationFields: valueHoldersToValues(_get(matchConfig, 'education.fields.list.items')),
      educationFieldsRequired:
        _get(matchConfig, 'education.fields.list.eliminating') || _get(matchConfig, 'education.fields.eliminating')
    }
  };
};

const formConfig = {
  form: ID,
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate
};

EducationPart.ID = ID;
EducationPart.getProgressFromPost = post => {
  const matchConfig = post?.opportunity?.matchConfig;
  return getProgress([
    () => matchConfig?.education?.currentlyStudying?.bool?.value != null,
    () => matchConfig?.education?.level?.range?.lower?.value != null,
    () => matchConfig?.education?.fields?.list?.items?.length > 0
  ]);
};
EducationPart.getProgressFromValues = v =>
  getProgress([
    () => v?.suitableForStudents != null,
    () => v?.minimumEducation != null,
    () => v?.educationFields?.length > 0
  ]);

export default connect(mapStateToProps)(reduxForm(formConfig)(EducationPart));
