import React from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import map from 'lodash/map';

import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';

import FadedContent from '../../../components/basic/FadedContent';
import Badge from '../../../components/badge/Badge';
import Avatar from '../../../components/mui/Avatar';
import Button from '../../../components/mui/Button';
import IconButton from '../../../components/mui/IconButton';
import Tooltip from '../../../components/basic/Tooltip';
import OpportunityDisabledMessage from '../../../components/opportunity/OpportunityDisabledMessage';

import { BRAND } from '../../../constants/constants';
import { EOpportunityType } from '../../../constants/enum';
import { formatMessage } from '../../../utils/utils';
import { isMaxConcurrentOpportunitiesReached } from '../../../utils/talentMarketPlan';

const getDisplayStatusProps = (p, locale, messages) => {
  /**
   * NOT YET Displayed (orange)
   * -- will be displayed from
   * -- opt. will be displayed until
   *
   * Displayed (green)
   * -- displayed from
   * -- opt. will be displayed until
   *
   * NOT Displayed ANYMORE (grey)
   * -- was displayed from - until
   */
  const { publishedAt } = p;
  const from = p.displayedFrom;
  const to = p.displayedTo;
  const now = Date.now();

  const formatDate = d => moment(d).locale(locale).format('L LT');

  const res = {
    published: !!publishedAt,
    displayToggle: !publishedAt || ((!from || from < now) && (!to || now <= to))
  };
  if (!publishedAt) {
    res.variant = 'muted';
    res.message = messages.notPublished;
  } else if (from && now < from) {
    // not yet
    const fromPart = `${messages.displayedFrom} ${formatDate(from)}`;
    const toPart = to ? ` ${messages.displayedTo} ${formatDate(to)}` : '';
    res.variant = 'warning';
    res.message = `${fromPart}${toPart}`;
  } else if (to && to < now) {
    // not anymore
    res.variant = 'muted';
    res.message = formatMessage(messages.notDisplayedAnymore, [formatDate(to)]);
  } else {
    const fromPart = `${messages.displayedFrom} ${formatDate(from || p.createdAt)}`;
    const toPart = to ? ` ${messages.displayedTo} ${formatDate(to)}` : '';
    res.variant = 'success';
    res.message = `${fromPart}${toPart}`;
  }
  // displayed
  return res;
};

const StatusRow = props => {
  const { messages, talentMarketPlan, onTogglePublished, variant, published, displayToggle, message } = props;
  const publishDisabled = isMaxConcurrentOpportunitiesReached(talentMarketPlan);
  return (
    <div className="container-flex-row fw-yes ai-center mui-padded">
      <div className="flex1 mui-padded">
        <div className="mui-label">{messages.presentedLabel}</div>
        <small className={`text-${variant}`} style={{ fontWeight: '500' }}>
          {message}
        </small>
      </div>
      {displayToggle && (
        <div className="mui-padded">
          <Tooltip content={!published && publishDisabled && <OpportunityDisabledMessage />}>
            <Button
              primary={!published}
              raised={!published}
              onClick={onTogglePublished}
              label={published ? messages.unpublish : messages.publish}
              icon={published && <i className="material-icons">pause</i>}
              disabled={!published && publishDisabled}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

const OpportunityHeader = props => {
  const { opportunity, intl, detailed, talentMarketPlan, onDelete, onTogglePublished } = props;
  const { posts, type, typeProps, company } = opportunity;
  const enumMessages = intl.messages.constants.enums;
  const post = posts[0] || { title: 'N/A' };
  const typeMessage = enumMessages.EOpportunityType[type] || { label: 'N/A' };
  const messages = intl.messages.components.pages.private.opportunities;
  const menuActions = [];
  if (post.portalUrl) {
    menuActions.push(
      <MenuItem
        key="previewPost"
        primaryText={messages.previewTooltip}
        leftIcon={<i className="material-icons">visibility</i>}
        containerElement={<a href={post.portalUrl} target="_blank" rel="noopener noreferrer" />}
      />
    );
  }
  if (type === EOpportunityType.JOB.key || type === EOpportunityType.TALENT_ACADEMY.key) {
    menuActions.push(
      <MenuItem
        key="editPost"
        primaryText={messages.editLabel}
        leftIcon={<i className="material-icons">edit</i>}
        containerElement={<Link to={`/sourcing/opportunities/posts/${post.id}/edit`} />}
      />
    );
  }
  if (onDelete) {
    menuActions.push(
      <MenuItem
        key="deletePost"
        primaryText={messages.deleteLabel}
        leftIcon={<i className="material-icons">delete</i>}
        onClick={onDelete}
      />
    );
  }
  const statusProps = getDisplayStatusProps(post, intl.locale, messages);
  const { variant } = statusProps;
  return (
    <div className={variant === 'muted' ? 'text-muted' : ''}>
      <div style={{ padding: '16px 16px 0' }}>
        <h4 className="container-flex" style={{ marginBottom: '16px', alignItems: 'flex-start' }}>
          <Avatar className={`bg-${variant} text-${variant}`} size={40} style={{ marginRight: '16px' }}>
            <span className={`mdi mdi-${typeProps.icon}`} style={{ fontSize: '24px', lineHeight: '24px' }} />
          </Avatar>
          <div className="flex1">
            <div>{post.title}</div>
            <div style={{ marginTop: '4px' }}>
              <small>{typeMessage.label}</small>
              {company && company.name && <small> &middot; </small>}
              {company && company.name && <small>{company.name}</small>}
            </div>
          </div>
          {menuActions.length > 0 && (
            <div>
              <IconMenu
                iconButtonElement={
                  <IconButton style={{ padding: 6, width: 36, height: 36 }}>
                    <i className="material-icons">more_vert</i>
                  </IconButton>
                }
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                desktop
              >
                {menuActions}
              </IconMenu>
            </div>
          )}
        </h4>
      </div>
      {detailed && ((post.tags && post.tags.length > 0) || post.description) && (
        <div style={{ padding: '0 16px' }}>
          {post.tags && post.tags.length > 0 && (
            <div style={{ marginBottom: '16px' }}>
              <div className="mui-label" style={{ marginBottom: 0 }}>
                {messages.tagsLabel}
              </div>
              {map(post.tags, t => (
                <Badge key={t} text={t} color={BRAND.theme.palette.primary1Color} style={{ margin: '4px 4px 0 0' }} />
              ))}
            </div>
          )}
          {post.description && (
            <div className="mui-margin-bottom-x2 text-width-reading">
              <div className="mui-label">{messages.descriptionLabel}</div>
              <FadedContent maxHeight={24}>
                <p dangerouslySetInnerHTML={{ __html: post.description }} />
              </FadedContent>
            </div>
          )}
        </div>
      )}
      <div className={variant === 'success' ? '' : `bg-${variant}`}>
        <StatusRow
          post={post}
          intl={intl}
          messages={messages}
          talentMarketPlan={talentMarketPlan}
          onTogglePublished={onTogglePublished}
          {...statusProps}
        />
      </div>
    </div>
  );
};

export default OpportunityHeader;
