import React from 'react';
import { connect } from 'react-redux';

import SimpleModal from './SimpleModal';
import Spinner from '../spinner/Spinner';

import { formatMessage } from '../../utils/utils';

import * as opportunityActions from '../../actions/entities/opportunityActions';
import * as opportunityPostBuilderActions from '../../actions/entities/opportunityPostBuilderActions';
import { getCompanyTalentMarketPlan } from '../../actions/entities/companyActions';
import { switchModal } from '../../actions/options/modalActions';

const messages = {
  message: 'Přejete si smazat příležitost {0}? Přestane se tak zobrazovat a nabízet talentům v naší platformě.',
  cancelButton: 'Zrušit',
  confirmButton: 'Smazat'
};

class OpportunityPostDelete extends React.Component {
  componentDidMount() {
    this.loadPost(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.postId !== this.props.postId) {
      this.loadPost(this.props);
    }
  }

  loadPost = props => {
    const { getOpportunityPost, clearOpportunityPost, postId } = props;
    if (postId) {
      getOpportunityPost(postId);
    } else {
      clearOpportunityPost();
    }
  };

  handleConfirm = confirmed => {
    if (!confirmed) {
      return;
    }
    const {
      opportunityPostBuilder: { item },
      postId,
      reloadAll,
      router
    } = this.props;
    const { deleteOpportunityPost, getOpportunities } = this.props;
    if (!item) {
      return;
    }
    deleteOpportunityPost(postId).then(() => {
      if (reloadAll) {
        getOpportunities();
      } else if (router) {
        router.push('/sourcing/opportunities');
      }
    });
  };

  render() {
    const {
      id,
      opportunityPostBuilder: { item, loading }
    } = this.props;
    const { switchModal } = this.props;
    const msg = messages;
    return (
      <SimpleModal
        open
        id={id}
        callback={this.handleConfirm}
        switchModal={switchModal}
        cancelLabel={msg.cancelButton}
        confirmLabel={msg.confirmButton}
        message={
          loading ? (
            <div style={{ position: 'relative', minHeight: '96px' }}>
              <Spinner show local />
            </div>
          ) : (
            formatMessage(msg.message, [<b key="0">{item && item.title}</b>])
          )
        }
      />
    );
  }
}

const mapStateToProps = state => {
  const {
    intl,
    entities: { opportunityPostBuilder }
  } = state;
  return { intl, opportunityPostBuilder };
};

const actions = {
  switchModal,
  getCompanyTalentMarketPlan,
  ...opportunityActions,
  ...opportunityPostBuilderActions
};

export default connect(mapStateToProps, actions)(OpportunityPostDelete);
