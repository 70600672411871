import React from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

class RichTextEditor extends React.Component {
  static createEditorState = value => {
    const contentBlock = htmlToDraft(value || '');
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    return EditorState.createWithContent(contentState);
  };

  constructor(props) {
    super(props);
    const {
      input: { value }
    } = props;
    this.state = {
      editorState: RichTextEditor.createEditorState(value),
      value: props.input.value,
      focused: false
    };
  }

  componentDidUpdate(prevProps) {
    const nextProps = this.props;
    if (prevProps.input === nextProps.input || !nextProps.input) {
      return;
    }
    const {
      input: { value },
      meta: { dirty }
    } = nextProps;
    if (dirty) {
      return;
    }
    if (!value) {
      return;
    }
    if (value !== this.state.value) {
      const editorState = RichTextEditor.createEditorState(value);
      this.setState({ value, editorState });
    }
  }

  onEditorStateChange = editorState => {
    const rawValue = convertToRaw(editorState.getCurrentContent());
    const newValue = draftToHtml(rawValue).replace(/^<p><\/p>\n?$/, '');
    this.setState({ value: newValue, editorState });
    if (this.props.input?.onChange) {
      this.props.input.onChange(newValue);
    }
  };

  handleFocus = () => this.setState({ focused: true });
  handleBlur = () => this.setState({ focused: false });

  render() {
    const { editorState, focused } = this.state;
    const { label, placeholder, helperText } = this.props;
    return (
      <div className="mui-rich-text-editor">
        <div className="mui-rich-text-editor__field bg-primary-light">
          {label && <div className="mui-fields-label">{label}</div>}
          <Editor
            wrapperClassName={`text-editor${focused ? ' text-editor-focused' : ''}`}
            toolbar={{
              options: ['inline', 'list', 'textAlign', 'colorPicker', 'link', 'image', 'remove', 'history'],
              inline: { options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'] },
              list: { options: ['unordered', 'ordered'] }
            }}
            editorState={editorState}
            onEditorStateChange={this.onEditorStateChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            stripPastedStyles
            placeholder={placeholder}
          />
        </div>
        {helperText && <div className="mui-fields-helper-text">{helperText}</div>}
      </div>
    );
  }
}

export default RichTextEditor;
