import React from 'react';
import _map from 'lodash/map';

import Toggle from 'material-ui/Toggle';
import MenuItem from 'material-ui/MenuItem';

import HorizontalScrollPane from '../../basic/HorizontalScrollPane';
import SplitButton from '../../mui/SplitButton';
import Button from '../../mui/Button';
import Collapse from '../../basic/Collapse';
import TalentMarketPlanItem from './TalentMarketPlanItem';
import ComingSoonIndicator from './ComingSoonIndicator';

import { EBillingPeriod } from '../../../constants/enum';
import withIntl from '../../withIntl';
import { formatMessage } from '../../../utils/utils';

const BillingPeriodSwitch = withIntl(
  props => {
    const {
      billingPeriod,
      onChange,
      intl: { messages }
    } = props;
    const isAnnual = billingPeriod === EBillingPeriod.ANNUAL.key;
    const onToggle = () => onChange(isAnnual ? EBillingPeriod.MONTHLY.key : EBillingPeriod.ANNUAL.key);
    const renderLabel = (label, active, style) => (
      <span style={{ fontWeight: active ? 500 : 400, ...style }} role="button" onClick={onToggle}>
        {label}
      </span>
    );
    return (
      <div className="mui-padded">
        <div className="text-sz-xs text-muted text-uppercase mui-margin-bottom-half">{messages.title}</div>
        <div className="container-flex-row ai-center">
          {renderLabel(messages.labels.MONTHLY, !isAnnual)}
          <Toggle style={{ margin: 0 }} toggled={isAnnual} onToggle={onToggle} />
          {renderLabel(messages.labels.ANNUAL, isAnnual, { marginLeft: '8px' })}
        </div>
      </div>
    );
  },
  {
    cs: {
      title: 'Zúčtovací období',
      labels: {
        ANNUAL: 'Roční',
        MONTHLY: 'Měsíční'
      }
    },
    sk: {
      title: 'Zúčtovacie obdobie',
      labels: {
        ANNUAL: 'Ročné',
        MONTHLY: 'Mesačné'
      }
    },
    en: {
      title: 'Billing period',
      labels: {
        ANNUAL: 'Annual',
        MONTHLY: 'Monthly'
      }
    }
  }
);

const CurrencySelect = withIntl(
  props => {
    const {
      currencies,
      currency,
      intl: { messages },
      onChange
    } = props;
    return (
      <div className="mui-padded container-flex-row ai-center fw-yes">
        <div className="text-sz-xs text-muted text-uppercase mui-margin-right">{messages.title}</div>
        {currencies && currencies.length > 1 ? (
          <SplitButton label={currency || <em>{messages.selectMessage}</em>} className="bg-primary-light">
            {_map(currencies, c => (
              <MenuItem key={c} primaryText={c} onClick={() => onChange(c)} />
            ))}
          </SplitButton>
        ) : (
          <Button label={currency || <em>N/A</em>} className="bg-primary-light" disabled mini />
        )}
      </div>
    );
  },
  {
    cs: {
      title: 'Měna',
      selectMessage: 'Vyberte jednu...'
    },
    sk: {
      title: 'Mena',
      selectMessage: 'Vyberte jednu...'
    },
    en: {
      title: 'Currency',
      selectMessage: 'Select one...'
    }
  }
);

const TalentMarketPlanSelect = props => {
  const {
    plans,
    plan,
    requestedPlan,
    selecting,
    currencies,
    currency,
    defaultCurrency,
    billingPeriod,
    intl: { messages }
  } = props;
  const { onPlanSelectStart, onPlanSelect, onBillingPeriodSelect, onCurrencySelect } = props;

  const currentCurrency = currency || defaultCurrency;
  return (
    <div>
      <Collapse isOpened={!selecting}>
        <div className="mui-padded border-bottom border-color-primary__50">
          <span className="text-sz-reg mui-margin-horizontal">
            {formatMessage(messages.planSelectedMessage, [<b key="name">{plan?.name}</b>])}
          </span>
          <Button primary label={messages.changeButton} onClick={onPlanSelectStart} mini />
        </div>
      </Collapse>
      <Collapse isOpened={selecting}>
        <div className="container-flex-row fw-yes jc-space-between mui-padded ai-end mui-padded-bottom-zero">
          <h4 className="mui-padded-horizontal-x2 mui-padded-top">{messages.title}</h4>
          {currencies && currencies.length > 1 && (
            <CurrencySelect currency={currentCurrency} currencies={currencies} onChange={onCurrencySelect} />
          )}
        </div>
        <div className="mui-padded-bottom">
          <div className="mui-padded">
            <HorizontalScrollPane stretch>
              <div className="container-flex-row">
                {_map(plans, p => (
                  <TalentMarketPlanItem
                    key={p.id}
                    plan={p}
                    active={plan?.id === p.id}
                    requested={requestedPlan?.id === p.id}
                    currency={currentCurrency}
                    billingPeriod={billingPeriod}
                    onChange={onPlanSelect}
                  />
                ))}
              </div>
            </HorizontalScrollPane>
            {/*<div className="mui-margin-horizontal-x2 mui-margin-top-half text-muted text-sz-sm">*/}
            {/*  <ComingSoonIndicator /> {messages.comingSoonMessage}*/}
            {/*</div>*/}
          </div>
          {/*<div className="container-flex-row jc-center mui-padded-bottom">*/}
          {/*  <BillingPeriodSwitch billingPeriod={billingPeriod} onChange={onBillingPeriodSelect} />*/}
          {/*</div>*/}
        </div>
      </Collapse>
    </div>
  );
};

const intlMessages = {
  cs: {
    comingSoonMessage: 'Již brzy',
    changeButton: 'Změnit',
    title: 'Vyberte si svůj plán',
    planSelectedMessage: 'Máte vybraný plán {0}'
  },
  sk: {
    comingSoonMessage: 'Už čoskoro',
    changeButton: 'Zmeniť',
    title: 'Vyberte si svoj plán',
    planSelectedMessage: 'Máte vybraný plán {0}'
  },
  en: {
    comingSoonMessage: 'Coming soon',
    changeButton: 'Change',
    title: 'Select your plan',
    planSelectedMessage: 'You have selected {0} plan'
  }
};

export default withIntl(TalentMarketPlanSelect, intlMessages);
