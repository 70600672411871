import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router';
import map from 'lodash/map';
import _get from 'lodash/get';
import VisSensor from 'react-visibility-sensor';

import Toggle from 'material-ui/Toggle';
import IconMenu from 'material-ui/IconMenu';
import List from 'material-ui/List/List';
import ListItem from 'material-ui/List/ListItem';

import Spinner from '../../../components/spinner/Spinner';
import Button from '../../../components/mui/Button';
import IconButton from '../../../components/mui/IconButton';
import {
  Grid,
  GridRow,
  GridBody,
  GridCell,
  GridHeader,
  GridLabel,
  GridFooter,
  SortableGridLabel
} from '../../../components/grid';
import HorizontalScrollPane from '../../../components/basic/HorizontalScrollPane';
import MenuItem from '../../../components/menu/MenuItem';
import OpportunityStatsChart from '../../../components/opportunity/OpportunityStatsChart';
import BaseContainer from '../../../components/basic/BaseContainer';

import OpportunityHeader from './OpportunityHeader';
import OpportunityParticipantRow from './OpportunityParticipantRow';

import { getOpportunityTypeProps } from '../../../utils/opportunity';
import { formatMessage, objectToQueryString } from '../../../utils/utils';
import { ESortOrder, EOpportunityParticipantStatus, EOpportunityParticipantsSort } from '../../../constants/enum';
import {
  MODAL_OPPORTUNITY_PARTICIPANTS_SET_RESPONSE,
  MODAL_OPPORTUNITY_POST_PUBLICATION,
  MODAL_OPPORTUNITY_POST_DELETE
} from '../../../constants/constants';

import * as opportunityActions from '../../../actions/entities/opportunityActions';
import * as companyActions from '../../../actions/entities/companyActions';
import * as modalActions from '../../../actions/options/modalActions';
import { selectSortedOpportunityParticipants } from '../../../selectors/opportunities';
import { getActiveSearchLimit } from '../../../utils/talentMarketPlan';

const determineFitOrderIndicator = sortProps => {
  const { currentSort } = sortProps || {};
  if (currentSort) {
    if (currentSort === EOpportunityParticipantsSort.FIT_REQUIREMENTS.sort) {
      return 'REQUIREMENTS';
    }
    if (currentSort === EOpportunityParticipantsSort.FIT_PREFERENCES.sort) {
      return 'OFFER';
    }
    if (currentSort === EOpportunityParticipantsSort.FIT_CULTURE.sort) {
      return 'CULTURE';
    }
  }
  return 'TOTAL';
};

const TABS = {
  ACTIVE_SEARCH: 'search',
  STATS: 'stats',
  INTERESTED: 'interested'
};

const Tab = props => {
  const { value, activeValue, label, location } = props;
  return (
    <div className="inline-container">
      <MenuItem
        label={label}
        active={value === activeValue}
        containerElement={<Link to={`${location.pathname}${objectToQueryString({ ...location.query, tab: value })}`} />}
      />
    </div>
  );
};

const Tabs = props => {
  const { activeTab, intl, showActiveSearch, showInterested } = props;
  const messages = intl.messages.components.pages.private.opportunities;
  return (
    <HorizontalScrollPane
      style={{ boxShadow: 'inset 0px 16px 16px -16px rgba(0,0,0,0.08)', padding: '4px 4px 0' }}
      className="bg-primary-light"
    >
      <div className="container-flex-row">
        {showInterested && (
          <Tab value={TABS.INTERESTED} activeValue={activeTab} label={messages.tabInterested} location={location} />
        )}
        {showActiveSearch && (
          <Tab
            value={TABS.ACTIVE_SEARCH}
            activeValue={activeTab}
            label={
              <span>
                <i
                  className="material-icons"
                  style={{ verticalAlign: 'middle', marginRight: '0.25em', fontSize: '20px' }}
                >
                  search
                </i>
                <span style={{ verticalAlign: 'middle' }}>{messages.tabActiveSearch}</span>
              </span>
            }
            location={location}
          />
        )}
        <Tab value={TABS.STATS} activeValue={activeTab} label={messages.statsLabel} location={location} />
      </div>
    </HorizontalScrollPane>
  );
};

const InfoMessage = ({ children }) => {
  return (
    <div className="text-normal-sub" style={{ textAlign: 'center', padding: '2em' }}>
      {children}
    </div>
  );
};

const MoreMenu = props => {
  const { children } = props;
  return (
    <IconMenu
      iconButtonElement={
        <IconButton style={{ padding: 6, width: 36, height: 36, verticalAlign: 'middle', margin: '0 0 8px 8px' }}>
          <i className="material-icons">more_vert</i>
        </IconButton>
      }
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      targetOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      {children}
    </IconMenu>
  );
};

class LazyContent extends React.Component {
  state = { visible: false };

  handleChange = visible => visible && this.setState({ visible: true });

  render() {
    const { children, initialHeight, ...rest } = this.props;
    const { visible } = this.state;
    const height = initialHeight || 64;
    return (
      <VisSensor onChange={this.handleChange} {...rest}>
        {visible ? children : <div style={{ height, minHeight: height }} />}
      </VisSensor>
    );
  }
}

const ActiveSearchResults = props => {
  const {
    activeSearch: { loading, page, items, metricDefinitions, error },
    intl
  } = props;
  const { onParticipantClick, onResponseSet, onLoadMore } = props;
  const messages = intl.messages.components.pages.private.opportunities;
  return (
    <div>
      {items &&
        items.length > 0 &&
        map(items, p => (
          <LazyContent key={p.id} partialVisibility offset={{ bottom: -64 }} initialHeight={95}>
            <OpportunityParticipantRow
              intl={intl}
              participant={p}
              metricDefinitions={metricDefinitions}
              onResponseSet={onResponseSet}
              onClick={onParticipantClick}
            />
          </LazyContent>
        ))}
      {loading && (
        <InfoMessage>
          <div style={{ position: 'relative', height: 32, margin: 16 }}>
            <Spinner local show size={32} stroke={4} />
          </div>
          {messages.activeSearch.searchingMessage}
        </InfoMessage>
      )}
      {!loading && error && <InfoMessage>{messages.activeSearch.errorMessage}</InfoMessage>}
      {!loading && page && page.totalElements === items.length && (
        <InfoMessage>{messages.activeSearch.noMoreTalentsMessage}</InfoMessage>
      )}
      {!loading && page && page.totalElements > items.length && !error && (
        <VisSensor
          key={`${items.length}`}
          partialVisibility
          offset={{ bottom: -100 }}
          onChange={visible => visible && onLoadMore()}
        >
          <div style={{ minHeight: '1px', width: '100%' }} />
        </VisSensor>
      )}
      {!loading && !items && (
        <InfoMessage>
          <p>{messages.activeSearch.discoverTalentsMessage}</p>
          <div>
            <Button
              onClick={onLoadMore}
              label={
                <span>
                  <span
                    className="mdi mdi-auto-fix"
                    style={{ fontSize: '18px', verticalAlign: 'middle', marginRight: '12px' }}
                  />
                  {messages.activeSearch.discoverButton}
                </span>
              }
              raised
              primary
            />
          </div>
        </InfoMessage>
      )}
    </div>
  );
};

const eqProps = (a, b, path) => _get(a, path) === _get(b, path);

const ActiveSearchMoreMenu = props => {
  const {
    auth,
    intl,
    activeSearch: { eliminatedIncluded, emailsShown }
  } = props;
  const { onToggleEliminatedIncluded, onToggleEmailsShown, onRefresh } = props;
  const messages = intl.messages.components.pages.private.opportunities;
  return (
    <div className="container-flex-row fw-yes jc-flex-end">
      <IconButton onClick={onRefresh} tooltip={messages.activeSearch.refreshLabel}>
        <i className="material-icons">refresh</i>
      </IconButton>
      <MoreMenu>
        <List style={{ padding: 0 }}>
          <ListItem
            primaryText={messages.activeSearch.eliminatedIncludedLabel}
            rightToggle={<Toggle onToggle={onToggleEliminatedIncluded} toggled={!!eliminatedIncluded} />}
            style={{ margin: 0, fontWeight: 400 }}
          />
          {auth.isAdmin && (
            <ListItem
              primaryText={messages.activeSearch.emailsShownLabel}
              secondaryText={messages.activeSearch.emailsShownHelperText}
              rightToggle={<Toggle onToggle={onToggleEmailsShown} toggled={!!emailsShown} />}
              style={{ margin: 0, fontWeight: 400 }}
            />
          )}
        </List>
      </MoreMenu>
    </div>
  );
};

class Opportunity extends React.Component {
  state = {
    silentLoad: false
  };
  componentDidMount() {
    this.loadOpportunity();
  }

  componentDidUpdate(prevProps) {
    const oppOrLocaleChanged =
      !eqProps(prevProps, this.props, 'intl.locale') || !eqProps(prevProps, this.props, 'opportunityId');
    const tabChanged = !eqProps(prevProps, this.props, 'location.query.tab');
    if (oppOrLocaleChanged) {
      this.loadOpportunity();
    } else if (tabChanged && this.props.location.query.tab === TABS.INTERESTED) {
      this.loadOpportunity(true);
    }
    if (
      oppOrLocaleChanged ||
      !eqProps(prevProps, this.props, 'opportunity.sort.properties') ||
      !eqProps(prevProps, this.props, 'opportunity.activeSearch.eliminatedIncluded') ||
      !eqProps(prevProps, this.props, 'opportunity.activeSearch.emailsShown')
    ) {
      this.handleReloadActiveSearchResultsCommon();
    }
  }

  loadOpportunity = silentLoad => {
    const {
      opportunityId,
      getCompanyTalentMarketPlan,
      getOpportunity,
      intl: { locale }
    } = this.props;
    if (silentLoad) {
      this.setState({ silentLoad });
    }
    getOpportunity(opportunityId, locale)
      .then(({ payload }) => {
        const company = payload?.payload?.company;
        if (company) {
          getCompanyTalentMarketPlan(company.id, locale);
        }
      })
      .finally(() => this.setState({ silentLoad: false }));
  };

  handleParticipantClick = p => {
    const {
      opportunityId,
      router,
      location: {
        query: { tab }
      }
    } = this.props;
    const query = tab ? { bpq: JSON.stringify({ tab }) } : null;
    router.push({ pathname: `/sourcing/opportunities/${opportunityId}/participants/${p.id}`, query });
  };

  handleParticipantResponseSet = (p, response) => {
    const { opportunityId, switchModal } = this.props;
    switchModal({ id: MODAL_OPPORTUNITY_PARTICIPANTS_SET_RESPONSE, opportunityId, participant: p, response });
  };

  handleReloadActiveSearchResultsCommon = () => {
    const {
      opportunityId,
      performOpportunityActiveSearch,
      intl: { locale },
      opportunity: {
        sort,
        activeSearch: { eliminatedIncluded, emailsShown }
      }
    } = this.props;
    const fit = determineFitOrderIndicator({ currentSort: sort.properties });
    const newPage = null;
    performOpportunityActiveSearch(opportunityId, { lang: locale, fit, eliminatedIncluded, emailsShown }, newPage);
  };

  handleReloadActiveSearchResults = () => this.handleReloadActiveSearchResultsCommon();

  handleSort = (e, properties, order) => {
    const {
      sortOpportunityParticipants,
      opportunity: { sort }
    } = this.props;
    const newOrder =
      sort.properties === properties
        ? order
          ? order === ESortOrder.ASC
            ? ESortOrder.DESC
            : null
          : ESortOrder.ASC
        : ESortOrder.ASC;
    const newProperties = newOrder ? properties : null;
    sortOpportunityParticipants(newProperties, newOrder);
  };

  handleShowRejectedToggle = () => {
    const {
      toggleShowRejectedOpportunityParticipants,
      opportunity: { showRejected }
    } = this.props;
    toggleShowRejectedOpportunityParticipants(!showRejected);
  };

  handleTogglePublished = () => {
    const {
      switchModal,
      opportunity: { item }
    } = this.props;
    const posts = item && item.posts;
    const post = posts && posts[0];
    if (post) {
      switchModal({ id: MODAL_OPPORTUNITY_POST_PUBLICATION, open: true, postId: post.id });
    }
  };

  handleDelete = () => {
    const {
      switchModal,
      opportunity: { item }
    } = this.props;
    const posts = item && item.posts;
    const post = posts && posts[0];
    if (post) {
      switchModal({ id: MODAL_OPPORTUNITY_POST_DELETE, open: true, postId: post.id });
    }
  };

  handleToggleEliminatedIncluded = () => {
    const { setOpportunityActiveSearchFilterProperty } = this.props;
    const val = _get(this.props, 'opportunity.activeSearch.eliminatedIncluded');
    setOpportunityActiveSearchFilterProperty('eliminatedIncluded', !val);
  };

  handleToggleEmailsShown = () => {
    const { setOpportunityActiveSearchFilterProperty } = this.props;
    const val = _get(this.props, 'opportunity.activeSearch.emailsShown');
    setOpportunityActiveSearchFilterProperty('emailsShown', !val);
  };

  handleLoadMoreActiveSearchResults = () => {
    const {
      opportunityId,
      performOpportunityActiveSearch,
      intl: { locale },
      opportunity: {
        sort,
        activeSearch: { page, eliminatedIncluded, emailsShown, limitReached }
      }
    } = this.props;
    if (limitReached) {
      return;
    }
    const fit = determineFitOrderIndicator({ currentSort: sort.properties });
    const newPage = page ? { number: page.number + 1, size: page.size } : null;
    performOpportunityActiveSearch(opportunityId, { lang: locale, fit, eliminatedIncluded, emailsShown }, newPage);
  };

  render() {
    const {
      auth,
      opportunity: { item, loading, sort, showRejected, activeSearch },
      talentMarketPlan,
      participants,
      opportunityId,
      intl,
      location
    } = this.props;
    const { silentLoad } = this.state;
    if (!silentLoad && loading) {
      return <Spinner show />;
    }
    const { type } = item;
    const messages = intl.messages.components.pages.private.opportunities;
    const enumMessages = intl.messages.constants.enums;
    if (!type) {
      return (
        <BaseContainer>
          <div className="text-muted text-center well" style={{ marginTop: '16px' }}>
            {messages.invalidOpportunityMessage}
          </div>
        </BaseContainer>
      );
    }
    const typeProps = getOpportunityTypeProps(type);
    const sortProps = { currentSort: sort.properties, currentOrder: sort.order, onSort: this.handleSort };
    const activeTab = location.query.tab || (typeProps.registrable ? TABS.INTERESTED : TABS.STATS);
    const activeSearchLimit = getActiveSearchLimit(talentMarketPlan);
    const canAccessActiveSearch = auth.isAdmin || activeSearchLimit == null || activeSearchLimit > 0;
    const isActiveSearch = canAccessActiveSearch && activeTab === TABS.ACTIVE_SEARCH;
    return (
      <BaseContainer>
        <div className="mui-card mui-margin-top-x2">
          <OpportunityHeader
            intl={intl}
            opportunity={{ ...item, typeProps }}
            detailed
            talentMarketPlan={talentMarketPlan}
            onTogglePublished={this.handleTogglePublished}
            onDelete={this.handleDelete}
          />
          <div style={{ minHeight: '500px' }} className="bg-primary-light">
            <Tabs
              intl={intl}
              activeTab={activeTab}
              showInterested={typeProps.registrable}
              showActiveSearch={typeProps.registrable && canAccessActiveSearch}
            />
            {activeTab === TABS.STATS && <OpportunityStatsChart intl={intl} opportunityId={opportunityId} />}
            {(activeTab === TABS.INTERESTED || activeTab === TABS.ACTIVE_SEARCH) && (
              <Grid>
                {isActiveSearch && (
                  <div className="mui-padded-half container-flex-row ai-center jc-space-between">
                    <div className="text-muted text-sz-reg-sm mui-padded-half">
                      {activeSearchLimit == null
                        ? messages.activeSearch.discoveryUnlimitedMessage
                        : formatMessage(messages.activeSearch.discoveryLimitedMessage, [
                            <b key="limit">{activeSearchLimit}</b>
                          ])}
                    </div>
                    <div className="mui-padded-half">
                      <ActiveSearchMoreMenu
                        intl={intl}
                        auth={auth}
                        activeSearch={activeSearch}
                        onToggleEliminatedIncluded={this.handleToggleEliminatedIncluded}
                        onToggleEmailsShown={this.handleToggleEmailsShown}
                        onRefresh={this.handleReloadActiveSearchResults}
                      />
                    </div>
                  </div>
                )}
                <GridHeader>
                  <GridRow>
                    <GridCell xs={24} sm={12} md={6}>
                      {isActiveSearch ? (
                        <GridLabel>{messages.table.nameLabel}</GridLabel>
                      ) : (
                        <SortableGridLabel {...EOpportunityParticipantsSort.NAME} {...sortProps}>
                          {messages.table.nameLabel}
                        </SortableGridLabel>
                      )}
                    </GridCell>
                    <GridCell xsHide sm={12} md={5}>
                      <div style={{ display: 'inline-block', verticalAlign: 'top', marginRight: '8px' }}>
                        <GridLabel>{messages.table.fitLabel}</GridLabel>
                      </div>
                      <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                        <div>
                          {isActiveSearch ? (
                            <GridLabel sub>{messages.table.fitRequirementsLabel}</GridLabel>
                          ) : (
                            <SortableGridLabel sub {...EOpportunityParticipantsSort.FIT_REQUIREMENTS} {...sortProps}>
                              {messages.table.fitRequirementsLabel}
                            </SortableGridLabel>
                          )}
                        </div>
                        <div>
                          {isActiveSearch ? (
                            <GridLabel sub>{messages.table.fitPreferencesLabel}</GridLabel>
                          ) : (
                            <SortableGridLabel sub {...EOpportunityParticipantsSort.FIT_PREFERENCES} {...sortProps}>
                              {messages.table.fitPreferencesLabel}
                            </SortableGridLabel>
                          )}
                        </div>
                        <div>
                          {isActiveSearch ? (
                            <GridLabel sub>{messages.table.fitCultureLabel}</GridLabel>
                          ) : (
                            <SortableGridLabel sub {...EOpportunityParticipantsSort.FIT_CULTURE} {...sortProps}>
                              {messages.table.fitCultureLabel}
                            </SortableGridLabel>
                          )}
                        </div>
                      </div>
                    </GridCell>
                    <GridCell xsHide smHide md={9}>
                      <GridLabel>{messages.table.activitiesLabel}</GridLabel>
                    </GridCell>
                    <GridCell xsHide smHide md={4}>
                      {!isActiveSearch && (
                        <SortableGridLabel {...EOpportunityParticipantsSort.RESPONDED} {...sortProps}>
                          {messages.table.respondedLabel}
                        </SortableGridLabel>
                      )}
                    </GridCell>
                  </GridRow>
                </GridHeader>
                {isActiveSearch ? (
                  <GridBody>
                    <ActiveSearchResults
                      intl={intl}
                      activeSearch={activeSearch}
                      onLoadMore={this.handleLoadMoreActiveSearchResults}
                      onResponseSet={this.handleParticipantResponseSet}
                      onParticipantClick={this.handleParticipantClick}
                    />
                  </GridBody>
                ) : (
                  <GridBody>
                    {participants.totalCount === 0 && <InfoMessage>{messages.noParticipantsMessage}</InfoMessage>}
                    {map(participants.items, pi => (
                      <div key={pi.statusKey}>
                        <div className="mui-label" style={{ marginTop: '12px' }}>
                          {enumMessages.EOpportunityParticipantStatus[pi.statusKey].label}
                        </div>
                        {map(pi.values, p => (
                          <LazyContent key={p.id} partialVisibility offset={{ bottom: -64 }} initialHeight={95}>
                            <OpportunityParticipantRow
                              intl={intl}
                              participant={p}
                              onClick={this.handleParticipantClick}
                              onResponseSet={this.handleParticipantResponseSet}
                              metricDefinitions={item.metricDefinitions}
                            />
                          </LazyContent>
                        ))}
                      </div>
                    ))}
                  </GridBody>
                )}
                {!isActiveSearch && participants.counts[EOpportunityParticipantStatus.REJECTED.key] > 0 && (
                  <GridFooter>
                    <GridRow>
                      <GridCell xs={24}>
                        <div>
                          <a role="button" onClick={this.handleShowRejectedToggle}>
                            {formatMessage(messages.table.toggleRejected[showRejected ? 'hide' : 'show'], [
                              participants.counts[EOpportunityParticipantStatus.REJECTED.key]
                            ])}
                          </a>
                        </div>
                      </GridCell>
                    </GridRow>
                  </GridFooter>
                )}
              </Grid>
            )}
          </div>
        </div>
      </BaseContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    auth,
    entities: {
      opportunity,
      currentCompany: { talentMarketPlan }
    },
    intl
  } = state;
  const { params } = ownProps;
  return {
    auth,
    opportunity,
    intl,
    talentMarketPlan,
    participants: selectSortedOpportunityParticipants(state, ownProps),
    opportunityId: params.opportunityId
  };
};

const actions = {
  ...opportunityActions,
  getCompanyTalentMarketPlan: companyActions.getCompanyTalentMarketPlan,
  switchModal: modalActions.switchModal
};

export default withRouter(connect(mapStateToProps, actions)(Opportunity));
