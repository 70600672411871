import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { change as changeForm } from 'redux-form';

import InvitationModal from '../../components/modals/Invitation';
import NewAccountResultModal from '../../components/modals/NewAccountResult';
import AccountUpgradeModal from '../../components/modals/AccountUpgrade';
import ConfirmActivateModal from '../../components/modals/ConfirmActivate';
import ConfirmDeleteModal from '../../components/modals/ConfirmDelete';
import ConfirmFinishModal from '../../components/modals/ConfirmFinish';
import DemoPlayModal from '../../components/modals/DemoPlay';
import UnfinishedNotifications from '../../components/modals/UnfinishedNotifications';
import ProjectCopy from '../../components/modals/ProjectCopy';
import ParticipantBatchOperation from '../../components/modals/ParticipantBatchOperation';
import OpportunityParticipantsSetResponse from '../../components/modals/OpportunityParticipantsSetResponse';
import OpportunityPostPublication from '../../components/modals/OpportunityPostPublication';
import OpportunityPostDelete from '../../components/modals/OpportunityPostDelete';
import CrossComparisonProjectSelect from '../../components/modals/CrossComparisonProjectSelect';
import ComparisonParticipantsSelect from '../../components/modals/ComparisonParticipantsSelect';

import allActions from './../../actions';
import * as opportunityActions from './../../actions/entities/opportunityActions';
import {
  MODAL_INVITE_APPLICANTS,
  MODAL_ACCOUNT_NEW_RESULT,
  MODAL_ACCOUNT_UPGRADE,
  MODAL_CONFIRM_ACTIVATE,
  MODAL_CONFIRM_DELETE,
  MODAL_CONFIRM_FINISH,
  MODAL_DEMO_PLAY,
  MODAL_UNFINISHED_NOTIFICATION,
  MODAL_PROJECT_COPY,
  MODAL_PARTICIPANT_BATCH_OPERATION,
  MODAL_OPPORTUNITY_PARTICIPANTS_SET_RESPONSE,
  MODAL_OPPORTUNITY_POST_PUBLICATION,
  MODAL_OPPORTUNITY_POST_DELETE,
  MODAL_CROSS_COMPARISON_PROJECT_SELECT,
  MODAL_COMPARISON_PARTICIPANTS_SELECT,
  MODAL_TALENT_MARKET_PLAN_SELECT
} from '../../constants/constants';
import { selectAccountPermissions } from '../../selectors/auth';
import TalentMarketPlanSelectModal from '../../components/plan/talentMarket/TalentMarketPlanSelectModal';

//TODO redo so that the modals themselves are containers
const Modals = props => {
  const { modal, auth, intl, router, simulationDemo } = props;
  const {
    switchModal,
    showSnackbar,
    upgradeAccount,
    editProject,
    activateProject,
    createProject,
    deleteProject,
    finishProject,
    getDemoUrl,
    notifyProfiles,
    createProjectInvitation,
    copyProject,
    executeProjectParticipantsBatchAction,
    getCompanies,
    getProjectsAll,
    getResults,
    setOpportunityParticipantsResponse
  } = props;
  return (
    <div>
      {modal[MODAL_INVITE_APPLICANTS].open && (
        <InvitationModal
          {...modal[MODAL_INVITE_APPLICANTS]}
          auth={auth}
          intl={intl}
          hideModal={() => switchModal({ id: MODAL_INVITE_APPLICANTS, open: false })}
          showSnackbar={showSnackbar}
          createProjectInvitation={createProjectInvitation}
        />
      )}
      {modal[MODAL_ACCOUNT_NEW_RESULT].open && (
        <NewAccountResultModal
          {...modal[MODAL_ACCOUNT_NEW_RESULT]}
          id={MODAL_ACCOUNT_NEW_RESULT}
          intl={intl}
          switchModal={switchModal}
          router={router}
        />
      )}
      {modal[MODAL_ACCOUNT_UPGRADE].open && (
        <AccountUpgradeModal
          {...modal[MODAL_ACCOUNT_UPGRADE]}
          id={MODAL_ACCOUNT_UPGRADE}
          intl={intl}
          switchModal={switchModal}
          router={router}
          upgradeAccount={upgradeAccount}
        />
      )}
      {modal[MODAL_CONFIRM_ACTIVATE].open && (
        <ConfirmActivateModal
          {...modal[MODAL_CONFIRM_ACTIVATE]}
          id={MODAL_CONFIRM_ACTIVATE}
          intl={intl}
          switchModal={switchModal}
          activateProject={activateProject}
          createProject={createProject}
          editProject={editProject}
        />
      )}
      {modal[MODAL_CONFIRM_DELETE].open && (
        <ConfirmDeleteModal
          {...modal[MODAL_CONFIRM_DELETE]}
          id={MODAL_CONFIRM_DELETE}
          intl={intl}
          switchModal={switchModal}
          deleteProject={deleteProject}
        />
      )}
      {modal[MODAL_CONFIRM_FINISH].open && (
        <ConfirmFinishModal
          {...modal[MODAL_CONFIRM_FINISH]}
          id={MODAL_CONFIRM_FINISH}
          intl={intl}
          switchModal={switchModal}
          finishProject={finishProject}
        />
      )}
      {modal[MODAL_DEMO_PLAY].open && (
        <DemoPlayModal
          id={MODAL_DEMO_PLAY}
          intl={intl}
          switchModal={switchModal}
          getDemoUrl={getDemoUrl}
          simulationDemo={simulationDemo}
          {...modal[MODAL_DEMO_PLAY]}
        />
      )}
      {modal[MODAL_UNFINISHED_NOTIFICATION].open && (
        <UnfinishedNotifications
          {...modal[MODAL_UNFINISHED_NOTIFICATION]}
          id={MODAL_UNFINISHED_NOTIFICATION}
          intl={intl}
          switchModal={switchModal}
          notifyProfiles={notifyProfiles}
        />
      )}
      {modal[MODAL_PROJECT_COPY].open && (
        <ProjectCopy
          id={MODAL_PROJECT_COPY}
          auth={auth}
          project={modal[MODAL_PROJECT_COPY].project}
          intl={intl}
          switchModal={switchModal}
          copyProject={copyProject}
        />
      )}
      {modal[MODAL_PARTICIPANT_BATCH_OPERATION].open && (
        <ParticipantBatchOperation
          {...modal[MODAL_PARTICIPANT_BATCH_OPERATION]}
          id={MODAL_PARTICIPANT_BATCH_OPERATION}
          intl={intl}
          switchModal={switchModal}
          executeProjectParticipantsBatchAction={executeProjectParticipantsBatchAction}
          getProjectsAll={getProjectsAll}
          getCompanies={getCompanies}
          getResults={getResults}
          showSnackbar={showSnackbar}
        />
      )}
      {modal[MODAL_OPPORTUNITY_PARTICIPANTS_SET_RESPONSE].open && (
        <OpportunityParticipantsSetResponse
          {...modal[MODAL_OPPORTUNITY_PARTICIPANTS_SET_RESPONSE]}
          id={MODAL_OPPORTUNITY_PARTICIPANTS_SET_RESPONSE}
          intl={intl}
          switchModal={switchModal}
          setResponse={setOpportunityParticipantsResponse}
        />
      )}
      {modal[MODAL_OPPORTUNITY_POST_PUBLICATION].open && (
        <OpportunityPostPublication
          {...modal[MODAL_OPPORTUNITY_POST_PUBLICATION]}
          id={MODAL_OPPORTUNITY_POST_PUBLICATION}
        />
      )}
      {modal[MODAL_OPPORTUNITY_POST_DELETE].open && (
        <OpportunityPostDelete
          {...modal[MODAL_OPPORTUNITY_POST_DELETE]}
          id={MODAL_OPPORTUNITY_POST_DELETE}
          router={router}
        />
    
      )}
      {modal[MODAL_CROSS_COMPARISON_PROJECT_SELECT].open && (
        <CrossComparisonProjectSelect
          {...modal[MODAL_CROSS_COMPARISON_PROJECT_SELECT]}
          id={MODAL_CROSS_COMPARISON_PROJECT_SELECT}
          switchModal={switchModal}
        />
      )}
      {modal[MODAL_COMPARISON_PARTICIPANTS_SELECT].open && (
        <ComparisonParticipantsSelect
          {...modal[MODAL_COMPARISON_PARTICIPANTS_SELECT]}
          id={MODAL_COMPARISON_PARTICIPANTS_SELECT}
          switchModal={switchModal}
        />
      )}
      {modal[MODAL_TALENT_MARKET_PLAN_SELECT].open && (
        <TalentMarketPlanSelectModal {...modal[MODAL_TALENT_MARKET_PLAN_SELECT]} id={MODAL_TALENT_MARKET_PLAN_SELECT} />
      )}
    </div>
  );
};

Modals.propTypes = {
  modal: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  // actions
  switchModal: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  upgradeAccount: PropTypes.func.isRequired,
  getDemoUrl: PropTypes.func.isRequired,
  notifyProfiles: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const {
    options: { modal },
    intl,
    simulation: { demo },
    entities: { opportunityPostBuilder }
  } = state;
  return {
    auth: selectAccountPermissions(state, ownProps),
    modal,
    intl,
    opportunityPostBuilder,
    simulationDemo: demo
  };
};

const actions = {
  ...allActions,
  setOpportunityParticipantsResponse: opportunityActions.setOpportunityParticipantsResponse,
  changeForm
};

export default connect(mapStateToProps, actions)(withRouter(Modals));
