import { handleActions } from 'redux-actions';

import {
  OPPORTUNITY_POST_GET,
  OPPORTUNITY_POST_CREATE,
  OPPORTUNITY_POST_UPDATE,
  OPPORTUNITY_POST_CLEAR,
  OPPORTUNITY_POST_DELETE
} from '../../constants/constants';

const defaultState = {};

const handlePostFromPayload = (state, { type, payload: { payload } }) => {
  const res = {
    ...state,
    item: payload,
    loading: false,
    error: false
  };
  if (type === OPPORTUNITY_POST_UPDATE.SUCCESS || type === OPPORTUNITY_POST_CREATE.SUCCESS) {
    res.savedAt = Date.now();
  }
  return res;
};
const handleLoading = state => ({ ...state, loading: true });
const handleError = state => ({ ...state, loading: false, error: true });

export default handleActions(
  {
    [OPPORTUNITY_POST_CLEAR]: () => defaultState,
    [OPPORTUNITY_POST_GET.SUCCESS]: handlePostFromPayload,
    [OPPORTUNITY_POST_CREATE.SUCCESS]: handlePostFromPayload,
    [OPPORTUNITY_POST_UPDATE.SUCCESS]: handlePostFromPayload,
    [OPPORTUNITY_POST_DELETE.SUCCESS]: () => defaultState,
    [OPPORTUNITY_POST_GET.ERROR]: handleError,
    [OPPORTUNITY_POST_CREATE.ERROR]: handleError,
    [OPPORTUNITY_POST_UPDATE.ERROR]: handleError,
    [OPPORTUNITY_POST_DELETE.ERROR]: handleError,
    [OPPORTUNITY_POST_GET.PENDING]: handleLoading,
    [OPPORTUNITY_POST_CREATE.PENDING]: handleLoading,
    [OPPORTUNITY_POST_UPDATE.PENDING]: handleLoading,
    [OPPORTUNITY_POST_DELETE.PENDING]: handleLoading
  },
  defaultState
);
