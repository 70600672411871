import { mirrorReversed} from '../utils/utils';

export default {
  common: {
    labels: {
      upgrade: 'Prémium'
    }
  },
  components: {
    ieUnsupported: {
      title: 'Prehliadač Internet Explorer už bohužiaľ nepodporujeme',
      subtitle: 'Namiesto neho použite Google Chrome, Firefox, Safari alebo MS Edge'
    },
    navigation: {
      sourcing: {
        label: 'Talent Market',
        opportunitiesLabel: 'Príležitosti',
        companyProfileLabel: 'Profil spoločnosti'
      },
      home: {
        label: 'Domov'
      },
      assessments: {
        label: 'Assessmenty'
      },
      designer: {
        label: 'HR-Boti'
      },
      groups: {
        label: 'Tímy a skupiny'
      },
      lockedModeMessages: {
        SOURCING: {
          message:
            'Prilákajte potenciálnych kandidátov inzerovaním pracovných pozícií, kariérnych podujatí alebo obľúbených kníh o osobnom rozvoji, videí atď. Vyskúšajte {0[Prémium účet]} teraz a začnite svoj obsah dodávať talentom.'
        },
        RECRUITMENT: {
          title: 'Potrebujete overiť schopnosti vašich ľudí?',
          message:
            'Vyskúšajte {0[Prémium účet]} a získajte prístup k simulačným hrám a HR-botom',
          button: 'Vyskúšať prémium'
        },
        DEVELOPMENT: {
          title: 'Chcete strategicky riadiť vzdelávanie a rozvoj vašich ľudí?',
          message:
            'Vyskúšajte {0[Prémium účet]} a využite silu behaviorálnych dát pre váš spoločný rast',
          button: 'Vyskúšať prémium'
        }
      }
    },
    modals: {
      emailSent: {
        text: 'Na váš e-mail bol odoslaný odkaz, pomocou ktorého si nastavíte nové heslo',
        redirect: 'PRIHLÁSIŤ SA'
      },
      invitation: {
        invite: 'Pozvať účastníkov do projektu',
        sendLink: 'Odkaz nižšie odošlite e-mailom alebo to {0[nechajte na nás]}',
        sendLinkManual: 'Odkaz nižšie odošlite e-mailom',
        anonymous: 'Anonymný',
        anonymousHelper: 'Anonymný odkaz funguje bez nutnosti registrácie. Nebudete však schopní určiť, kto odkaz použil.',
        copyToClipboard: 'Skopírovať odkaz do schránky',
        codeDescription: 'Alebo, ak chcete dialóg používať priamo na vašej stránke, skopírujte nasledujúci kód a vložte ho medzi tagy <head> a </head>:',
        done: 'Hotovo',
        cancel: 'Zrušiť',
        send: 'Odoslať',
        inviteButton: 'Pozvať',
        sendFromSystem: {
          enterEmailsText: 'Zadajte e-maily účastníkov alebo im {0[odkaz odošlite osobne]}',
          emailsLabel: 'E-maily',
          emailsHintText: 'Oddeľte adresy čiarkou alebo medzerou',
          emailsInvalidText: 'Neplatný e-mail'
        },
        invitationSent: {
          title: 'Pozvánky odoslané',
          invitedParticipantsLabel: 'Pozvaní účastníci',
          alreadyExistingParticipantsLabel: 'Už sú súčasťou projektu',
          noParticipantsInvitedText: 'Žiadni ďalší účastníci neboli pozvaní. Všetky zadané e-mailové adresy sú už súčasťou projektu.',
          inviteMoreText: 'Chcete {0[pozvať ďalších]}?'
        },
        invitationError: {
          message: 'Nepodarilo sa nám odoslať pozvánky. Chcete to {0[skúsiť znova]}?'
        }
      },
      changeFormLeave: {
        message: 'Máte neuložené zmeny. Chcete ich zahodiť?',
        confirm: 'Zahodiť',
        cancel: 'Späť'
      },
      confirmActivate: {
        msg1: 'Aktivovať projekt',
        msg2: 'Po aktivácii už nebude možné meniť názov a súvisiace texty.',
        snackbar1: 'Projekt',
        snackbar2: 'bol aktivovaný',
        yes: 'Aktivovať',
        createYes: 'Vytvoriť a aktivovať',
        no: 'Späť'
      },
      confirmDelete: {
        msg1: 'Trvalo odstrániť projekt',
        msg2: 'Akciu už nemožno vrátiť späť.',
        snackbar1: 'Projekt',
        snackbar2: 'bol trvalo zmazaný',
        yes: 'Odstrániť',
        no: 'Späť'
      },
      confirmFinish: {
        msg1: 'Ukončiť projekt',
        msg2: 'Účastníci sa do neho ďalej nebudú môcť prihlásiť.',
        snackbar1: 'Projekt',
        snackbar2: 'bol ukončený',
        yes: 'Ukončiť',
        no: 'Späť'
      },
      accountUpgrade: {
        title: 'Vyskúšajte Prémium na 14 dní zadarmo',
        htmlMessage: '<p>' +
        'Odomknite si všetky nástroje na uľahčenie náboru, výberu a rozvoja ľudí:' +
        '<ul>' +
        '<li><b>simulačné hry</b> na testovanie schopností</li>' +
        '<li><b>HR-boti</b> na zvýšenie konverzie inzerátov a overenie culture-fitu</li>' +
        '<li>všetky <b>analytické dáta</b> na sledovanie výkonu a návratnosti</li>' +
        '</ul>' +
        'Stačí odoslať formulár. My sa s vami spojíme a všetko vám nastavíme presne na mieru.' +
        '</p>',
        fields: {
          phone: {
            label: 'Vaše telefónne číslo',
            helperText: 'Nepovinné',
            invalid: 'Neplatné telefónne číslo'
          }
        },
        fieldRequired: 'Povinné pole',
        valueLengthError: 'Dĺžka musí byť medzi {0} a {1} znakmi',
        confirmButton: 'Odoslať',
        cancelButton: 'Zrušiť',
        confirmationMessage: {
          success: 'Ďakujeme, prijali sme vašu požiadavku. Budeme vás v najbližšej dobe kontaktovať.',
          error:
            'Nepodarilo sa spracovať vašu požiadavku. Ak problémy pretrvávajú, kontaktujte našu podporu na {0} alebo využite chat v ľavom dolnom rohu.',
          button: 'Rozumiem'
        }
      },
      creditTopup: {
        title: 'Objavte s nami ešte viac kvalitných profilov',
        messages: {
          priceListIntro: 'Ďakujeme, že používate {0} Dashboard. Potrebujete navýšiť počet dostupných kreditov? Vyskúšajte jeden z nasledujúcich balíkov:',
          customIntro: 'Nevybrali ste si? Nevadí, stačí odoslať formulár, my sa s vami spojíme a pripravíme pre vás najlepšiu ponuku presne podľa vaších potrieb.',
          noPriceList: [
            'Ďakujeme, že používate {0} Dashboard. Potrebujete navýšiť počet dostupných kreditov?',
            'Stačí odoslať formulár, my sa s vami spojíme a pripravíme pre vás najlepšiu ponuku presne podľa vaších potrieb.'
          ]
        },
        confirmButton: 'Chcem viac kreditov'
      },
      demoPlay: {
        loading: 'Prosím čakajte...',
        cancelButton: 'Zrušiť',
        playButton: 'Hrať DEMO',
        error: 'Nastala neočakávaná chyba. Vyskúšajte akciu opakovať neskôr, prípadne nás kontaktujte.',
        message: 'Máte pár minút? Vyskúšajte si krátke DEMO simulačnej hry.'
      },
      notifications: {
        unfinished: {
          confirmButton: 'Odoslať',
          cancelButton: 'Teraz nie',
          text: 'Účastníkom, ktorí zatiaľ nedokončili, odošleme e-mailom priateľské pripomenutie s odkazom, prostredníctvom ktorého môžu pohodlne nadviazať tam, kde skončili.',
          textQuestion: 'Chcete odoslať pripomenutie vybraným účastníkom?',
          textSingle: 'Účastníkovi {0} odošleme e-mailom priateľské pripomenutie s odkazom, prostredníctvom ktorého môže pohodlne nadviazať tam, kde skončil. Chcete odoslať pripomenutie?'
        }
      },
      projectCopy: {
        message: 'Vytvorte zoskupenie založené na projekte {0}',
        nameLabel: 'Názov zoskupenia',
        groupingAddendum: 'Zoskupenie',
        copyAllParticipantsLabel: 'Kopírovať všetkých účastníkov s dokončenou aktivitou',
        groupingToggleLabel: 'Zoskupenie',
        groupingToggleHelperText: 'Zvyčajne sa odporúča vytvoriť zoskupenie (pohľad). V niektorých situáciach je ale možné vytvoriť štandardný projekt. Rozdiel je v interakcii s účastníkmi - zatiaľ čo do štandardného projektu ich môžete pozývať, zoskupenie je pre nich neviditeľné.',
        confirmButton: 'Vytvoriť',
        cancelButton: 'Zrušiť'
      },
      participantBatchOperation: {
        COPY: {
          searchPlaceholder: 'Vyberte projekt, do ktorého chcete účastníkov kopírovať',
          thisProjectLabel: 'Súčasný projekt',
          message: 'Profily vybraných účastníkov budú skopírované z projektu {0} do projektu {1}',
          changeProjectLabel: 'Zmeniť cieľový projekt',
          participantListHelper: 'Môžete odznačiť položky, ktoré nechcete kopírovať',
          operationWarning: 'Pozor, chystáte sa skopírovať účastníka do iného projektu, než do ktorého bol primárne pozvaný. <b>Názov tohto projektu sa zobrazí účastníkovi v portáli.</b> Ak to nechcete, využite možnosť kopírovať do Zoskupenia.',
          snackbarMessage: {
            '1': '1 účastník skopírovaný do projektu {1}',
            '2-4': '{0} účastníci skopírovaní do projektu {1}',
            '>4': '{0} účastníkov skopírovaných do projektu {1}'
          },
          confirmButton: 'Kopírovať',
          cancelButton: 'Zrušiť'
        },
        MOVE: {
          searchPlaceholder: 'Vyberte projekt, do ktorého chcete účastníkov presunúť',
          thisProjectLabel: 'Súčasný projekt',
          message: 'Profily vybraných účastníkov budú presunuté z projektu {0} do projektu {1}',
          changeProjectLabel: 'Zmeniť cieľový projekt',
          participantListHelper: 'Môžete odznačiť položky, ktoré nechcete presúvať',
          operationWarning: 'Pozor, chystáte sa presunúť účastníka do iného projektu, než do ktorého bol primárne pozvaný. <b>Názov tohto projektu sa zobrazí účastníkovi v portáli. Pôvodný projekt už neuvidí.</b> Ak to nechcete, využite možnosť kopírovať/presunúť do Zoskupenia.',
          snackbarMessage: {
            '1': '1 účastník presunutý do projektu {1}',
            '2-4': '{0} účastníci presunutí do projektu {1}',
            '>4': '{0} účastníkov presunutých do projektu {1}'
          },
          confirmButton: 'Presunúť',
          cancelButton: 'Zrušiť'
        },
        DELETE: {
          message: 'Profily vybraných účastníkov budú odstránené z projektu {0}',
          participantListHelper: 'Môžete odznačiť položky, ktoré nechcete odstrániť',
          snackbarMessage: {
            '1': '1 účastník odstránený',
            '2-4': '{0} účastníci odstránení',
            '>4': '{0} účastníkov odstránených'
          },
          confirmButton: 'Odstrániť',
          cancelButton: 'Zrušiť'
        }
      },
      opportunityParticipantsSetResponse: {
        cancelLabel: 'Zrušiť',
        confirmLabel: {
          ACCEPTED: 'Prejaviť záujem',
          ACCEPTED_INTERESTED: 'Potvrdiť záujem',
          ACCEPTED_ACCEPTED: 'Potvrdiť prihlášku',
          REJECTED: 'Odmietnuť'
        },
        message: {
          ACCEPTED_ACCEPTED: {
            main: 'Chcete prijať prihlášku uchádzača {0}?',
            description: 'Prijatím jeho prihlášky mu dávate vedieť, že s ním počítate vo výberovom procese. Taktiež získate jeho kontaktné údaje, takže s ním môžete komunikovať napriamo.'
          },
          ACCEPTED_INTERESTED: {
            main: 'Chcete potvrdiť svoj záujem o uchádzača {0}?',
            description: 'Dáte mu tak najavo, že o neho stojíte a pokiaľ sa prihlási, automaticky jeho prihlášku príjmete. Obdrží notifikáciu s výzvou, aby sa prihlásil. Ak tak vykoná, získate ihneď jeho kontaktné informácie.'
          },
          ACCEPTED: {
            main: 'Chcete prejaviť svoj záujem o uchádzača {0}?',
            description: 'Dáte mu tak najavo, že o neho stojíte a pokiaľ sa prihlási, automaticky jeho prihlášku príjmete. Obdrží notifikáciu s výzvou, aby sa prihlásil. Ak tak vykoná, získate ihneď jeho kontaktné informácie.'
          },
          REJECTED: {
            main: 'Prosím, uveďte dôvod pre odmietnutie uchádzača {0}'
          }
        },
        rejectReasonsLabel: 'Vyberte dôvod',
        rejectReasonTextLabel: 'Napíšte vaše odôvodnenie',
        rejectReasonTextHelperText: 'Tento text sa zobrazí uchádzačovi'
      }
    },
    cards: {
      pagination: {
        pageInfoMessage: 'Ste na stránke {0}',
        goToStartLabel: 'Prejsť na začiatok'
      },
      project: {
        from: 'od',
        to: 'do',
        noRestriction: 'Bez časového obmedzenia',
        latestProfileLabel: 'Najnovší profil',
        applicantCount: 'Počet účastníkov',
        ended: 'dokončilo',
        started: 'zahájilo',
        new: 'nezačaté',
        showApplicants: 'Detail',
        tags: {
          DEMO: 'Ukážka'
        }
      },
      projectAdd: {
        text: 'Pracujeme na tom, aby ste si už čoskoro mohli sami vytvárať projekty',
        textDisabled:
          'Nový projekt budete môcť vytvoriť hneď, ako autorizujeme váš účet. Je možné, že vás budeme s týmto cieľom do 24 hodín kontaktovať. Ďakujeme za váš záujem.'
      },
      demoPlay: {
        text: 'Chcete vidieť, ako vyzerá zážitok účastníka v simulácii?',
        button: 'Vyskúšať DEMO',
        resolutionError: 'Rozlíšenie okna vášho prehliadača nie je dostatočné pre plnohodnotný zážitok zo simulácie. Je potrebných minimálne 1200x638 pixelov.'
      },
      guideShow: {
        text: 'Vráťte sa do sprievodcu a pripomeňte si najdôležitejšie vlastnosti platformy {0}',
        button: 'Vrátiť sa'
      }
    },
    grid: {
      paginator: {
        rows: 'Riadkov na stránku:',
        of: 'z'
      }
    },
    iconMenu: {
      edit: 'Upraviť',
      delete: 'Vymazať',
      activate: 'Aktivovať',
      export: 'Export',
      exportXLSX: 'Export do XLSX',
      exportCSV: 'Export do CSV',
      finish: 'Ukončiť',
      reactivate: 'Znovu aktivovať',
      detail: 'Detail',
      invite: 'Pozvať účastníkov',
      createGrouping: 'Vytvoriť zoskupenie'
    },
    snackbar: {
      error: 'Neočakávaná chyba. Počkajte chvíľu a skúste akciu vykonať znova.',
      link: {
        common: 'Odkaz bol skopírovaný do schránky, stlačením Ctrl + V ho vložíte do textu',
        mac: 'Odkaz bol skopírovaný do schránky, stlačením Cmd + V ho vložíte do textu'
      }
    },
    sidebar: {
      overview: 'Prehľad',
      new: 'Vytvoriť nový',
      back: 'Späť',
      help: 'Pomocník',
      userGuide: {
        label: 'Návod',
        link: 'dash-user-guide'
      },
      guide: {
        label: 'Sprievodca'
      },
    },
    projectFilter: {
      hint: 'Hľadať',
      advanced: 'Pokročilé filtrovanie',
      projectTypesLabel: 'Typy projektov',
      activityTypesLabel: 'Aktivity',
      languagesLabel: 'Jazyky',
      projectStatesLabel: 'Stavy projektov',
      refreshTooltip: 'Znova načítať',
      sortTooltip: 'Zoradiť podľa',
      activeFirstLabel: 'Aktívne projekty najskôr',
      showDemoProjects: 'Zobraziť ukážkové projekty',
      showDemoProjectsHelper: 'Vždy zoradené ako posledné',
      newLabel: 'Nový',
      newLabelHelper: 'Vytvoriť nový assessment',
      noProjectFoundMessage: 'Nenašli sme žiadny assessment',
      noProjectFoundMessageCanAdd: 'Nenašli sme žiadny assessment. Môžete ho vytvoriť tlačidlom "NOVÝ" vpravo hore.',
      fromValuesFilters: {
        activityType: {
          label: 'Aktivity'
        },
        language: {
          label: 'Jazyky'
        },
        projectState: {
          label: 'Stavy projektov'
        },
        projectType: {
          label: 'Typy projektov'
        },
        finishedNonEmpty: {
          label: 'Dokončené profily'
        }
      },
      layout: {
        CARD: { tooltip: 'Zobraziť ako karty' },
        LIST: { tooltip: 'Zobraziť ako zoznam' }
      }
    },
    guide: {
      initial: {
        slide1: {
          title: 'Viete, prečo ste tu?',
          subtitle: 'Ste tu, pretože sa chcete niečo dozvedieť o ľuďoch. Chcete poznať odpovede, ktoré často hľadáte pomocou intuície, životopisov alebo testových otázok. Viete však, že dnes už to nestačí. Viete, že vám chýbajú fakty. O uchádzačoch i zamestnancoch. Kvôli nim ste sem prišli. A ste tu správne.'
        },
        slide2: {
          title: 'Ľudia chcú dostať férovú šancu ukázať, čo v nich je',
          subtitle: 'Dovoľte im to. Ako? Pozvite ich do simulačnej hry. V nej sa môžu slobodne prejaviť a ešte si odnesú skvelý zážitok z vášho výberového procesu. Vy si pritom overíte ich kompetencie a nebude vás to stáť žiadny čas.',
          buttonLabel: 'Hrať DEMO'
        },
        slide3: {
          title: 'Vaša intuícia už nebude nikdy sama',
          subtitle: 'Budete mať prehľadné fakty vždy na dosah ruky. Naše behaviorálne diagnostické údaje o ľuďoch vám pomôžu odhaliť ich silné a slabé stránky. Získate závery a odporúčania, s ktorými budete robiť lepšie rozhodnutia pri nábore, výbere a rozvoji svojich ľudí.'
        },
        slide4: {
          title: 'Nesklamete nikoho',
          subtitle: 'Sympatie silných si získate dobre mierenou pochvalou a tým slabším dáte možnosť odísť hrdo so vztýčenou hlavou. Kompetenčné odznaky a report na mieru získa každý. Pre nikoho tak nebude výberové konanie zbytočne investovaný čas.'
        },
        slide5: {
          title: 'Ste pripravení inovovať svoje HR?',
          subtitle: 'Kľúčové je, koho do tímu pustíte. Čím viac dobrých rozhodnutí urobíte hneď na začiatku, o to menej problémov budete musieť riešiť v budúcnosti.'
        }
      },
      initialV2: {
        slide1: {
          title: 'Cesta k unikátnym dátam o vašich talentoch práve začína',
          subtitle: 'Vitajte! V krátkosti vám predstavíme základné princípy Behavera Talent Decision Cloud.'
        },
        slide2: {
          title: 'Nechajte svoje talenty zahrať si hru',
          subtitle: 'Dáta získavame prostredníctvom online simulačnej hry, ktorá automaticky meria a interpretuje behaviorálne kompetencie z prejavov hráčov v rôznych situáciách. Bola navrhnutá tímom I/O psychológov, HR profesionálov a manažérov a otestovaná na tisícoch ľudí. Nie je to nudný dotazník ani logická hádanka. Je to assessmentová hra novej generácie.',
          buttonLabel: 'Zahrajte si krátke demo'
        },
        slide3: {
          title: 'Pozvite 3 vybraných ľudí zo svojho tímu',
          subtitle: 'Cez internetový odkaz sa ľahko prihlásia a získajú prístup do simulačnej hry. Vyberte 3 členov svojho tímu, ktorí vás radi podporia v objavovaní nových riešení. Doprajte im dostatok času a sústredenia na dokončenie hry. Získate tak svoje prvé tri profily a vaši kolegovia obdržia digitálne odznaky so svojimi najsilnejšími kompetenciami.'
        },
        slide4: {
          title: 'Získajte tu správnu perspektívu',
          subtitle: 'Akonáhle hráči simuláciu dokončia, ich profily vám budú ihneď k dispozícii. V tejto chvíli nastáva ten správny čas prispôsobiť výsledky vašim potrebám. S tým vám radi pomôžeme a ukážeme vám, ako na to.'
        },
        slide5: {
          title: 'Odštartujte novú éru v práci so svojimi talentmi',
          subtitle: 'Pracujte s unikátnymi dátami na výber a rozvoj talentov. Objavujte silné stránky tímov aj potenciál rozvoja jednotlivcov. Váš Talent Decision Cloud je pripravený. Pustite sa do toho.'
        }
      }
    },
    pages: {
      public: {
        login: {
          text: 'Prihláste sa',
          claims: [
            'Prilákajte ľudí simulačnou hrou',
            'Zmerajte kompetencie behaviorálnymi dátami',
            'Zmeňte svoj hiring a development s Talent Decision Cloud-om'
          ],
          forgottenPwd: 'Zabudnuté heslo',
          submit: 'PRIHLÁSIŤ',
          lang: 'Jazyk',
          email: 'E-mail',
          pwd: 'Heslo',
          error: 'Neplatné heslo / e-mail',
          errorAccessDenied: 'Prístup zamietnutý'
        },
        resetPassword: {
          text: 'Zabudnuté heslo',
          newPwd: 'Zadajte nové heslo',
          repeatNewPwd: 'Zopakujte heslo',
          lang: 'Jazyk',
          errorEqual: 'Hesla sa musia zhodovať',
          errorWidth: 'Hesla musia mať minimálne 8 znakov',
          submit: 'Odoslať'
        },
        resetPasswordEmail: {
          text: 'Zabudnuté heslo',
          lang: 'Jazyk',
          notice: 'Obdržíte správu s odkazom pre reštartovanie hesla',
          setMail: 'Zadajte e-mail',
          submit: 'Odoslať',
          error: 'Nesprávne zadaná adresa',
          errorNotFound: 'Používateľ so zadanou e-mailovou adresou nenájdený'
        },
        accountNew: {
          text: 'Vytvorte si účet',
          submit: 'Vytvoriť účet',
          fields: {
            firstName: {
              label: 'Meno'
            },
            lastName: {
              label: 'Priezvisko'
            },
            email: {
              label: 'Váš pracovný e-mail',
              invalidEmail: 'Neplatný e-mail',
              alreadyExists: 'Používateľ s daným e-mailom už existuje',
              freeEmail: 'Nepodporovaný e-mail, použite pracovný'
            },
            phone: {
              label: 'Vaše telefónne číslo',
              invalidPhone: 'Neplatné telefónne číslo'
            },
            promoCode: {
              label: 'Promo kód',
              helperText: 'Máte promo kód? Zadajte ho teraz a získajte nárok na zľavu a ďalšie výhody'
            },
            password: {
              label: 'Zvolte si heslo'
            },
            repeatPassword: {
              label: 'Zopakujte heslo',
              passwordsDontMatch: 'Hesla sa líšia'
            },
            language: {
              label: 'Jazyk'
            },
            companyName: {
              label: 'Názov spoločnosti',
              alreadyExists: 'Spoločnosť s daným identifikátorom už existuje'
            },
            companyConfirm: {
              label: 'Prehlasujem, že pracujem pre túto spoločnosť',
              required: 'Potvrďte prosím, že pre uvedenú spoločnosť pracujete'
            },
            marketingConfirm: {
              label: 'Zašlite mi občas e-mailom novinky o tom, čo robíte a aké nové simulačné hry a funkcie pripravujete'
            },
            tosConfirm: {
              label: '{0[Podmienky služby]} a {1[Zásady ochrany súkromia]} som čítal/a a súhlasím s nimi',
              required: 'Na využitie služby musíte súhlasiť'
            }
          },
          requiredMessage: '* Povinné polia',
          fieldRequired: 'Povinné pole',
          valueLengthError: 'Dĺžka musí byť medzi {0} a {1} znakmi',
          successMessage: {
            text1: 'Váš účet bol vytvorený a na uvedenú adresu sme vám zaslali potvrdzovací e-mail.',
            text2: 'Kliknutím na odkaz v e-maile svoj účet aktivujete.',
            text3: 'Ak správa nedorazí do 20 minút, pozrite sa tiež do priečinka SPAM, prípadne tiež skontrolujte iné priečinky, ktore využívate na triedenie pošty.',
            button: 'Rozumiem'
          },
          errorMessage: {
            text1:
              'Nepodarilo sa nám vytvoriť váš nový účet. Ak problémy pretrvávajú, kontaktujte našu podporu na {0} alebo využite chat v pravom dolnom rohu.',
            button: 'Rozumiem'
          }
        },
        accountActivation: {
          text: 'Aktivujte účet pre e-mail {0}',
          submit: 'Aktivovať',
          successMessage: 'Účet úspešne aktivovaný',
          errorMessage: 'Účet sa nám nepodarilo aktivovať. Skontrolujte platnosť odkazu'
        },
        common: {
          loginFooter: 'Máte už {0} účet? {1[Prihláste sa]}',
          newAccountFooter: 'Nemáte {0} účet? {1[Vytvorte si ho]}'
        }
      },
      private: {
        project: {
          applicantDetail: {
            back: 'Späť',
            print: 'Vytlačiť',
            evaluation: 'Evaluácia',
            publicProfile: 'Verejný profil',
            idSim: 'IDSIM',
            projectName: 'Projekt',
            simulationName: 'Aktivita',
            added: 'Používateľ pridaný',
            finished: 'Dokončil/a',
            exported: 'Exportované',
            strengths: 'Prednosti',
            weakness: 'Rezerva',
            competencies: 'Prehľad kompetencií',
            wellBeing: 'Employee well-being - Rizikové faktory',
            noRiskFactorsMessage: 'Žiadne rizikové faktory',
            causesLabel: 'Najčastejšie príčiny',
            indicators: 'Pracovné ukazovatele',
            recommendations: 'Odporúčania na prácu s účastníkom',
            interpretations: 'Závery a odporúčania',
            dimensions: 'Dimenzie',
            unfinishedMessage: 'Účastník ešte nedokončil aktivitu',
            operationalization: 'Operacionalizácia',
            operationalizations: 'Operacionalizácie',
            coreCompetenciesLabel: 'Kľúčové',
            otherCompetenciesLabel: 'Ďalšie',
            hideLabel: 'Skryť',
            showLabel: 'Zobraziť',
            factors: 'Faktory',
            durationStats: {
              preparation: 'Prípravy',
              simulation: 'Práca na úlohách',
              total: 'Celkový čas',
              description: 'Celkový čas je tvorený časom stráveným na prípravách a prácou na úlohách'
            },
            chart: {
              position: 'Umiestnenie',
              score: 'Skóre',
              skipped: 'Preskočených úloh'
            },
            compareTooltip: 'Porovnať',
            lockedPrintMessage: 'Pre tlač prejdite na plnú verziu',
            lockedProfileMessage: 'Pre zobrazenie profilu prejdite na {0[plnú verziu]}',
            lockedRecommendationsMessage:
              'Zistite, na čo sa zamerať v ďalšej práci s účastníkom. Prejdite na {0[plnú verziu]}',
            lockedSkillsDescriptionMessage:
              'Pre detailný popis kompetencie a zhrnutie skóre účastníka prejdite na {0[plnú verziu]}',
            lockedIndicatorMessage: 'Zaujíma vás detailný popis ukazovateľa? Prejdite na {0[plnú verziu]}'
          },
          applicantCompare: {
            removeButton: 'Odstrániť',
            addButton: 'Pridať profil k porovnaniu',
            searchPlaceholder: 'Hľadať profil'
          },
          applicantList: {
            anonymous: 'Anonymný',
            back: 'Zoznam projektov',
            invite: 'Pozvať účastníkov',
            inProgress: 'prebieha',
            unstarted: 'nezačaté',
            searchTooltip: 'Hľadať účastníkov',
            highlightTooltip: 'Zvýrazniť účastníkov v analytike',
            refreshTooltip: 'Znova načítať',
            rejected: {
              INVITATION: 'pozvanie odmietnuté',
              REGISTRATION: 'registrácia zrušená'
            },
            pending: {
              INVITATION: 'pozvaný',
              REGISTRATION: 'registrácia nepotvrdená'
            },
            inviteTooltip: {
              CONCEPT: 'Projekt ešte nebol aktivovaný',
              SCHEDULED: 'Projekt ešte nie je aktívny',
              ACTIVE: 'Pozvite účastníkov',
              FINISHED: 'Projekt už bol ukončený'
            },
            demo: {
              weightApplyDisabled: 'Demo projekt neumožňuje uloženie nastavenia'
            },
            empty: {
              valid: 'Zatiaľ nemáte žiadnych účastníkov. Pozvite ich cez tlačidlo ',
              invalid: 'Zoznam účastníkov je prázdny'
            },
            cols: {
              position: 'Poradie',
              name: 'Meno',
              score: 'Skóre',
              goodAt: 'Prednosti',
              weakAt: 'Rezerva',
              num: 'Num',
              finishedAt: 'Dokončené',
              competencies: 'Kompetencie',
              applicationStatus: 'Status',
              metrics: 'Metriky'
            },
            applicationStatusEnum: {
              NONE: 'Žiadny',
              REJECTED: 'Odmietnutý',
              ACCEPTED: 'Prijatý'
            },
            lockedChartMessage:
              'Prehľadne zobrazíte a zoradíte profily podľa vybraných parametrov. Táto funkcia je dostupná v {0[plnej verzii]}',
            upgradeMessage: 'Pre zobrazenie profilu prejdite na {0[plnú verziu]}',
            compareButton: 'Porovnať',
            highlightButton: 'Zvýrazniť',
            highlightHelperText: 'Zvýrazniť účastníkov v analytike',
            intraComparison: {
              label: 'Medzi sebou',
              description: 'Porovnať profily medzi sebou'
            },
            crossComparison: {
              label: 'Naprieč projektami',
              description: 'Porovnať s účastníkmi iného projektu'
            },
            markAsAccepted: 'Označiť ako prijaté',
            markAsRejected: 'Označiť ako odmietnuté',
            unsetApplicationStatus: 'Zrušiť nastavený status',
            notifications: {
              unfinished: {
                lastNotified: 'Pripomenuté {0}',
                neverNotified: 'Zatiaľ nepripomenuté',
                maxCountReached: 'Odoslaný maximálny počet pripomenutí',
                timeLimitReached: 'Ďalšie pripomenutie môžete odoslať najskôr po 2 dňoch',
                buttonLabel: 'Pripomenúť'
              }
            },
            crossComparedFromProjectMessage: 'Z projektu {0}',
            actions: {
              copyLabel: 'Kopírovať do...',
              moveLabel: 'Presunúť do...',
              deleteLabel: 'Odstrániť',
              remindLabel: 'Pripomenúť',
              addToTeamsLabel: 'Pridať do skupin/tímov...',
              addTagsLabel: 'Pridať tagy...'
            }
          },
          weighted: {
            name: 'Váhy aktívne',
            description: 'Boli nastavené vlastné váhy kompetencií pre tento projekt'
          },
          customModel: {
            name: 'Vlastný kompetenčný model',
            description: 'Bol nastavený vlastný kompetenčný model pre tento projekt'
          },
          overview: {
            tabs: {
              activity: 'Aktivita',
              list: 'Zoznam účastníkov',
              analytics: 'Analytika',
              context: 'Kontext'
            }
          },
          participantFilter: {
            fullTextPlaceholder: 'Hľadať',
            matchAllLabel: 'Spĺňa všetky vybrané',
            fromValuesFilters: {
              activityStatus: {
                label: 'Stav aktivity'
              },
              applicationStatus: {
                label: 'Status'
              },
              metricsScore: {
                label: 'Rozsah skóre'
              },
              metricsDuration: {
                label: 'Trvanie aktivity'
              },
              registeredAt: {
                label: 'Registrované'
              },
              finishedAt: {
                label: 'Dokončené'
              },
              bioAge: {
                label: 'Vek'
              },
              seekerStatus: {
                label: 'Hľadá príležitosti'
              },
              skillsLanguages: {
                label: 'Jazyky'
              },
              educationFields: {
                label: 'Študijné odbory'
              },
              educationLevel: {
                label: 'Vzdelanie'
              },
              educationCurrentlyStudying: {
                label: 'Stále študuje'
              },
              employmentWorkExperience: {
                label: 'Pracovné skúsenosti'
              },
              employmentLastWorkPosition: {
                label: 'Posledná pracovná pozícia'
              },
              preferencesRoles: {
                label: 'Roly'
              },
              preferencesSpecializations: {
                label: 'Špecializácie'
              },
              preferencesIndustries: {
                label: 'Odvetvia'
              },
              preferencesOpportunityTypes: {
                label: 'Typy príležitostí'
              },
              preferencesLocations: {
                label: 'Lokalita'
              },
              preferencesPersonalDevelopmentAreas: {
                label: 'Možnosti rozvoja'
              },
              preferencesBenefits: {
                label: 'Benefity'
              },
              preferencesSalaryRange: {
                label: 'Očakávaný plat',
                description: 'V Kč za mesiac, prepočítané podľa približného predom stanoveného kurzu'
              },
              preferencesLoveBrands: {
                label: 'Love brands'
              },
              skillsOther: {
                label: 'Iné schopnosti'
              },
              tags: {
                label: 'Tagy',
                noneLabel: 'Bez tagov'
              }
            }
          },
          analytics: {
            noParticipants: 'Zatiaľ žiadni účastníci nedokončili simuláciu',
            metricBubbleChart: {
              title: 'Kompetenčná mapa skupiny',
              subtitle: 'Prehľad početnosti skóre v jednotlivých kompetenciach'
            },
            scatterChart: {
              title: 'Kompetenčný fit',
              subtitle: 'Graf vzťahu hlavných a vedľajších kompetencií',
              description: 'Graf zobrazuje vzťah vami vybraných hlavných a vedľajších (všetkých ostatných) kompetencií účastníkov. Na osi X (horizontálna) a Y (vertikálna) je premietnutý vážený priemer hlavných, resp. vedľajších kompetencií. Zaradenie do jedného zo 4 vyobrazených kvadrantov vyjadruje, ako daný účastník vašu konfiguráciu splňuje.',
              competenciesLabel: 'Kompetencie',
              primaryCompetenciesLabel: 'Hlavné kompetencie',
              secondaryCompetenciesLabel: 'Vedľajšie kompetencie'
            },
            crossComparison: {
              button: 'Porovnanie naprieč',
              cancelButton: 'Zrušiť porovnanie',
              cancelHighlightButton: 'Zrušiť zvýraznenie',
              thisProjectLabel: 'Tento projekt',
              noProjectsLabel: 'Žiadne ďalšie projekty',
              searchProject: 'Hľadať projekt',
              projectSelect: {
                cancel: 'Zrušiť',
                change: 'Zmeniť',
                comparisonEmptyMessage: '{0[Porovnať]} s projektom',
                comparisonMessage: 'Porovnanie s projektom {0}'
              }
            }
          },
          questionnaireAnalytics: {
            removeParticipantFromComparisonButton: 'Odstrániť',
            anonymous: 'Anonymný',
            singleComparisonMessage: 'Na porovnanie jediného účastníka kliknite na príslušný riadok. Opakovaný klik opäť aktivuje všetky riadky.',
            managerialComparisonLabel: 'Porovnať ako manažéra tímu',
            overviewTitle: 'Tímový prehľad',
            overviewSubtitle: 'Ako vyzerá tímový profil na základe vyplnených Culture Fit dotazníkov',
            overviewSubtitleComparison: 'Porovnanie jednotlivcov s tímovým profilom a percentuálny fit tímovej kultúry'
          }
        },
        projectEdit: {
          buttons: {
            back: 'Späť',
            next: 'Ďalšie',
            close: 'Zrušiť',
            save: 'Uložiť',
            create: 'Dokončiť',
            saveActivate: 'Uložiť a aktivovať',
            createActivate: 'Dokončiť a aktivovať'
          },
          projectTypes: {
            POSITION: {
              label: 'Výberové konanie',
              tag: 'Výberové konanie',
              description: 'Zvoľte túto možnosť, ak chcete vykonať screening alebo assessment uchádzačov v rámci výberového konania na konkrétnu pozíciu',
              overrides: {
                projectNameLabel: 'Názov pozície',
                projectNameHelper: 'Uveďte názov pozície výberového konania, ku ktorému sa simulácia bude vzťahovať'
              }
            },
            GROUP_ASSESSMENT: {
              label: 'Tím alebo skupinu',
              tag: 'Assessment skupiny',
              description: 'Zvoľte túto možnosť, ak chcete vykonať screening alebo assessment v rámci konkrétneho interného tímu alebo skupiny',
              overrides: {
                projectNameLabel: 'Názov skupiny',
                projectNameHelper: 'Uveďte názov tímu alebo skupiny, pre ktorú bude určený'
              }
            },
            TALENT_PROGRAM: {
              label: 'Talent program',
              tag: 'Talent program',
              description: 'Zvoľte túto možnosť, ak chcete vykonať screening alebo assessment v rámci výberu stážistov alebo svojho programu pre hľadanie mladých talentov napr. z radov študentov a pod',
              overrides: {
                projectNameLabel: 'Názov talentového programu',
                projectNameHelper: 'Uveďte názov talentového programu (napr. akadémia alebo stáž)'
              }
            },
            VIEW: {
              label: 'Zoskupenie',
              tag: 'Zoskupenie',
              description: 'Zvoľte túto možnosť, ak chcete zoskupiť profily z iných assessmentov. Tento projekt je pre účastníka skrytý a slúži najmä na analytické a štatistické účely.',
              overrides: {
                projectNameLabel: 'Názov zoskupenia',
                projectNameHelper: 'Uveďte názov zoskupenia'
              }
            },
            QUESTIONNAIRE: {
              label: 'Dotazník',
              tag: 'Dotazník'
            }
          },
          intro: {
            title: 'Vytvoriť nový assessment pre',
            editTitle: 'Assessment pre'
          },
          generalSettings: {
            simNameLabel: 'Aktivita',
            projectNameLabel: 'Názov projektu',
            projectNameHelper: 'Uveďte názov projektu, ku ktorému sa aktivita bude vzťahovať',
            posTypeLabel: 'Typ pozície',
            posTypeHelper: 'Slúži na prispôsobenie príbehu účastníka aktivity',
            companyLabel: 'Spoločnosť',
            languageLabel: 'Jazyk aktivity',
            dateTitle: 'Platnosť projektu',
            dateHelper: 'Zadajte dátum, do ktorého sa môžu účastníci prihlásiť a dokončiť zadanie',
            alwaysOpenLabel: 'Vždy otvorené',
            reportTitle: 'Report',
            reportHelper: 'Vyberte, či chcete účastníkovi okamžite po ukončení aktivity sprístupniť report. Nastavenie je neskôr možné meniť globálne aj pre jednotlivcov.',
            dateStartLabel: 'Dátum otvorenia',
            dateEndLabel: 'Dátum ukončenia',
            dateEndHelperText: 'Vrátane nastaveného dátumu',
            advancedSettings: 'Pokročilé nastavenia',
            participantReportLabel: 'Report pre účastníka',
            supportedLanguagesLabel: 'Ďalšie podporované jazyky',
            supportedLanguagesHelper: 'Pridaním ďalších jazykov umožníte účastníkovi vybrať jazyk aktivity',
            viewLabel: 'Projekt je zoskupenie',
            viewDescription: 'Zoskupenia neumožňujú účastníkom priamu interakciu - sú pre nich skryté. Zoskupenie slúži ako obálka účastníkov, ktorí prešli rovnakým typom assessmentu, aký je nakonfigurovaný pre tento projekt.',
            poolEndpointLabel: 'Talent pool endpoint',
            talentPoolsLabel: 'Talent pooly',
            talentPoolsHelper: 'Vyberte talent pooly, do ktorých chcete účastníka projektu kvalifikovať',
            allowedEmailDomainsLabel: 'Povolené e-mailové domény',
            allowedEmailDomainsHelper: 'Môžete obmedziť prístup k registrácii do projektu pre konkrétne e-mailové domény (napr. "gmail.com"). Pozvánok z platofrmy sa nastavenie netýka.',
            allowedEmailDomainsErrorInvalid: 'Neplatná doména, skúste formát "gmail.com"',
            posterEnabledLabel: 'Poster pre účastníka',
            posterEnabledToggleDisabledDescription: 'Momentálne je dostupný len pre preferenčný Culture Fit',
            posterEnabledDescription: 'Dostupný priamo v reporte v portáli po dokončení aktivity',
            reportChatLabel: 'Chatbot pri reporte',
            reportChatHelper: 'Vyberte chatbota, ktorý sa účastníkovi objaví pri reporte',
            targetViewsLabel: 'Cieľové zoskupenia',
            targetViewsHelper: 'Profily sa budú automaticky kopírovať do vybraných zoskupení',
            flowProjectIdLabel: 'Ďalší projekt',
            flowProjectIdHelper: 'Môžete nastaviť tzv. flow projektov, kde po registrácii, alebo pozvaní bude účastník automaticky pozvaný aj do vybraného projektu',
            flowProjectEventTypeLabel: 'Kedy pozvať',
            seekerQualifierEventTypeLabel: 'Kvalifikovať hľadača',
            seekerQualifierEventTypeHelperText: 'Projekt môže účastníka kvalifikovať do roly hľadača',
            personalDetailsExtentLabel: 'Rozsah povinných údajov',
            personalDetailsExtentHelper: 'Zadajte rozsah dát, ktoré účastník musí mať vyplnené pred vstupom do assessmentu'
          },
          texts: {
            introTitle: 'Uvítacia správa pre účastníka',
            introHints: [
              'Uvítacia správa má za cieľ uviesť účastníkov do kontextu a poskytnúť im informácie o tom, čo ich čaká.',
              'Môžete nechať prednastavenú správu alebo text prispôsobiť.'
            ],
            customize: 'Prispôsobiť',
            reset: {
              button: 'Resetovať',
              confirmMessage: 'Naozaj chcete text resetovať?',
              yes: 'Áno',
              no: 'Nie'
            },
            internalNameLabel: 'Uchádzač sa bude hlásiť na pozíciu {0}, ktorej ale interne hovoríme',
            internalNameHelper: 'Pomenujte pozíciu tak, ako chcete, aby sa o nej hovorilo v aktivite',
            internalDescriptionLabel: 'A vo svojej práci sa bude primárne zameriavať na',
            internalTextPreviewHelper: 'Takto napríklad to bude pre uchádzača v aktivite vyzerať. Upravte text vyššie, aby sa vám výsledná veta páčila.',
            reportTitle: 'Správa po dokončení aktivity',
            reportHints: [
              'Správa sa zobrazí používateľovi po dokončení aktivity.',
              'Môžete nechať prednastavenú správu alebo vložiť vlastný text.'
            ]
          },
          notifications: {
            recipientsLabel: 'Váš tím',
            recipientsHelper: 'Kto z vášho tímu bude dostávať notifikácie o dianí v rámci tohto projektu?',
            select: 'Vyberte',
            frequencyLabel: 'Frekvencia notifikácií',
            frequencyHelper: 'Vyberte frekvenciu notifikácií',
            frequencyEnum: {
              NEVER: 'Nikdy',
              HOURLY: 'Hodinovo',
              DAILY: 'Denne',
              WEEKLY: 'Týždenne'
            }
          },
          snackbar: {
            createdMessage: 'Projekt {0} bol vytvorený',
            savedMessage: 'Zmeny pre projekt {0} boli uložené'
          },
          validation: {
            languageChangeWarn: 'Zmenili ste jazyk simulácie. Ak zmeny uložíte, bude obsah v záložke TEXTY nahradený východiskovým pre nový jazyk.',
            projectTypeEmpty: 'Vyberte typ projektu',
            projectNameEmpty: 'Uveďte názov projektu',
            posTypeEmpty: 'Vyberte typ pozície',
            companyEmpty: 'Vyberte spoločnosť',
            simulationEmpty: 'Vyberte aktivitu',
            languageEmpty: 'Vyberte jazyk',
            startDateAfterEndDate: 'Dátum otvorenia musí byť pred dátumom ukončenia',
            shortenedDateInterval: 'Platnosť projektu môže byť len predĺžená',
            endDateInPast: 'Dátum ukončenia musí byť v budúcnosti',
            endDateNotSet: 'Nastavte dátum ukončenia',
            notificationsRecipientsEmpty: 'Vyberte, kto bude notifikácie dostávať, alebo nastavte frekvenciu na "Nikdy"',
            frequencyEmpty: 'Vyberte frekvenciu, alebo odstráňte príjemcov'
          },
          projectStatus: {
            activeWithEnd: 'Tento projekt je aktívny a bude končiť {0}',
            activeUnlimited: 'Tento projekt je aktívny a bez časového obmedzenia',
            planned: 'Tento projekt bude aktívny {0}',
            concept: 'Tento projekt je v stave “Koncept”. Aby ste do neho mohli pozvať účastníkov, musíte ho aktivovať.'
          }
        },
        designer: {
          canvas: {
            node: {
              placeholder_DIALOGUE_IMPULSE: 'Napíšte repliku...',
              placeholder_DIALOGUE_REPLY: 'Napíšte odpoveď...',
              defaultPersonName: 'Bibi Talent',
              defaultPersonInfo: 'Digital Recruitment Guru',
              emptyLabel: 'Prázdne'
            }
          },
          editor: {
            saveButton: 'Uložiť',
            autoArrangeButton: 'Usporiadať',
            namePlaceholder: 'Pomenujte ma...',
            propertiesForm: {
              redirectUrlLabel: 'URL na presmerovanie',
              redirectTimeoutLabel: 'Časové oneskorenie presmerovania',
              redirectReportLabel: 'Presmerovať na report',
              redirectTopLabel: 'Presmerovať celú stránku',
              summaryMailTitle: 'Súhrnný e-mail',
              summaryMailDescription: 'Po dokončení simulácie vám môžeme poslať súhrn premenných a ich hodnôt.',
              summaryMailLabel: 'E-mailová adresa',
              defEnvLabel: 'Predvolené prostredie',
              preview: 'Náhľad',
              envLabel: {
                PARK: 'Park',
                OFFICE: 'Kancelária',
                COUNTER: 'Prepážka'
              },
              defLangLabel: 'Jazyk',
              languageTitle: 'Jazyk konverzácie',
              languageDescription: 'Konverzácia, jej kontext a ovládacie prvky sa primárne riadia nastaveným jazykom',
              companyTitle: 'Spoločnosť',
              companyDescription: 'Vyberte spoločnosť, pre ktorú je konverzácia určená',
              companyLabel: 'Spoločnosť',
              environmentTitle: 'Prostredie',
              environmentDescription:
                'Simulácia sa bude odohrávať vo vybranom prostredí. V súčasnosti sa mení len pozadie.',
              variableDefinitionsTitle: 'Premenné',
              variableDefinitionsDescription: 'Definujte premenné, ktoré môžu byť použité počas simulácie na riadenie toku alebo nastavenie externých parametrov. Meno premennej môže pozostávať len zo znakov abecedy, napr. \'mojaPremenna\' (žiadne medzery, pomlčky alebo iné zvláštne znaky).',
              variableDefinitionAddButton: 'Pridať premennú',
              variableDefinitionNameLabel: 'Meno premennej',
              variableDefinitionValueLabel: 'Počiatočná hodnota',
              inputParamsTitle: 'Premenné simulácie',
              inputParamsDescription: 'Zoznam premenných v simulácii, ktoré v jej priebehu môžu nadobúdať rôzne hodnoty podľa operácií definovaných na používateľských akciách (napr. vybraná odpoveď v dialógu). Tieto premenné tiež môžu vstupovať do simulácie prostredníctvom jej URL ako parametre. Premenné potom slúžia na zber dát (reportovaných alebo nie), riadenie presmerovania alebo aj na prispôsobenie správ v dialógu - použitie v texte ako {{nazovPremennej}}. Identifikačné premenné sa používajú na určenie, či prebieha príslušná simulácia.',
              redirectTitle: 'Presmerovanie',
              redirectDescription:
                'Po úspešnom dokončení simulácie môže byť používateľ presmerovaný na špecifikované URL. To môže obsahovať premenné definované v predošlej sekcii, napr. http://www.priklad.sk/identifikator={nazovPremennej}',
              redirectTimeoutDescription:
                'Môžete taktiež definovať čas v sekundách, po uplynutí ktorého dôjde k presmerovaniu používateľa. Predvolená hodnota je 6 sekúnd.',
              inputParamNameLabel: 'Názov premennej',
              inputParamIdentifyingLabel: 'Identifikačná',
              inputParamTypeLabel: 'Typ',
              inputParamReportedLabel: 'Reportovaná',
              inputParamLabelLabel: 'Reportovaný názov',
              inputParamMapsToMetricLabel: 'Mapované na metriku',
              inputParamMetricLabel: 'Metrika',
              inputParamMetricMinValueLabel: 'Minimálna hodnota',
              inputParamMetricMaxValueLabel: 'Maximálna hodnota',
              inputParamMetricMappingReversedLabel: 'Reverzné mapovanie',
              addInputParamButton: 'Pridať premennú',
              removeInputParamButton: 'Odstrániť',
              inputParamTypes: {
                STRING: 'Reťazec znakov',
                NUMBER: 'Číslo'
              },
              projectTitle: 'Projekt',
              projectDescription: 'Nastavenie projektu',
              projectNameLabel: 'Názov projektu',
              projectNameHelperText: 'Vo východzom stave preberá názov modelu',
              simulationTitle: 'Simulácia',
              simulationDescription: 'Nastavenie simulácie ako takej. Typ simulácie definuje metriky, na ktoré môžu byť číselné premenné mapované.',
              simulationKeyLabel: 'Typ',
              simulationVariantLabel: 'Varianta',
              simulationVariants: {
                candidate: 'Preferenčný',
                manager: 'Vnímaný - Manažér',
                employee: 'Vnímaný - Zamestnanec'
              },
              simulationNameLabel: 'Názov',
              simulationNameHelperText: 'Vo východzom stave preberá názov typu simulácie, prípadne názov modelu',
              lafTitle: 'Vzhľad',
              lafDescription: 'Konfigurácia vzhľadu simulácie',
              lafAnswerFormatLabel: 'Formát odpovedí',
              lafAnswerFormatTypes: {
                SAME: 'Rovnaké'
              },
              lafTypingDurationEnabledLabel: 'Efekt písania povolený',
              validation: {
                unknownParameter: "Neznáma premenná '{0}'",
                languageRequired: 'Vyberte jazyk',
                companyRequired: 'Vyberte spoločnosť',
                parameterNameRequired: 'Názov je povinný',
                parameterNameNotUnique: 'Názov už existuje',
                parameterNameInvalid: 'Nesprávny formát - môže obsahovať iba veľké a malé písmená alebo podtržítko',
                parameterLabelRequired: 'Nesmie byť prázdne',
                parameterTypeRequired: 'Vyberte typ',
                redirectTimeoutNegative: 'Časové oneskorenie nesmie byť záporné',
                projectNameRequired: 'Názov je povinný',
                simulationNameRequired: 'Názov je povinný',
                nameLengthAtLeast: 'Dĺžka názvu musí byť aspoň {0}',
                mappingMetricRequired: 'Vyberte metriku',
                mappingMinValueRequired: 'Zadajte minimálnu hodnotu',
                mappingMaxValueLessThanMin: 'Maximálna hodnota nesmie byť menšia než minimálna',
                invalidEmail: 'Neplatný e-mail'
              }
            },
            nodeSettingsForm: {
              confirmButton: 'Potvrdiť',
              cancelButton: 'Zrušiť',
              variableOperationsTitle: 'Operácie na premenných',
              variableOperationsDescription: 'Definujte operácie na premenných, ktoré budú vykonané, ak si používateľ zvolí túto cestu v simulácii (napr. tým, že vyberie danú odpoveď)',
              variableOperationsEmpty: 'Žiadne operácie zatiaľ neboli definované',
              variableOperationVariableLabel: 'Premenná',
              variableDefinitionsEmpty: 'Najskôr definujte premenné v bočnej lište',
              variableOperationOperationLabel: 'Operácia',
              variableOperationValueLabel: 'Hodnota',
              variableOperationAddButton: 'Pridať operáciu',
              variableOperationVariableRequired: 'Vyberte premennú',
              variableOperationOperationRequired: 'Vyberte operáciu',
              variableOperationValueRequired: 'Nastavte hodnotu',
              variableOperationValueNotVariable: 'Neexistujúca premenná',
              operations: {
                ASSIGN: {
                  label: 'priraď'
                },
                ADD: {
                  label: 'pričítaj'
                },
                MULTIPLY: {
                  label: 'vynásob'
                },
                ASSIGN_VARIABLE: {
                  label: 'priraď premennú'
                },
                ASSIGN_RESULT: {
                  label: 'priraď obsah'
                }
              }
            }
          },
          modelList: {
            filterPlaceholder: 'Hľadať',
            createButton: 'Vytvoriť',
            confirmDeleteButton: 'Potvrdiť',
            emptyListMessage:
              'Žiadne modely simulácií tu nie sú. Môžete začať pridaním modelu klikom na tlačidlo "VYTVORIŤ"',
            unnamedItemName: 'Bez názvu',
            modelCountMessage: '{0} modelov celkom',
            nameHeader: 'Názov',
            createdHeader: 'Vytvorené',
            modifiedHeader: 'Posledná zmena'
          }
        },
        opportunities: {
          search: 'Hľadať',
          previewTooltip: 'Náhľad',
          editLabel: 'Upraviť',
          deleteLabel: 'Zmazať',
          presentedLabel: 'Uverejnenie',
          descriptionLabel: 'Popis',
          tagsLabel: 'Tagy',
          statsLabel: 'Štatistiky',
          participantsLabel: 'Účastníci',
          showDetailButton: 'Zobraziť detail',
          showStatsButton: 'Zobraziť štatistiky',
          displayedFrom: 'Od',
          displayedTo: 'do',
          notDisplayedAnymore: 'Už neprezentované (do {0})',
          notPublished: 'Neuverejnené',
          publish: 'Uverejniť',
          unpublish: 'Pozastaviť',
          invalidOpportunityMessage: 'Neplatná príležitosť',
          noParticipantsMessage: 'Zatiaľ sa nikto neprihlásil alebo neprejavil záujem',
          table: {
            nameLabel: 'Meno',
            fitLabel: 'Fit',
            fitRequirementsLabel: 'Požadujeme',
            fitPreferencesLabel: 'Ponúkame',
            fitCultureLabel: 'Kultúra',
            activitiesLabel: 'Aktivity',
            respondedLabel: 'Reakcia',
            toggleRejected: {
              show: 'Zobraziť odmietnuté ({0})',
              hide: 'Skryť odmietnuté ({0})',
            }
          },
          stats: {
            addWeekButton: '1 týždeň',
            fromLabel: 'Od',
            toLabel: 'Do',
            cancelLabel: 'Zrušiť',
            props: {
              DISPLAYED: { label: 'Zobrazené' },
              OPENED: { label: 'Otvorené' },
              LIKED: { label: 'Páči sa' },
              INTERESTED: { label: 'Zaujíma' },
              ACCEPTED: { label: 'Prihlásené' }
            }
          },
          responseStatus: {
            INTERESTED: { label: 'Zaujíma sa' },
            ACCEPTED: { label: 'Prihlásil sa' },
            REJECTED: { label: 'Odmietol' }
          },
          companyResponseStatus: {
            INTERESTED: { label: 'Máme záujem' },
            ACCEPTED: { label: 'Máme záujem' },
            ACCEPTED_ACCEPTED: { label: 'Sme prepojení' },
            REJECTED: { label: 'Odmietli sme' }
          },
          companyResponseButtons: {
            ACCEPT: 'Prejaviť záujem',
            ACCEPT_INTERESTED: 'Potvrdiť záujem',
            ACCEPT_ACCEPTED: 'Potvrdiť prihlášku',
            REJECT: 'Odmietnuť'
          },
          loadError: {
            message: 'Ospravedlňujeme sa, niekto (pravdepodobne ja) niečo pokazil a teraz nemôžeme načítať príležitosti. {0[Skúste znova]} alebo kontaktujte podporu na {1}.',
            homeButton: 'Domov'
          },
          noOpportunitiesMessage: 'Žiadne príležitosti nenájdené',
          group1Title: 'Pracovné ponuky, udalosti, talentové akadémie...',
          group2Title: 'E-knihy, videá, online kurzy...',
          createButtonTooltip: 'Vytvoriť príležitosť',
          createButtonLabel: 'Nová',
          tabInterested: 'Záujemci',
          tabActiveSearch: 'Objavovanie talentov',
          activeSearch: {
            searchingMessage: 'Hľadáme...',
            noMoreTalentsMessage: 'Viac vhodných talentov sme nenašli',
            errorMessage: 'Pri vyhľadávaní nastala chyba, skúste to znova neskôr',
            discoverTalentsMessage: 'Objavte viac vhodných talentov a rovno ich oslovte',
            discoverButton: 'Objaviť',
            eliminatedIncludedLabel: 'Ukázať aj talenty nesplňujúce K.O. kritéria',
            refreshLabel: 'Znova načítať',
            emailsShownLabel: 'Zobraziť e-mailové adresy',
            emailsShownHelperText: 'Len pre administrátorov',
            discoveryUnlimitedMessage: 'Môžete objaviť neobmedzený počet talentov',
            discoveryLimitedMessage: 'Môžete objaviť {0} talentov'
          }
        },
        companyProfile: {
          noProfileAvailable: {
            title: 'Zatiaľ nemáte žiadny profil',
            message: 'Zapôsobte na potenciálnych kandidátov pomocou starostlivo vypracovaného profilu spoločnosti. Stačí len vyplniť formulár a my sa postaráme o zvyšok.',
            actionButton: 'Začať tu'
          },
          notPublished: 'Profil nie je uverejnený',
          publishedAt: 'Uverejnený {0}',
          draftUpdated: 'Koncept profilu aktualizovaný {0}',
          nameLabel: 'Názov',
          logoLabel: 'Logo',
          websiteLabel: 'Webové stránky',
          headerImageLabel: 'Titulný obrázok',
          descriptionLabel: 'Popis',
          locationsLabel: 'Lokalita',
          industriesLabel: 'Odvetvia',
          momentsLabel: 'Momentky',
          employeeSizeLabel: 'Počet zamestnancov',
          typeLabel: 'Typ spoločnosti',
          benefitsLabel: 'Benefity',
          personalDevelopmentAreasLabel: 'Možnosti rozvoja',
          editButton: 'Upraviť',
          actions: {
            preview: {
              label: 'Náhľad'
            },
            show: {
              label: 'Ukázať profil'
            },
            publishDraft: {
              label: 'Uverejniť koncept'
            },
            publish: {
              label: 'Uverejniť',
              confirmLabel: 'Uverejniť',
              cancelLabel: 'Zrušiť',
              confirmMessage: 'Profil bude verejne dostupný. Chcete ho uverejniť?'
            },
            unpublish: {
              label: 'Zrušiť uverejnenie',
              confirmLabel: 'Zrušiť uverejnenie',
              cancelLabel: 'Zavrieť',
              confirmMessage: 'Profil nebude verejne dostupný. Chcete zrušiť jeho uverejnenie?'
            },
            discard: {
              label: 'Zahodiť koncept',
              confirmLabel: 'Zahodiť',
              cancelLabel: 'Zrušiť',
              confirmMessage: 'Koncept bude odstránený, ponechá sa len predchádzajúci uverejnený profil. Chcete zahodiť koncept?'
            },
            unknown: {
              confirmLabel: 'Potvrdiť',
              cancelLabel: 'Zrušiť',
              confirmMessage: 'Potvrdiť vašu akciu?'
            }
          }
        },
        companySelect: {
          currentCompanyMessage: 'Aktuálne vybraná spoločnosť {0}',
          noCurrentCompanyMessage: 'Spoločnosť nevybraná',
          noCurrentCompanyNote: 'Pokračujte výberom aktívneho účtu',
          setButton: 'Vybrať',
          unsetButton: 'Zrušiť',
          changeButton: 'Zmeniť',
          menuUnsetButton: 'Zrušiť aktuálny výber spoločnosti',
          menuSearchCompany: 'Hľadať spoločnosť'
        },
        sourcingProfileBuilder: {
          title: 'Oslovte nové talenty v 2 jednoduchých krokoch',
          message: 'Už o 15 minút môžete získať pozornosť viac ako 3 500 motivovaných kandidátov z celej ČR',
          profile: {
            title: 'Váš firemný profil, ktorý môžu navštevovať záujemcovia o vaše príležitosti',
            previewButton: 'Náhľad',
            NONE: {
              message: 'Vytvorte profil vašej spoločnosti',
              description: 'Prepojte vašu značku s komunitou talentov, predstavte im firemnú kultúru a zdieľajte s nimi váš obsah',
              button: 'Vytvoriť'
            },
            PENDING: {
              message: 'Profil máte vytvorený. Zostáva ho len zverejniť.',
              button: 'Zverejniť'
            },
            DONE: {
              message: 'Profil vašej spoločnosti je pripravený',
              button: 'Upraviť'
            }
          },
          opportunities: {
            title: 'Vaše pracovné pozície, stáže, alebo programy pre talenty',
            NONE: {
              message: 'Vytvorte jednu či viac príležitostí',
              description: 'Predstavte talentom otvorené pracovné pozície alebo talentové programy, ktoré ponúkate',
              button: 'Vytvoriť'
            },
            PENDING: {
              message: 'Príležitost už máte vytvorenú. Zostáva ju len zverejniť.',
              button: 'Zverejniť'
            },
            DONE: {
              message: 'Už máte uverejnené príležitosti',
              button: 'Vytvoriť ďalšiu'
            }
          }
        },
        newBadge: {
          label: 'Nové'
        }
      }
    },
    notifications: {
      cta: {
        // sk translation not necessary
        elf2017: {
          title: 'Zaujalo vás demo naší simulace?',
          message: 'Věnujte nám 1 minutu a získejte přístup k plné verzi',
          action: {
            label: 'Mám zájem',
            url: 'https://goo.gl/forms/2w4jzBH9yhJzKyPf1'
          }
        }
      },
      default: {
        source: 'Notifikácia',
        buttons: {
          dismiss: 'Zrušiť',
          hide: 'Skryť'
        }
      },
      freeAccountBlocked: {
        title: 'Môžeme vám pomôcť?',
        message: 'Registrácia prebehla úspešne, do 24 hodín vám účet overíme. Zatiaľ môžete skúsiť naše DEMO.'
      }
    },
    errors: {
      apiDown: 'Momentálne vylepšujeme {0} Dashboard a čoskoro budeme späť. Ak máte nejaké otázky, použite chat nižšie alebo nás kontaktujte na {1}.'
    },
    creditLimits: {
      creditsUsed: 'Využitých kreditov',
      creditsFree: 'Dostupných kreditov',
      creditsLimit: 'Limit počtu kreditov',
      unlimited: 'Bez limitu počtu kreditov',
      creditsInfo: '1 profil = 5 kreditov',
      upgrade: {
        unlock: '{0[Odomknúť]} uzamknuté profily',
        raise: '{0[Navýšiť]} počet kreditov'
      },
      expiration: {
        unset: 'Bez expirácie',
        set: 'Kredity expirujú {0}'
      }
    },
    discreteMetricChart: {
      anonymousParticipants: '{0} anonymných účastníkov',
      anonymous: 'Anonymný'
    },
    dualMetric: {
      interpretation: {
        teamTitle: 'Tím',
        challengeTitle: 'Výzva',
        opportunityTitle: 'Príležitosť',
        resolutionTitle: 'Odporúčanie'
      }
    },
    analytics: {
      metricsBubbleChart: {
        meanLabel: 'Priemer',
        medianLabel: 'Stredná hodnota'
      },
      dualMetricsMassChart: {
        noDataMessage: 'Žiadne dostupné dáta',
        sortedLabel: 'Usporiadané',
        legend: ['Orientovaní na opačnú dimenziu', 'Menej orientovaní', 'Skôr orientovaní', 'Výrazne orientovaní'],
        zones: ['Upozadené', 'Nevyhranené', 'Vyhranené'],
        description: 'Graf popisuje motivačné a pracovne kultúrne faktory a mieru ich dôležitosti pre jednotlivcov v tíme. Čím výraznejšie sa väčší počet jednotlivcov orientuje na konkrétne oblasti, tým viac kultúrne vyhranená je skupina ako celok.'
      }
    },
    priceList: {
      creditAmount: 'Počet kreditov',
      creditPrice: 'Cena za kredit',
      creditPriceDiscount: 'Vaša zľava',
      numberOfTODProfiles: 'Počet profilov',
      expiry: 'Platnosť kreditov',
      totalPrice: 'Cena celkom',
      discountedPrice: 'Vaša znížená cena',
      topUpButton: 'Vybrať',
      monthFormat: {
        '1': '1 mesiac',
        '2-4': '{0} mesiace',
        '>4': '{0} mesiacov'
      },
      yearFormat: {
        '1': '1 rok',
        '2-4': '{0} roky',
        '>4': '{0} rokov'
      }
    },
    datePicker: {
      okLabel: 'OK',
      cancelLabel: 'Zrušiť'
    },
    participant: {
      personal: {
        seekerStatusTitle: 'Status',
        salaryFreeLabel: 'BEZPLATNE',
        groupTitles: {
          BIO: { title: 'Bio' },
          EDU: { title: 'Vzdelanie' },
          WORK_EXPERIENCE: { title: 'Pracovné skúsenosti' },
          LANGUAGES: { title: 'Jazyky' },
          SKILLS: { title: 'Iné schopnosti' },
          ROLES: { title: 'Roly' },
          SPECIALIZATIONS: { title: 'Špecializácie' },
          INDUSTRIES: { title: 'Odvetvia' },
          PDAS: { title: 'Možnosti rozvoja' },
          LOCATIONS: { title: 'Lokalita' },
          OPPORTUNITY_TYPES: { title: 'Typy príležitostí' },
          SALARY: { title: 'Mzda' },
          BENEFITS: { title: 'Benefity' },
          LOVE_BRANDS: { title: 'Love Brands' }
        }
      }
    },
    copyToClipboardInput: {
      label: 'Hodnota',
      helperText: '{0[Skopírujte do schránky]} alebo vyberte obsah a stlačte Ctrl + C',
      helperTextCopied: 'Úspešne skopírované do schránky'
    },
    teams: {
      teamSelect: {
        searchPlaceholder: 'Vyhľadajte tím, skupinu...'
      },
      memberSelect: {
        invitationLinkMessage: 'Prípadne môžete odoslať nasledujúci odkaz:',
        invitationLinkLabel: 'Pozývací odkaz',
        searchPlaceholder: 'Vyhľadajte alebo zadajte e-mailovú adresu',
        autoInviteCheckboxLabel: 'Automaticky pozvať do nastavených aktivít',
        emptyMessage: 'Vyhľadajte profily v rámci platformy alebo pozvite ľudí e-mailom',
        buttonCancel: 'Zrušiť',
        buttonConfirm: 'Pridať',
        buttonConfirmSingle: 'Nastaviť',
        clickToAddLabel: 'Pridať kliknutím',
        closeMenuLabel: 'Zatvoriť',
        emailInvitationDisabled: {
          searchPlaceholder: 'Vyhľadajte',
          emptyMessage: 'Vyhľadajte profily v rámci platformy',
        }
      },
      memberRow: {
        remindLabel: 'Pripomenúť',
        remindMaxNotificationsReached: 'Dosiahnutý maximálny počet notifikácii',
        remindDisabled: 'Notifikácie deaktivované do {0}',
        inviteLabel: 'Pozvať',
        highlightTooltip: 'Zvýrazniť',
        removeTooltip: 'Odstrániť',
        detailLabel: 'Detail',
        profileLabel: 'Profil',
        activityStats: {
          totalScoreLabel: 'Celkové skóre',
          fitLabel: 'Fit'
        }
      },
      dialogs: {
        activityInvite: {
          inviteLabel: 'Pozvať',
          cancelLabel: 'Zrušiť',
          message: 'Pozvať {0} do nasledujúcich aktivít:',
          messageSingle: 'Pozvať {0} do aktivity {1}?'
        },
        activityRemind: {
          remindLabel: 'Pripomenúť',
          cancelLabel: 'Zrušiť',
          message: 'Pripomenúť {0} nasledujúce aktivity:',
          messageSingle: 'Pripomenúť {0} aktivitu {1}?'
        },
        memberRemove: {
          cancelLabel: 'Zrušiť',
          removeLabel: 'Odstrániť',
          message: 'Odstrániť {0} z tímu?'
        }
      },
      activityAnalytics: {
        teasers: {
          addManagerMessage: '{0[Pridajte]} manažéra tímu, platforma ho otestuje a tu sa zobrazí jeho profil',
          addMembersMessage: '{0[Pridajte]} členov tímu, platforma ich otestuje a tu sa zobrazí ich tímový profil',
          addMembersMessages: {
            TEAM: '{0[Pridajte]} členov tímu, platforma ich otestuje a tu sa zobrazí ich tímový profil',
            GROUP: '{0[Pridajte]} členov skupiny, platforma ich otestuje a tu sa zobrazí ich skupinový profil',
            HIRING: '{0[Pridajte]} uchádzačov, platforma ich otestuje a tu sa zobrazí ich súhrnný profil'
          },
          inviteManagerMessage: '{0} nepozvaný do aktivity',
          inviteLabel: 'Pozvať',
          remindManagerMessage: '{0} nedokončené',
          remindLabel: 'Pripomenúť'
        }
      },
      subGroup: {
        config: {
          message: 'Vyberte kombináciu assessmentov a jazykové verzie',
          assessmentsLabel: 'Assessmenty',
          languagesLabel: 'Jazyky',
          primaryLanguageLabel: 'Primárny jazyk',
          otherLanguagesLabel: 'Ďalšie podporované jazyky',
          cancelLabel: 'Zrušiť',
          goToProjectLabel: 'Nastavenie projektu'
        },
        saveLabel: 'Uložiť',
        changeMemberLabel: 'Zmeniť',
        setMemberLabel: 'Nastaviť',
        addMemberLabel: 'Pridať',
        listTooltip: 'Zoznam',
        chartTooltip: 'Graf',
        interpretationsTooltip: 'Interpretácie',
        configureTooltip: 'Nastavenia',
        configDoneLabel: 'Hotovo',
        memberCountMessage: '{0} z {1} členov',
        memberCountHelperText: 'Môžete zmeniť počet zobrazených členov',
        emptyMemberListMessage: '{0[Pridajte alebo pozvite]} členov tímu',
        emptyManagerListMessage: '{0[Pridajte alebo pozvite]} manažéra tímu',
        emptyMemberListMessages: {
          TEAM: '{0[Pridajte alebo pozvite]} členov tímu',
          GROUP: '{0[Pridajte alebo pozvite]} členov skupiny',
          HIRING: '{0[Pridajte alebo pozvite]} uchádzačov',
        },
        suggestedLabel: 'Navrhované',
        titles: {
          MANAGER: {
            title: 'Manažér'
          },
          MEMBER: {
            title: 'Tím',
            variants: {
              TEAM: 'Tím',
              GROUP: 'Skupina',
              HIRING: 'Uchádzači'
            }
          }
        }
      },
      name: {
        placeholder: 'Pomenujte tím...',
        placeholderVariants: {
          TEAM: 'Pomenujte tím...',
          GROUP: 'Pomenujte skupinu...',
          HIRING: 'Pomenujte otvorenú pozíciu...'
        },
        helperText: 'Premenujte kliknutím',
        focusedHelperText: 'Uložte kliknutím vedľa'
      },
      team: {
        switchLabel: 'Zmeniť',
        newLabel: 'Nový',
        settingsLabel: 'Nastavenia',
        shareLabel: 'Zdieľať',
        deleteLabel: 'Odstrániť',
        generateMembersLabel: 'Vygenerovať členov',
        changeTypeLabel: 'Zmeniť typ skupiny',
        linkHiringLabel: 'Pripojiť otvorenú pozíciu',
        typeSelect: {
          header: 'Vyberte typ skupiny',
          message: '...a získajte unikátny vhľad do kompetencií, schopností, postojov a hodnôt {0}',
          dynamicMessageTexts: ['ľudí vo vašich tímoch', 'ľubovoľných jednotlivcov', 'uchádzačov o prácu']
        },
        tabs: {
          overview: 'Prehľad',
          insights: 'Poznatky',
          list: 'Ľudia',
          analytics: 'Analytika',
          wellBeing: 'Well-being'
        },
        searchTooltip: 'Filtrovať',
        reloadTooltip: 'Aktualizovať'
      }
    },
    dualMetricsInterpretation: {
      interpretationLabel: 'Interpretácia'
    }
  },
  containers: {
    common: {
      notFound: {
        message: 'Skontrolujte prosím správnosť odkazu. Ak máte dojem, že by tu niečo malo byť, kontaktujte našu podporu na {0}.',
        button: 'Zoznam projektov'
      }
    }
  },
  project: {
    active: {
      card: 'Aktívne'
    },
    scheduled: {
      card: 'Plánované'
    },
    finished: {
      card: 'Ukončené'
    },
    concept: {
      card: 'Koncept'
    },
    edit: {
      basic: 'Základné nastavenia',
      texts: 'Texty',
      notifications: 'Notifikácie'
    }
  },
  filter: {
    all: 'Celkové skóre',
    graph: 'Graf účastníkov',
    competency: 'Kompetencia',
    order: 'Usporiadať podľa celkového skóre',
    score: 'Skóre',
    weights: {
      title: 'Nastavenie kompetencií',
      subtitle: 'Celkové skóre, váhy, kľúčové kompetencie',
      weightsInfo: 'Ovplyvňujú spôsob výpočtu celkového skóre a môžu ovplyvniť poradie účastníkov',
      totalScoreLabel: 'Povoliť celkové skóre',
      totalScoreHelper: 'Váhy sú aktívne, len pokiaľ je povolené celkové skóre',
      weightsTitle: 'Váhy kompetencií',
      coreCompetenciesTitle: 'Kľúčové kompetencie',
      buttons: {
        apply: 'Použiť',
        reset: 'Resetovať',
        resetHelper: 'Resetovať na posledné uložené nastavenie',
        level: 'Vyrovnať'
      }
    },
    chart: {
      title: 'Graf účastníkov',
      subtitle: 'Zobrazenie všetkých účastníkov v stĺpcovom grafe'
    }
  },
  forms: {
    partContainer: {
      buttons: {
        back: 'Späť',
        next: 'Ďalšie',
        cancel: 'Zrušiť',
        create: 'Vytvoriť',
        createAndPublish: 'Vytvoriť a uverejniť',
        save: 'Uložiť',
        saveAndPublish: 'Uložiť a uverejniť'
      },
      savedMessage: 'Uložené'
    }
  },
  wizard: {
    step1: 'Zameranie',
    step2: 'Základné nastavenia',
    step3: 'Prispôsobenie textov',
    step4: 'Notifikácie'
  },
  matching: {
    range: {
      and: 'a',
      upper: { label: 'maximálne' },
      lower: { label: 'aspoň' }
    },
    requirements: {
      title: 'Požadujeme',
      education: {
        title: 'Vzdelanie',
        level: {
          title: 'Úroveň'
        },
        fields: {
          title: 'Odbory štúdia'
        },
        currentlyStudying: {
          title: 'Vhodné pre študujúcich'
        }
      },
      workExperience: {
        title: 'Pracovné skúsenosti',
        years: {
          title: 'Roky praxe'
        },
        previousRole: {
          title: 'Skúsenosti s rolou'
        }
      },
      skills: {
        title: 'Schopnosti',
        languages: {
          title: 'Jazyky'
        },
        other: {
          title: 'Iné'
        }
      }
    },
    preferences: {
      title: 'Ponúkame',
      classification: {
        title: 'Zaradenie',
        types: {
          title: 'Typ príležitosti'
        },
        roles: {
          title: 'Roly'
        },
        specializations: {
          title: 'Špecializácie'
        },
        industries: {
          title: 'Odvetvia'
        },
        locations: {
          title: 'Lokalita'
        }
      },
      benefitsAndGrowth: {
        title: 'Ohodnotenie a rast',
        salary: {
          title: 'Mesačná odmena',
          offerLabel: 'Ponúkame'
        },
        benefits: {
          title: 'Benefity',
          unmatchedTitle: 'Jeho ďalšie preferované benefity'
        },
        personalDevelopmentAreas: {
          title: 'Možnosti rozvoja',
          unmatchedTitle: 'Navyše preferuje tieto možnosti rozvoja'
        }
      }
    }  },
  constants: {
    internalText: {
      officeDay: {
        text: 'Vitajte! Viem, že ste u nás {0} a budete mať na starosť {1}, ale dnes by som od vás potreboval pomôcť s inou vecou.',
        descriptionFallback: 'Vitajte! Viem, že ste u nás {0} a určite sa už tešíte, že sa pustíte do svojich úloh, ale dnes by som od vás potreboval pomôcť s inou vecou.'
      }
    },
    positionTypes: {
      INTERNSHIP: {
        label: 'V rámci stáže alebo akadémie',
        description: 'Vyberte túto možnosť, ak vyberáte stážistov alebo v rámci programu rozvoja mladých talentov vo vašej organizácii'
      },
      ADMINISTRATIVE: {
        label: 'Asistentská / Administratívna',
        description: 'Vyberáte na pozíciu typu osobná asistent/ka, asistent/ka oddelenia, sekretárka, back-office pracovník alebo na inú pozíciu, ktorej hlavnou pracovnou náplňou je administratíva'
      },
      SALES: {
        label: 'Obchodná',
        description: 'Vyberáte na pozíciu ako je obchodník, nákupca, referent predaja, pracovník zákazníckej podpory alebo takej, ktorej primárnym zameraním je obchodná činnosť, jej podpora či súvisiace agendy'
      },
      MARKETING: {
        label: 'Marketingová',
        description: 'Vyberáte na pozíciu pracovníka v marketingu, či už ide o online či offline zameranie, kreatívca alebo správcu'
      },
      OTHER: {
        label: 'Iná',
        description: 'Ak vyberáte na inú pozíciu, než sú vyššie uvedené'
      }
    },
    enums: {
      EActivityStatus: {
        REGISTERED: {
          name: 'Nezačaté'
        },
        IN_PROGRESS: {
          name: 'Prebieha'
        },
        FINISHED: {
          name: 'Dokončené'
        }
      },
      EApplicationStatus: {
        NONE: {
          name: 'Žiadny',
          setStatusLabel: 'Zrušiť nastavený status'
        },
        REJECTED: {
          name: 'Odmietnutý',
          setStatusLabel: 'Označiť ako odmietnuté'
        },
        ACCEPTED: {
          name: 'Prijatý',
          setStatusLabel: 'Označiť ako prijaté'
        },
        IN_PROGRESS: {
          name: 'V procese',
          setStatusLabel: 'Označiť ako v procese'
        },
        FUTURE_FIT: {
          name: 'Do budúcna',
          setStatusLabel: 'Označiť ako do budúcna'
        },
        WITHDRAWN: {
          name: 'Odstúpil/a',
          setStatusLabel: 'Označiť ako odstúpené'
        },
        OFFER_REJECTED: {
          name: 'Ponuka neprijatá',
          setStatusLabel: 'Označiť ako neprijatú ponuku'
        }
      },
      EEducation: {
        VOCATIONAL: {
          label: 'Stredná škola – výučný list',
          shortLabel: 'Výučný list'
        },
        SECONDARY: {
          label: 'Stredná škola – maturita',
          shortLabel: 'Maturita'
        },
        POST_SECONDARY: {
          label: 'Vyššia odborná škola – DiS.',
          shortLabel: 'DiS.'
        },
        UNDERGRADUATE: {
          label: 'Vysoká škola – Bc.',
          shortLabel: 'Bc.'
        },
        GRADUATE: {
          label: 'Vysoká škola – Mgr., Ing.',
          shortLabel: 'Mgr./Ing.'
        },
        POST_GRADUATE: {
          label: 'Vysoká škola – doktorský titul',
          shortLabel: 'Doktorský titul'
        },
        PROFESSIONAL: {
          label: 'Profesijný titul – MBA, LL.M',
          shortLabel: 'MBA, LL.M'
        }
      },
      EWorkExperienceYears: {
        ZERO: {
          label: 'Bez pracovnej skúsenosti',
          shortLabel: '0'
        },
        ONE: {
          label: 'Menej než rok',
          shortLabel: '<1 r.'
        },
        THREE: {
          label: '1 – 3 roky',
          shortLabel: '1–3 r.'
        },
        TEN: {
          label: '4 – 10 rokov',
          shortLabel: '4–10 r.'
        },
        MORE: {
          label: 'Viac ako 10 rokov',
          shortLabel: '>10 r.'
        }
      },
      ESeekerStatus: {
        ACTIVE: {
          label: 'Aktívny'
        },
        OPEN: {
          label: 'Otvorený'
        },
        TENTATIVE: {
          label: 'Rozhliada sa'
        },
        INACTIVE: {
          label: 'Nehľadá'
        }
      },
      EWorkExperiencePosition: {
        NO_SPECIALIZATION: 'Bez špecializácie',
        ADMINISTRATIVE: 'Administratívna pozícia',
        SALES: 'Obchodná pozícia',
        EXPERT: 'Špecialista',
        ENTREPRENEURIAL: 'Podnikanie',
        LINE_MANAGEMENT: 'Nižší management',
        MIDDLE_MANAGEMENT: 'Stredný management',
        TOP_MANAGEMENT: 'Top management'
      },
      EFieldOfStudyCategory: {
        ARTS: 'Umenie',
        HUMANITIES: 'Humanitné vedy',
        SOCIAL_SCIENCES: 'Sociálne vedy',
        NATURAL_SCIENCES: 'Prírodné vedy',
        APPLIED_SCIENCES: 'Aplikované štúdiá'
      },
      EFieldOfStudy: {
        GENERAL: 'Všeobecné',
        SERVICES: 'Služby',
        BUSINESS_TRADE: 'Obchod',
        TECHNICAL: 'Remeslo',
        OTHER: 'Iné',
        PERFORMING_ARTS: 'Múzické umenie',
        VISUAL_ARTS: 'Výtvarné umenie',
        GEOGRAPHY: 'Zemepis',
        HISTORY: 'História',
        LANGUAGES: 'Jazyky',
        EDUCATION: 'Vzdelávanie',
        PHILOSOPHY: 'Filozofia',
        THEOLOGY: 'Teológia',
        ECONOMICS: 'Ekonomika / podnikanie',
        LAW: 'Právo',
        POLITICAL_SCIENCE: 'Politológia',
        PSYCHOLOGY: 'Psychológia',
        SOCIOLOGY: 'Sociológia',
        BIOLOGY: 'Biológia',
        CHEMISTRY: 'Chémia',
        MATHEMATICS: 'Matematika',
        PHYSICS: 'Fyzika',
        AGRICULTURE: 'Poľnohospodárstvo',
        COMPUTER_SCIENCE: 'Výpočtová technika',
        ENGINEERING: 'Strojárenstvo a technológie',
        MEDICINE: 'Medicínske a zdravotné vedy'
      },
      EParticipantReport: {
        NONE: {
          label: 'Žiadny',
          description: 'Účastník nedostane report'
        },
        STANDARD: {
          label: 'Základný',
          description: 'Účastník dostane základné interpretácie'
        },
        ENHANCED: {
          label: 'Rozšírený',
          description: 'Účastník dostane interpretácie s odporúčaním a graf kompetencií'
        },
        FULL: {
          label: 'Plný',
          description: 'Účastník dostane interpretácie s odporúčaním a plný graf kompetencií'
        }
      },
      EParticipantReportSimple: {
        NO: {
          label: 'Nie',
          description: 'Účastník nedostane report'
        },
        YES: {
          label: 'Áno',
          description: 'Účastník dostane report s interpretáciami a grafom kompetencií'
        }
      },
      EPoolEndpoint: {
        NONE: {
          label: 'Nie',
          description: 'Projekt nebude slúžiť ako zdroj, ani ako cieľ profilov pre talent pool'
        },
        SOURCE: {
          label: 'Zdroj',
          description: 'Projekt poskytne profily do talent poolu'
        },
        DESTINATION: {
          label: 'Cieľ',
          description: 'Projekt je cieľom pre profily z talent poolu'
        }
      },
      EActivityEventType: {
        IMMEDIATE: {
          label: 'Okamžite',
          description: 'Okamžite po registrácii k aktuálnemu projektu'
        },
        COMPLETED: {
          label: 'Po dokončení',
          description: 'Po dokončení aktivity v rámci aktuálneho projektu'
        }
      },
      EActivityTypeVariant: {
        CULTURE_FIT: {
          CANDIDATE: { label: 'Preferenčný' },
          MANAGER: { label: 'Vnímaný - Manažér' },
          EMPLOYEE: { label: 'Vnímaný - Zamestnanec' }
        }
      },
      EBool: {
        YES: {
          label: 'Áno'
        },
        NO: {
          label: 'Nie'
        }
      },
      EOpportunityType: {
        JOB: {
          label: 'Pracovná pozícia'
        },
        EVENT: {
          label: 'Udalosť'
        },
        EBOOK: {
          label: 'E-kniha'
        },
        VIDEO: {
          label: 'Video'
        },
        COACH: {
          label: 'Tréner'
        },
        ONLINE_COURSE: {
          label: 'Online kurz'
        },
        TALENT_ACADEMY: {
          label: 'Talentová akadémia'
        },
        BOOK: {
          label: 'Kniha'
        },
        ARTICLE: {
          label: 'Článok'
        }
      },
      ELanguageLevel: {
        CONVERSANT: {
          label: 'Základný'
        },
        PROFICIENT: {
          label: 'Pokročilý'
        },
        FLUENT: {
          label: 'Profesionálny'
        },
        NATIVE: {
          label: 'Materinský jazyk'
        }
      },
      ERank: {
        LOW: { label: 'Nízka úroveň' },
        MID_LOW: { label: 'Nízka úroveň' },
        MID: { label: 'Stredná úroveň' },
        MID_HIGH: { label: 'Vysoká úroveň' },
        HIGH: { label: 'Vysoká úroveň' },
        NEUTRAL: { label: 'Neutrálna úroveň' },
        LOCKED: { label: '?' }
      },
      ECompanyRejectReason: {
        NONE: { label: 'Bez udania dôvodu', description: "Keď nechcete uviesť dôvod" },
        FILLED: { label: 'Už obsadené', description: "Ak ste už pozíciu obsadili" },
        OVERFLOW: { label: 'Príliš mnoho kandidátov', description: 'Ak už máte dostatok rozjednaných uchádzačov' },
        BAD_FIT: { label: 'Nesplnené kritéria', description: 'Pretože nesplňuje vaše kritéria' },
        CUSTOM: { label: 'Iný dôvod', description: 'Napíšte vlastný text' }
      },
      EProjectType: {
        POSITION: {
          label: 'Výberové konanie'
        },
        GROUP_ASSESSMENT: {
          label: 'Assessment skupiny'
        },
        TALENT_PROGRAM: {
          label: 'Talent program'
        },
        VIEW: {
          label: 'Zoskupenie'
        },
        QUESTIONNAIRE: {
          label: 'Dotazník'
        }
      },
      EProjectState: {
        ACTIVE: {
          label: 'Aktívne'
        },
        SCHEDULED: {
          label: 'Plánované'
        },
        FINISHED: {
          label: 'Ukončené'
        },
        CONCEPT: {
          label: 'Koncepty'
        }
      },
      EProjectFilterSort: {
        NAME: {
          label: 'Názov'
        },
        MODIFIED_AT: {
          label: 'Posledná modifikácia'
        },
        LATEST_PROFILE_ADDED_AT: {
          label: 'Najnovší profil'
        }
      },
      ECompanyType: {
        STARTUP: { label: 'Startup' },
        ENTERPRISE: { label: 'Veľký podnik' },
        FAMILY_OFFICE: { label: 'Rodinná firma' },
        GLOBAL_ENTERPRISE: { label: 'Globálny podnik' },
        SME: { label: 'Malý a stredný podnik' },
        MICRO_COMPANY: { label: 'Mikro spoločnosť' },
        NNO: { label: 'Neštátná nezisková organizácia' },
        GOVERNMENT: { label: 'Štátna organizácia' },
        NGO: { label: 'Neštátna organizácia' },
        TEAM: { label: 'Tím' },
        ASSOCIATION: { label: 'Spolok' }
      },
      EOpportunityParticipantStatus: {
        UNPROCESSED: {
          label: 'Čakajúci'
        },
        WAITING: {
          label: 'Čakáme na potvrdenie'
        },
        CONNECTED: {
          label: 'V spojení'
        },
        REJECTED: {
          label: 'Odmietnuté'
        }
      },
      EPersonalDetailsExtent: {
        NONE: {
          label: 'Žiadny',
          description: 'Žiadne osobné údaje - môže spôsobiť problémy pri simulácii'
        },
        MINIMAL: {
          label: 'Minimálny',
          description: 'Len meno a priezvisko'
        },
        LIGHT: {
          label: 'Základný',
          description: 'Meno, priezvisko, oslovenie v simulácii'
        },
        FULL: {
          label: 'Plný',
          description: 'Základné bio, vzdelanie, pracovné skúsenosti a schopnosti'
        }
      },
      EGroupType: {
        TEAM: {
          label: 'Tím'
        },
        GROUP: {
          label: 'Skupina'
        },
        HIRING: {
          label: 'Otvorená pozícia'
        }
      }
    },
    charts: {
      labels: {
        dual: {
          DISCRETE_FOUR: mirrorReversed(['Skôr', 'Výrazne'])
        }
      }
    }
  },
  smartsupp: {
    offline: {
      title: 'Napíšte nám správu',
      notice: 'Sme vám k dispozícii. Napíšte nám pomocou formulára nižšie.',
      hideTitle: 'Skryť',
      required: '',
      name: 'Meno',
      email: 'E-mail',
      number: 'Telefón',
      message: 'Vaša správa',
      submit: 'Odoslať správu',
      messageRequiredAlert: 'Je nutné vyplniť telo správy',
      emailRequiredAlert: 'Je nutné vyplniť e-mailovú adresu',
      emailInvalidAlert: 'Zadaná e-mailová adresa nie je platná',
      sendInfo: 'Odosielanie správy...',
      successTitle: 'Ďakujeme',
      successDesc: 'Vaša správa bola úspěšne odoslaná. Budeme vás kontaktovať.',
      failureTitle: 'Chyba',
      failureDesc: 'Je nám ľúto, ale správa nebola odoslaná'
    },
    widget: {
      offline: 'Napíšte nám správu'
    }
  }
};
