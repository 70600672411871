import { mirrorReversed} from '../utils/utils';

export default {
  common: {
    labels: {
      upgrade: 'Premium'
    }
  },
  components: {
    ieUnsupported: {
      title: 'Unfortunately, we no longer support Internet Explorer',
      subtitle: 'Please use Google Chrome, Firefox, Safari or MS Edge instead'
    },
    navigation: {
      sourcing: {
        label: 'Talent Market',
        opportunitiesLabel: 'Opportunities',
        companyProfileLabel: 'Company profile'
      },
      home: {
        label: 'Home'
      },
      assessments: {
        label: 'Assessments'
      },
      designer: {
        label: 'HR-Bots'
      },
      groups: {
        label: 'Teams & Groups'
      },
      lockedModeMessages: {
        SOURCING: {
          message:
            'Attract potential candidates by advertising your job positions, career events, or event favourite personal development books, videos etc. Try {0[Premium]} now and start delivering your content to talents.'
        },
        RECRUITMENT: {
          title: 'Do you need to test your people\'s skills?',
          message:
            'Try {0[Premium account]} and get access to simulation games and HR-bots',
          button: 'Try premium'
        },
        DEVELOPMENT: {
          title: 'Do you want to strategically manage learning and development of your people?',
          message:
            'Try {0[Premium account]} and embrace the power of behavioral data for your growth',
          button: 'Try premium'
        }
      }
    },
    modals: {
      emailSent: {
        text: 'Link to reset your password has been sent to your email address',
        redirect: 'LOG IN'
      },
      invitation: {
        invite: 'Invite new participants to project',
        sendLink: 'Send the link below to participants or {0[leave it to us]}',
        sendLinkManual: 'Send the link below to participants',
        anonymous: 'Anonymous',
        anonymousHelper: 'Anonymous link works out of the box without the need to sign up. However you will not be able to determine who used the link.',
        codeDescription: 'Or, if you want to use the dialogue directly on your website, copy the following code and insert it between the <head> and </head> tags:',
        copyToClipboard: 'Copy link to clipboard',
        done: 'Done',
        cancel: 'Cancel',
        send: 'Send',
        inviteButton: 'Invite',
        sendFromSystem: {
          enterEmailsText: 'Enter the participants\' emails or {0[send them the link personally]}',
          emailsLabel: 'Emails',
          emailsHintText: 'Separate the addresses by a comma or space',
          emailsInvalidText: 'Invalid email'
        },
        invitationSent: {
          title: 'Invitations sent',
          invitedParticipantsLabel: 'Invited participants',
          alreadyExistingParticipantsLabel: 'Already part of the project',
          noParticipantsInvitedText: 'No more participants have been invited. All the email addresses entered are already part of the project.',
          inviteMoreText: 'Would you like to {0[invite more]}?'
        },
        invitationError: {
          message: 'We couldn\'t send the invitations. Would you like to {0[try again]}?'
        }
      },
      changeFormLeave: {
        message: 'You have unsaved changes. Do you want to discard them?',
        confirm: 'Discard',
        cancel: 'Back'
      },
      confirmActivate: {
        msg1: 'Activate the project ',
        msg2: 'After activation, you will not be able to change its name and related texts',
        snackbar1: 'Project for',
        snackbar2: 'has been activated',
        yes: 'Activate',
        createYes: 'Create and activate',
        no: 'Back'
      },
      confirmDelete: {
        msg1: 'Permanently delete the project',
        msg2: 'This cannot be undone',
        snackbar1: 'Project',
        snackbar2: 'has been deleted',
        yes: 'Delete',
        no: 'Back'
      },
      confirmFinish: {
        msg1: 'End the project',
        msg2: 'Users will no longer be able to join it',
        snackbar1: 'Project',
        snackbar2: 'has been ended',
        yes: 'End it',
        no: 'Back'
      },
      accountUpgrade: {
        title: 'Try Premium 14 days for free',
        htmlMessage: '<p>' +
        'Unlock all tools to master your sourcing, recruitment and employee growth:' +
        '<ul>' +
        '<li><b>simulation games</b> to assess competencies</li>' +
        '<li><b>HR-bots</b> to improve your ad conversion and to determine culture-fit</li>' +
        '<li>all <b>analytics data</b> to track performance and key indicators</li>' +
        '</ul>' +
        'Just submit the form. We will contact you and set up everything exactly for you.' +
        '</p>',
        fields: {
          phone: {
            label: 'Your phone number',
            helperText: 'Optional',
            invalid: 'Invalid phone number'
          }
        },
        fieldRequired: 'This field is mandatory',
        valueLengthError: 'Value must be between {0} and {1} characters',
        confirmButton: 'Submit',
        cancelButton: 'Cancel',
        confirmationMessage: {
          success: 'Thank you, we have successfully received your request and will contact you shortly',
          error:
            "We couldn't process your request. If the problems persist, contact our support at {0} or use the chat in the bottom left corner.",
          button: 'Got it'
        }
      },
      creditTopup: {
        title: 'Discover even more profiles with us',
        messages: {
          priceListIntro: 'Thank you for using {0} Dashboard. Do you need to increase the number of available credits? Try one of the following packages:',
          customIntro: 'Couldn\'t make a choice? No worries, just submit this form and we will contact you shortly with the plan to fit your needs.',
          noPriceList: [
            'Thank you for using {0} Dashboard. Do you need to increase the number of available credits?',
            'Just submit this form and we will contact you shortly with the plan to fit your needs.'
          ]
        },
        confirmButton: 'I want custom offer'
      },
      demoPlay: {
        loading: 'Please wait...',
        cancelButton: 'Cancel',
        playButton: 'Play DEMO',
        error: 'Unexpected error occurred. Try again later or contact us for help.',
        message: 'Do you have few minutes? Try a short simulation DEMO.'
      },
      notifications: {
        unfinished: {
          confirmButton: 'Send reminder now',
          cancelButton: 'Not now',
          text: 'We will send a friendly email reminder to selected participants who haven\'t completed the assessment yet. We will include a link through which they can easily jump back in and continue from where they left off.',
          textQuestion: 'Do you want to send reminder to selected participants now?',
          textSingle: 'We will send a friendly email reminder to {0}. We will include a link through which they can easily jump back in and continue from where they left off. Do you want to send reminder now?'
        }
      },
      projectCopy: {
        message: 'Create grouping based on the {0} project',
        nameLabel: 'Name of the grouping',
        groupingAddendum: 'Grouping',
        copyAllParticipantsLabel: 'Copy all participants with finished activity',
        groupingToggleLabel: 'Grouping',
        groupingToggleHelperText: 'Usually it\'s recommended to create a grouping project (view), however under some circumstances a regular project may be created. Difference is in the interaction with participants - while they may be invited into a regular project, a grouping is virtually invisible to them.',
        confirmButton: 'Create',
        cancelButton: 'Cancel'
      },
      participantBatchOperation: {
        COPY: {
          searchPlaceholder: 'Select target project',
          thisProjectLabel: 'Current project',
          message: 'Selected participants\' profiles will be copied from project {0} to project {1}',
          changeProjectLabel: 'Change target project',
          participantListHelper: 'You can deselect items you do not wish to copy',
          operationWarning: 'Heads up! You will copy the participant to a different project than the one to which they were primarily invited. <b>This project\'s name will be visible to the participant in the portal.</b> If you do not want this, use the copy to grouping feature.',
          snackbarMessage: {
            '1': '1 participant copied to project {1}',
            '>1': '{0} participants copied to project {1}'
          },
          confirmButton: 'Copy',
          cancelButton: 'Cancel'
        },
        MOVE: {
          searchPlaceholder: 'Select target project',
          thisProjectLabel: 'Current project',
          message: 'Selected participants\' profiles will be moved from project {0} to project {1}',
          changeProjectLabel: 'Change target project',
          participantListHelper: 'You can deselect items you do not wish to move',
          operationWarning: 'Heads up! You will move the participant to a different project than the one to which they were primarily invited. <b>This project\'s name will be visible to the participant in the portal, and they will no longer see the original project.</b> If you do not want this, use the copy/move to the grouping feature.',
          snackbarMessage: {
            '1': '1 participant moved to project {1}',
            '>1': '{0} participants moved to project {1}'
          },
          confirmButton: 'Move',
          cancelButton: 'Cancel'
        },
        DELETE: {
          message: 'Selected participants\' profiles will be removed from project {0}',
          participantListHelper: 'You can deselect items you do not wish to remove',
          snackbarMessage: {
            '1': '1 participant removed',
            '>1': '{0} participants removed'
          },
          confirmButton: 'Remove',
          cancelButton: 'Cancel'
        }
      },
      opportunityParticipantsSetResponse: {
        cancelLabel: 'Cancel',
        confirmLabel: {
          ACCEPTED: 'Show interest',
          ACCEPTED_INTERESTED: 'Confirm interest',
          ACCEPTED_ACCEPTED: 'Confirm sign up',
          REJECTED: 'Reject'
        },
        message: {
          ACCEPTED_ACCEPTED: {
            main: 'Would you like to accept {0} sign up?',
            description:
              "By accepting the applicant's sign up, you're letting them know you wish to include them in the opportunity selection process. You'll also receive their contact info so you may communicate with them directly."
          },
          ACCEPTED_INTERESTED: {
            main: 'Would you like to confirm your interest in {0}?',
            description:
              "You're letting them know your interest in their profile. They will be notified and asked to confirm their interest. You'll get their contact info after confirmation."
          },
          ACCEPTED: {
            main: 'Would you like to show your interest in {0}?',
            description:
              "You're letting them know your interest in their profile. They will be notified and asked to confirm their interest. You'll get their contact info after confirmation."
          },
          REJECTED: {
            main: 'Please give us a reason for rejecting {0}'
          }
        },
        rejectReasonsLabel: 'Pick a reason',
        rejectReasonTextLabel: 'Write your reason',
        rejectReasonTextHelperText: 'This text will be shown to the applicant'
      }
    },
    cards: {
      pagination: {
        pageInfoMessage: 'You are on the page {0}',
        goToStartLabel: 'Go to beginning'
      },
      project: {
        from: 'from',
        to: 'to',
        noRestriction: 'Always open',
        latestProfileLabel: 'Latest profile',
        applicantCount: 'Number of participants',
        ended: 'completed',
        started: 'started',
        new: 'registered',
        showApplicants: 'Detail',
        tags: {
          DEMO: 'Preview'
        }
      },
      projectAdd: {
        text: "We're working hard so you will soon be able to create your own projects",
        textDisabled:
          'You will be able to create a new project once we authorize your account. We may contact you for this purpose within 24 hours. Thank you for your interest.'
      },
      demoPlay: {
        text: 'Would you like to see the participant\'s experience in the simulation?',
        button: 'Try the DEMO',
        resolutionError: 'Your browser screen resolution is not sufficient for the full simulation experience. At least 1200x638 pixels is required.'
      },
      guideShow: {
        text: 'Go back to guide and learn about the most important features of the {0} platform',
        button: 'Open guide'
      }
    },
    grid: {
      paginator: {
        rows: 'Rows on the page:',
        of: 'of'
      }
    },
    iconMenu: {
      edit: 'Edit',
      delete: 'Delete',
      activate: 'Activate',
      finish: 'End it',
      export: 'Export',
      exportXLSX: 'Export to XLSX',
      exportCSV: 'Export to CSV',
      reactivate: 'Reactivate',
      detail: 'Detail',
      invite: 'Invite participants',
      createGrouping: 'Create grouping'
    },
    snackbar: {
      error: 'Unexpected error. Please wait a moment and try again',
      link: {
        common: 'Link has been copied to clipboard. Press Ctrl + V to paste into text',
        mac: 'Link has been copied to clipboard. Press Cmd + V to paste into text'
      }
    },
    sidebar: {
      overview: 'Overview',
      new: 'Create new',
      back: 'Back',
      help: 'Help',
      userGuide: {
        label: 'User guide'
      },
      guide: {
        label: 'Guide'
      }
    },
    projectFilter: {
      hint: 'Search',
      advanced: 'Advanced filter',
      projectTypesLabel: 'Project types',
      activityTypesLabel: 'Activities',
      languagesLabel: 'Languages',
      projectStatesLabel: 'Project states',
      refreshTooltip: 'Reload',
      sortTooltip: 'Sort by',
      activeFirstLabel: 'Active projects first',
      showDemoProjects: 'Show demo projects',
      showDemoProjectsHelper: 'Always sorted last',
      newLabel: 'New',
      newLabelHelper: 'Create new assessment',
      noProjectFoundMessage: 'We haven\'t found any assessment project',
      noProjectFoundMessageCanAdd: 'We haven\'t found any assessment project. You may create one by clicking the "NEW" button on the top right.',
      fromValuesFilters: {
        activityType: {
          label: 'Activities'
        },
        language: {
          label: 'Languages'
        },
        projectState: {
          label: 'Project states'
        },
        projectType: {
          label: 'Project types'
        },
        finishedNonEmpty: {
          label: 'Finished profiles'
        }
      },
      layout: {
        CARD: { tooltip: 'Display cards' },
        LIST: { tooltip: 'Display list' }
      }
    },
    guide: {
      initial: {
        slide1: {
          title: 'Do you know why you\'re here?',
          subtitle: 'You\'re here because you want to learn something about people. You want to know the answers for which you are often looking through intuition, resumes, skill tests and surveys. Today it is not enough. You know that you are missing the facts. Facts about your job candidates and employees. You came here for them. And you\'re in the right place.'
        },
        slide2: {
          title: 'People want to get a fair chance to show their potential',
          subtitle: 'Let them do it. How? Invite them to a simulation game. They\'ll get a chance to prove themselves and even take away a great experience from your recruitment. And in return, you get insights into their competencies. Instantly, objectively, and without biting off precious time from your busy days.',
          buttonLabel: 'Play DEMO'
        },
        slide3: {
          title: 'Your intuition will never be alone',
          subtitle: 'You will have clear facts at your fingertips. Behavioral diagnostic data about the people. Data that help you uncover their strengths and weaknesses. Conclusions and recommendations, with which you will make better decisions in recruitment, selection and development of your people.'
        },
        slide4: {
          title: 'You don\'t let anybody down',
          subtitle: 'You earn favor of high achievers with well-aimed praise and appreciate the effort of those who didn\'t make the cut. Competence badges and personalized reports are there to make sure everybody gets something positive out of their invested time.'
        },
        slide5: {
          title: 'Are you ready to upgrade your HR?',
          subtitle: 'Who you let in your team is crucial. The more good decisions you make right at the beginning, the less problems you will have to deal with in the future.'
        }
      },
      initialV2: {
        slide1: {
          title: 'Your journey to talent decision cloud starts here',
          subtitle: 'Welcome! Complete this short guide to understand the basic principles of Behavera Talent Decision Cloud.'
        },
        slide2: {
          title: 'Benchmark your talents against our simulation',
          subtitle: 'We use simulation games to measure behavioral competencies. Designed by I/O psychologists, game developers, talent coaches and hiring managers and tested with thousands of people, it captures human skills as demonstrated in various situations. It is not a boring questionnaire or a logic puzzle. It is a next-gen assessment game.',
          buttonLabel: 'Play the 5 min demo'
        },
        slide3: {
          title: 'Invite up to 3 people from your team',
          subtitle: 'By inviting people they receive a link that takes them into the simulation game. Choose 3 people from your team who have the right mindset to accompany you in this journey. Make sure they have enough time to focus on the simulation and to finish it. You’ll get your first three profiles and your team get badges representing their strengths.'
        },
        slide4: {
          title: 'Make talent decision cloud work for you',
          subtitle: 'Once the simulation game is completed, the results are automatically available to you. Then is the best time to prioritize which behavioral competencies are the key for you. We will help you to set it up in a short video-call.'
        },
        slide5: {
          title: 'Unleash the power of talent decision cloud',
          subtitle: 'You are good to go. Use talent decision cloud to empower your talent sourcing, hiring and development strategy. Let’s get started, we are ready. Are you?'
        }
      }
    },
    pages: {
      public: {
        login: {
          text: 'Log in',
          claims: [
            'Attract people with simulation',
            'Measure competencies with behavioral data',
            'Change your hiring and development with Talent Decision Cloud'
          ],
          forgottenPwd: 'Forgotten password',
          submit: 'LOG IN',
          lang: 'Language',
          email: 'Email',
          pwd: 'Password',
          error: 'Wrong password / email',
          errorAccessDenied: 'Access denied'
        },
        resetPassword: {
          text: 'Forgotten password',
          newPwd: 'Enter the new password',
          repeatNewPwd: 'Repeat the new password',
          lang: 'Language',
          errorEqual: 'Passwords not matching',
          errorWidth: 'Passwords must be 8 characters or more',
          submit: 'Submit'
        },
        resetPasswordEmail: {
          text: 'Forgotten password',
          lang: 'Language',
          notice: 'Link to reset your password will be sent to your email address',
          setMail: 'Enter your email',
          submit: 'Submit',
          error: 'Invalid email address',
          errorNotFound: 'User with given email not found'
        },
        accountNew: {
          text: 'Create your account',
          submit: 'Create account',
          fields: {
            firstName: {
              label: 'Name'
            },
            lastName: {
              label: 'Surname'
            },
            email: {
              label: 'Your work e-mail',
              invalidEmail: 'Invalid e-mail',
              alreadyExists: 'User with given e-mail already exists',
              freeEmail: 'Unsupported e-mail, use your work-related one'
            },
            phone: {
              label: 'Your phone number',
              invalidPhone: 'Invalid phone number'
            },
            promoCode: {
              label: 'Promo code',
              helperText: 'Do you have a promo code? Enter it now to get discounts and other benefits'
            },
            password: {
              label: 'Choose password'
            },
            repeatPassword: {
              label: 'Repeat password',
              passwordsDontMatch: "Passwords don't match"
            },
            language: {
              label: 'Language'
            },
            companyName: {
              label: 'Company name',
              alreadyExists: 'Company with given identifier already exists'
            },
            companyConfirm: {
              label: 'I confirm that I work for the company',
              required: 'You need to confirm to setup the account for the company'
            },
            marketingConfirm: {
              label: 'Send me occasional email updates about what new features and simulation games you are working on'
            },
            tosConfirm: {
              label: 'I read and agree to the {0[Terms of Service]} and {1[Privacy Policy]}',
              required: 'You need to agree to use the service'
            }
          },
          requiredMessage: '* Required fields',
          fieldRequired: 'This field is mandatory',
          valueLengthError: 'Value must be between {0} and {1} characters',
          successMessage: {
            text1: 'Your account has been created and we have sent you a confirmation email to your address.',
            text2: 'Click on the link in the email to activate your account.',
            text3: 'If the message does not arrive within 20 minutes, check your SPAM folder as well as other folders you use for sorting your incoming mail.',
            button: 'Got it'
          },
          errorMessage: {
            text1:
              "We couldn't create your account. If the problems persist, contact our support at {0} or use the chat in the bottom right corner.",
            button: 'Got it'
          }
        },
        accountActivation: {
          text: 'Activate your {0} account',
          submit: 'Activate',
          successMessage: 'Account successfully activated',
          errorMessage: "We couldn't activate your account. Please check the link validity"
        },
        common: {
          loginFooter: 'Already have a {0} account? {1[Log in]}',
          newAccountFooter: "Don't have a {0} account yet? {1[Sign up]}"
        }
      },
      private: {
        project: {
          applicantDetail: {
            back: 'Back',
            print: 'Print',
            evaluation: 'Evaluation',
            publicProfile: 'Public profile',
            idSim: 'IDSIM',
            projectName: 'Project',
            simulationName: 'Activity',
            added: 'User added',
            finished: 'Finished',
            exported: 'Exported',
            strengths: 'Strengths',
            weakness: 'Weakness',
            competencies: 'Competencies overview',
            wellBeing: 'Employee well-being - Risk factors',
            noRiskFactorsMessage: 'No risk factors',
            causesLabel: 'Most common causes',
            indicators: 'Work-related indicators',
            recommendations: 'Further recommendations',
            interpretations: 'Conclusions and recommendations',
            dimensions: 'Dimensions',
            unfinishedMessage: 'The participant has not finished the activity yet',
            operationalization: 'Operationalization',
            operationalizations: 'Operationalizations',
            coreCompetenciesLabel: 'Core',
            otherCompetenciesLabel: 'Other',
            hideLabel: 'Hide',
            showLabel: 'Show',
            factors: 'Factors',
            durationStats: {
              preparation: 'Preparations',
              simulation: 'Time on tasks',
              total: 'Total time',
              description: 'Total time consists of the time spent on preparations and working on tasks'
            },
            chart: {
              position: 'Rank',
              score: 'Score',
              skipped: 'Skipped tasks'
            },
            compareTooltip: 'Compare',
            lockedPrintMessage: 'Upgrade to print',
            lockedProfileMessage: '{0[Upgrade]} to see this profile',
            lockedRecommendationsMessage:
              '{0[Upgrade]} to find out what to focus on when further working with the participant',
            lockedSkillsDescriptionMessage:
              "{0[Upgrade]} to see the competency description and the participant's score summary",
            lockedIndicatorMessage: '{0[Upgrade]} to find out the participant’s work-related indicator'
          },
          applicantCompare: {
            removeButton: 'Remove',
            addButton: 'Add profile to comparison',
            searchPlaceholder: 'Search profile'
          },
          applicantList: {
            anonymous: 'Anonymous',
            back: 'Back to overview',
            invite: 'Invite participants',
            inProgress: 'in progress',
            unstarted: 'registered',
            searchTooltip: 'Search participants',
            highlightTooltip: 'Highlight participants in analytics',
            refreshTooltip: 'Reload',
            rejected: {
              INVITATION: 'invitation rejected',
              REGISTRATION: 'registration cancelled'
            },
            pending: {
              INVITATION: 'invited',
              REGISTRATION: 'registration pending'
            },
            inviteTooltip: {
              CONCEPT: 'Project is not activated yet',
              SCHEDULED: 'Project has not started yet',
              ACTIVE: 'Invite participants',
              FINISHED: 'Project has ended'
            },
            demo: {
              weightApplyDisabled: 'Custom weights cannot be saved for DEMO project'
            },
            empty: {
              valid: 'You have no participants yet. Invite them via ',
              invalid: 'List of participants is empty'
            },
            cols: {
              position: 'Rank',
              name: 'Name',
              score: 'Score',
              goodAt: 'Strengths',
              weakAt: 'Weakness',
              num: 'Num',
              finishedAt: 'Finished',
              competencies: 'Competencies',
              applicationStatus: 'Status',
              metrics: 'Metrics'
            },
            applicationStatusEnum: {
              NONE: 'None',
              REJECTED: 'Rejected',
              ACCEPTED: 'Accepted'
            },
            lockedChartMessage:
              'Sort and view profiles according to specific parameters. {0[Upgrade]} to use this feature',
            upgradeMessage: '{0[Upgrade]} to see this profile',
            compareButton: 'Compare',
            highlightButton: 'Highlight',
            highlightHelperText: 'Highlight participants in the analytics',
            intraComparison: {
              label: 'Against each other',
              description: 'Compare against each other'
            },
            crossComparison: {
              label: 'Across projects',
              description: 'Compare against other project\'s participants'
            },
            markAsAccepted: 'Mark as accepted',
            markAsRejected: 'Mark as rejected',
            unsetApplicationStatus: 'Remove status',
            notifications: {
              unfinished: {
                lastNotified: 'Last reminder sent {0}',
                neverNotified: 'Reminder not yet sent',
                maxCountReached: 'Maximum number of reminders reached',
                timeLimitReached: 'Reminder disabled for 2 days',
                buttonLabel: 'Remind'
              }
            },
            crossComparedFromProjectMessage: 'From {0} project',
            actions: {
              copyLabel: 'Copy to...',
              moveLabel: 'Move to...',
              deleteLabel: 'Remove',
              remindLabel: 'Remind',
              addToTeamsLabel: 'Add to groups/teams...',
              addTagsLabel: 'Add tags...'
            }
          },
          weighted: {
            name: 'Weights active',
            description: 'Custom competencies weights for this project have been set'
          },
          customModel: {
            name: 'Custom competency model',
            description: 'Custom competency model has been set for this project'
          },
          overview: {
            tabs: {
              activity: 'Activity',
              list: 'Participants list',
              analytics: 'Analytics',
              context: 'Context'
            }
          },
          participantFilter: {
            fullTextPlaceholder: 'Search',
            matchAllLabel: 'Matches all',
            fromValuesFilters: {
              activityStatus: {
                label: 'Activity status'
              },
              applicationStatus: {
                label: 'Status'
              },
              metricsScore: {
                label: 'Score range'
              },
              metricsDuration: {
                label: 'Activity duration'
              },
              registeredAt: {
                label: 'Registered'
              },
              finishedAt: {
                label: 'Finished'
              },
              bioAge: {
                label: 'Age'
              },
              seekerStatus: {
                label: 'Seeker status'
              },
              skillsLanguages: {
                label: 'Languages'
              },
              educationFields: {
                label: 'Fields of study'
              },
              educationLevel: {
                label: 'Education'
              },
              educationCurrentlyStudying: {
                label: 'Still studying'
              },
              employmentWorkExperience: {
                label: 'Work experience'
              },
              employmentLastWorkPosition: {
                label: 'Last work position'
              },
              preferencesRoles: {
                label: 'Roles'
              },
              preferencesSpecializations: {
                label: 'Specializations'
              },
              preferencesIndustries: {
                label: 'Industries'
              },
              preferencesOpportunityTypes: {
                label: 'Opportunity types'
              },
              preferencesLocations: {
                label: 'Locations'
              },
              preferencesPersonalDevelopmentAreas: {
                label: 'Personal development'
              },
              preferencesBenefits: {
                label: 'Benefits'
              },
              preferencesSalaryRange: {
                label: 'Salary range',
                description: 'In CZK per month, calculated based on an approximately set exchange rate'
              },
              preferencesLoveBrands: {
                label: 'Love brands'
              },
              skillsOther: {
                label: 'Other skills'
              },
              tags: {
                label: 'Tags',
                noneLabel: 'Without tags'
              }
            }
          },
          analytics: {
            noParticipants: 'No participants have finished the simulation yet',
            metricBubbleChart: {
              title: 'Group competency map',
              subtitle: 'Individual competencies score frequency overview'
            },
            scatterChart: {
              title: 'Competency fit',
              subtitle: 'Primary vs secondary competencies scatter chart',
              description: 'The chart shows the relationship between primary (selected by you) and secondary (all other) participants\' competencies. Axis X (horizontal) and Y (vertical) represents weighted mean of primary and secondary competencies respectively. Assignment to one of the 4 displayed quadrants depicts how the participant matches your configuration.',
              competenciesLabel: 'Competencies',
              primaryCompetenciesLabel: 'Primary competencies',
              secondaryCompetenciesLabel: 'Secondary competencies'
            },
            crossComparison: {
              button: 'Cross-compare',
              cancelButton: 'Cancel comparison',
              cancelHighlightButton: 'Cancel highlight',
              thisProjectLabel: 'This project',
              noProjectsLabel: 'No other projects',
              searchProject: 'Search project',
              projectSelect: {
                cancel: 'Cancel',
                change: 'Change',
                comparisonEmptyMessage: '{0[Compare]} to project',
                comparisonMessage: 'Comparing to {0}'
              }
            }
          },
          questionnaireAnalytics: {
            removeParticipantFromComparisonButton: 'Remove',
            anonymous: 'Anonymous',
            singleComparisonMessage: 'Click on the row to compare a single participant. Click again to activate all rows.',
            managerialComparisonLabel: 'Compare as a team manager',
            overviewTitle: 'Team overview',
            overviewSubtitle: 'What the team profile looks like based on the completed Culture Fit questionnaires',
            overviewSubtitleComparison: 'Comparing individuals to the team profile and percentual culture fit'
          }
        },
        projectEdit: {
          buttons: {
            back: 'Back',
            next: 'Next',
            close: 'Cancel',
            save: 'Save',
            create: 'Create',
            saveActivate: 'Save and activate',
            createActivate: 'Create and activate'
          },
          projectTypes: {
            POSITION: {
              label: 'Open position',
              tag: 'Open position',
              description: 'Select this option if you want to do a screening or assessment of candidates as a part of the hiring process for an open position',
              overrides: {
                projectNameLabel: 'Position name',
                projectNameHelper: 'Enter the name of the position you are hiring to fill using this activity'
              }
            },
            GROUP_ASSESSMENT: {
              label: 'Team or group',
              tag: 'Group assessment',
              description: 'Select this option if you want to do a screening or assessment within a particular internal group or a team',
              overrides: {
                projectNameLabel: 'Group name',
                projectNameHelper: 'Fill in the name of the team or group that is going to be subject of this assessment'
              }
            },
            TALENT_PROGRAM: {
              label: 'Talent program',
              tag: 'Talent program',
              description: 'Select this option if you want to do screening or assessment as a part of your internship, trainee, or talent program',
              overrides: {
                projectNameLabel: 'Talent program name',
                projectNameHelper: 'Name your talent program (eg. academy, internship, trainee program, etc...)'
              }
            },
            VIEW: {
              label: 'Grouping',
              tag: 'Grouping',
              description: 'Select this option if you want to group the profiles from different assessments. This project is hidden for the participant and serves mostly for analytical purposes.',
              overrides: {
                projectNameLabel: 'Name of the grouping',
                projectNameHelper: 'Fill in the name of the grouping'
              }
            },
            QUESTIONNAIRE: {
              label: 'Questionnaire',
              tag: 'Questionnaire'
            }
          },
          intro: {
            title: 'Create a new assessment for',
            editTitle: 'Assessment for'
          },
          generalSettings: {
            simNameLabel: 'Activity',
            projectNameLabel: 'Project name',
            projectNameHelper: 'Enter the name of the project you are hiring to fill by this activity',
            posTypeLabel: 'Position type',
            posTypeHelper: 'Used to adapt the story for the activity participants',
            companyLabel: 'Select company',
            languageLabel: 'Activity language',
            dateTitle: 'Project validity',
            dateHelper: 'Enter the end date by which the participants can sign up and complete the assignment',
            reportTitle: 'Report',
            reportHelper: 'Select whether you want to make the report available to participants immediately after they complete the activity. Setting can be later changed globally or for individuals.',
            alwaysOpenLabel: 'Always open',
            dateStartLabel: 'Start date',
            dateEndLabel: 'End date',
            dateEndHelperText: 'Including the set date',
            advancedSettings: 'Advanced settings',
            participantReportLabel: 'Participant report',
            supportedLanguagesLabel: 'Other supported languages',
            supportedLanguagesHelper: 'Enables the participants to choose the activity language',
            viewLabel: 'Project is a grouping',
            viewDescription: 'Groupings don\'t allow direct interaction with participants - they are invisible to them. A grouping may serve as an envelope of participants that have gone through the same kind of assessment as is configured within this project.',
            poolEndpointLabel: 'Talent pool endpoint',
            talentPoolsLabel: 'Talent pools',
            talentPoolsHelper: 'Project participant will be qualified for selected talent pools',
            allowedEmailDomainsLabel: 'Allowed e-mail domains',
            allowedEmailDomainsHelper: 'You can restrict registration to this project for certain email domains (e.g. "gmail.com"). This does not apply to invitations.',
            allowedEmailDomainsErrorInvalid: 'Invalid domain, try "gmail.com" format',
            posterEnabledLabel: 'Poster for participants',
            posterEnabledToggleDisabledDescription: 'Currently available only for preferential Culture Fit',
            posterEnabledDescription: 'Available in the report section in portal once the activity has been completed',
            reportChatLabel: 'Chatbot on report',
            reportChatHelper: 'Select a chatbot which will appear for participant on report',
            targetViewsLabel: 'Target views',
            targetViewsHelper: 'Profiles will be automatically copied to "view projects" you select',
            flowProjectIdLabel: 'Next project',
            flowProjectIdHelper: 'You may define project flow where the participant will be invited to the selected project after their registration or invitation to the current one',
            flowProjectEventTypeLabel: 'When to invite',
            seekerQualifierEventTypeLabel: 'Qualify seeker',
            seekerQualifierEventTypeHelperText: 'Project may qualify participant for the seeker role',
            personalDetailsExtentLabel: 'Personal details extent',
            personalDetailsExtentHelper: 'Enter the extent of personal data the participant needs to have filled before starting the assessment'
          },
          texts: {
            introTitle: 'Welcoming message for participants',
            introHints: [
              'Welcoming message introduces the participants into the activity on your behalf and provides them with the basic information.',
              'You can use the default message below or customize it.'
            ],
            customize: 'Customize',
            reset: {
              button: 'Reset',
              confirmMessage: 'Do you really want to reset the text?',
              yes: 'Yes',
              no: 'No'
            },
            internalNameLabel: 'Candidate will apply for position {0} but we call it internally',
            internalNameHelper: 'Name the position in a way you want to refer to it in the activity',
            internalDescriptionLabel: 'And his job description and main responsibility will be',
            internalTextPreviewHelper: 'In the activity it may look like this. Does the text make sense? If not, modify it above until you like it',
            reportTitle: 'Message upon completion',
            reportHints: [
              'The message is displayed to the user after completion of the activity.',
              'You can use the default message below or customize it'
            ]
          },
          notifications: {
            recipientsLabel: 'Your team',
            recipientsHelper: 'Select one or more members of your team, who shall receive notifications about events in this project',
            select: 'Select',
            frequencyLabel: 'Frequency of notifications',
            frequencyHint: 'Select the frequency of notifications',
            frequencyEnum: {
              NEVER: 'Never',
              HOURLY: 'Hourly',
              DAILY: 'Daily',
              WEEKLY: 'Weekly'
            }
          },
          snackbar: {
            createdMessage: 'Project {0} has been created',
            savedMessage: 'Changes for project {0} have been saved'
          },
          validation: {
            languageChangeWarn: 'You have changed the activity language. Saving this will replace the content in TEXTS tab to the new language default',
            projectTypeEmpty: 'Select project type',
            projectNameEmpty: 'Name the project',
            posTypeEmpty: 'Select position type',
            companyEmpty: 'Select company',
            simulationEmpty: 'Select activity',
            languageEmpty: 'Select language',
            startDateAfterEndDate: 'Start date must be before end date',
            shortenedDateInterval: 'Project period can only be prolonged',
            endDateInPast: 'End date must be in the future',
            endDateNotSet: 'Set the end date',
            notificationsRecipientsEmpty: 'Select recipients or set frequency to "Never"',
            frequencyEmpty: 'Select frequency or remove recipients'
          },
          projectStatus: {
            activeWithEnd: 'This project is active and will end on {0}',
            activeUnlimited: 'This project is active and always open',
            planned: 'This project starts on {0}',
            concept: 'This project is a draft. To invite participants you need to activate it'
          }
        },
        designer: {
          canvas: {
            node: {
              placeholder_DIALOGUE_IMPULSE: 'Write a dialogue impulse...',
              placeholder_DIALOGUE_REPLY: 'Write a reply...',
              defaultPersonName: 'Bibi Talent',
              defaultPersonInfo: 'Digital Recruitment Guru',
              emptyLabel: 'Empty'
            }
          },
          editor: {
            saveButton: 'Save',
            autoArrangeButton: 'Arrange',
            namePlaceholder: 'Name me...',
            propertiesForm: {
              redirectUrlLabel: 'Redirect URL',
              redirectTimeoutLabel: 'Redirect timeout',
              redirectReportLabel: 'Redirect to report',
              redirectTopLabel: 'Redirect full page',
              summaryMailTitle: 'Summary email',
              summaryMailDescription: 'We can send you a summary of the variables and their values after the simulation completes.',
              summaryMailLabel: 'Email address',
              defEnvLabel: 'Default environment',
              preview: 'Preview',
              envLabel: {
                PARK: 'Park',
                OFFICE: 'Office',
                COUNTER: 'Counter'
              },
              defLangLabel: 'Language',
              languageTitle: 'Conversation language',
              languageDescription: 'Conversation, its context and controls are primarily set in the given language',
              companyTitle: 'Company',
              companyDescription: 'Select company for this conversation',
              companyLabel: 'Company',
              environmentTitle: 'Environment',
              environmentDescription:
                'The simulation will be set in the environment you select. Currently only background is influenced',
              variableDefinitionsTitle: 'Variables',
              variableDefinitionsDescription: 'You may define variables that can be used throughout the process to change the flow based on values assigned or altered when user selects specific path. Variable name should only consist of alphabetical characters, e.g. \'myOwnVariable\' (no spaces, hyphens or any other special characters).',
              variableDefinitionAddButton: 'Add variable',
              variableDefinitionNameLabel: 'Variable name',
              variableDefinitionValueLabel: 'Initial value',
              inputParamsTitle: 'Simulation variables',
              inputParamsDescription:
                'The set of simulation variables that may be assigned with different values based on the operations defined on the user actions (e.g. selected dialogue answer). These variables may also be initialized by the URL parametres. Variables then serve to collect data (reported or not), define redirect URL or even to customize dialogue messages - may be used in the text in the form of {{variableName}}. Identifying variables are used to determine whether there is an ongoing simulation.',
              redirectTitle: 'Redirect',
              redirectDescription:
                'After the simulation is completed the users may be redirected to a URL specified here. It may contain variables that are defined in the previous section, e.g. http://www.example.org/identifier={variableName}',
              redirectTimeoutDescription:
                'You may also define timeout in seconds, after which the user will be redirected. Default value is 6 seconds',
              inputParamNameLabel: 'Variable name',
              inputParamIdentifyingLabel: 'Identifying',
              inputParamTypeLabel: 'Type',
              inputParamReportedLabel: 'Reported',
              inputParamLabelLabel: 'Reported name',
              inputParamMapsToMetricLabel: 'Mapped to metric',
              inputParamMetricLabel: 'Metric',
              inputParamMetricMinValueLabel: 'Minimum value',
              inputParamMetricMaxValueLabel: 'Maximum value',
              inputParamMetricMappingReversedLabel: 'Reverse mapping',
              addInputParamButton: 'Add variable',
              removeInputParamButton: 'Remove',
              inputParamTypes: {
                STRING: 'String',
                NUMBER: 'Number'
              },
              projectTitle: 'Project',
              projectDescription: 'Project settings',
              projectNameLabel: 'Project name',
              projectNameHelperText: 'Takes the model name by default',
              simulationTitle: 'Simulation',
              simulationDescription: 'Simulation settings. Simulation type defines metrics, to which the numeric variables may be mapped.',
              simulationKeyLabel: 'Type',
              simulationVariantLabel: 'Variant',
              simulationVariants: {
                candidate: 'Preferential',
                manager: 'Perceptive - Manager',
                employee: 'Perceptive - Employee'
              },
              simulationNameLabel: 'Name',
              simulationNameHelperText: 'Takes the simulation type name or model name by default',
              lafTitle: 'Look & Feel',
              lafDescription: 'Simulation look & feel configuration',
              lafAnswerFormatLabel: 'Answer format',
              lafAnswerFormatTypes: {
                SAME: 'Same'
              },
              lafTypingDurationEnabledLabel: 'Typing effect enabled',
              validation: {
                unknownParameter: "Unknown variable '{0}'",
                languageRequired: 'Language required',
                companyRequired: 'Company required',
                parameterNameRequired: 'Name required',
                parameterNameNotUnique: 'Name already present',
                parameterNameInvalid: 'Invalid format - may contain letters or an underscore only',
                parameterLabelRequired: 'Required',
                parameterTypeRequired: 'Select type',
                redirectTimeoutNegative: 'Timeout cannot be negative',
                projectNameRequired: 'Name required',
                simulationNameRequired: 'Name required',
                nameLengthAtLeast: 'Name length must be at least {0}',
                mappingMetricRequired: 'Select metric',
                mappingMinValueRequired: 'Enter a minimum value',
                mappingMaxValueLessThanMin: 'Maximum value cannot be less than minimum',
                invalidEmail: 'Invalid email'
              }
            },
            nodeSettingsForm: {
              confirmButton: 'Confirm',
              cancelButton: 'Cancel',
              variableOperationsTitle: 'Variable operations',
              variableOperationsDescription: 'Define variable operations that will be executed when user chooses this path (e.g. by selecting given reply)',
              variableOperationsEmpty: 'No operations defined yet',
              variableOperationVariableLabel: 'Variable',
              variableDefinitionsEmpty: 'First define variables in the sidebar',
              variableOperationOperationLabel: 'Operation',
              variableOperationValueLabel: 'Value',
              variableOperationAddButton: 'Add operation',
              variableOperationVariableRequired: 'Select variable',
              variableOperationOperationRequired: 'Select operation',
              variableOperationValueRequired: 'Set value',
              variableOperationValueNotVariable: 'Non-existent variable',
              operations: {
                ASSIGN: {
                  label: 'assign'
                },
                ADD: {
                  label: 'increment by'
                },
                MULTIPLY: {
                  label: 'multiply by'
                },
                ASSIGN_VARIABLE: {
                  label: 'assign variable'
                },
                ASSIGN_RESULT: {
                  label: 'assign content'
                }
              }
            }
          },
          modelList: {
            filterPlaceholder: 'Search',
            createButton: 'Create',
            confirmDeleteButton: 'Confirm',
            emptyListMessage: 'No simulation models yet. You can start by adding one via "CREATE" button',
            unnamedItemName: 'Unnamed',
            modelCountMessage: '{0} models in total',
            nameHeader: 'Name',
            createdHeader: 'Created',
            modifiedHeader: 'Last modified'
          }
        },
        opportunities: {
          search: 'Search',
          previewTooltip: 'Preview',
          editLabel: 'Edit',
          deleteLabel: 'Delete',
          presentedLabel: 'Published',
          descriptionLabel: 'Description',
          tagsLabel: 'Tags',
          statsLabel: 'Stats',
          participantsLabel: 'Participants',
          showDetailButton: 'Show detail',
          showStatsButton: 'Show stats',
          displayedFrom: 'From',
          displayedTo: 'until',
          notDisplayedAnymore: 'Not presented anymore (until {0})',
          notPublished: 'Not published',
          publish: 'Publish',
          unpublish: 'Suspend',
          invalidOpportunityMessage: 'Invalid opportunity',
          noParticipantsMessage: 'No one has signed up or shown interest yet',
          table: {
            nameLabel: 'Name',
            fitLabel: 'Fit',
            fitRequirementsLabel: 'Required',
            fitPreferencesLabel: 'Offered',
            fitCultureLabel: 'Culture',
            activitiesLabel: 'Activities',
            respondedLabel: 'Responded',
            toggleRejected: {
              show: 'Show rejected ({0})',
              hide: 'Hide rejected ({0})',
            }
          },
          stats: {
            addWeekButton: '1 week',
            fromLabel: 'From',
            toLabel: 'To',
            cancelLabel: 'Cancel',
            props: {
              DISPLAYED: { label: 'Displayed' },
              OPENED: { label: 'Opened' },
              LIKED: { label: 'Liked' },
              INTERESTED: { label: 'Interested' },
              ACCEPTED: { label: 'Signed up' }
            }
          },
          responseStatus: {
            INTERESTED: { label: 'Interested' },
            ACCEPTED: { label: 'Signed up' },
            REJECTED: { label: 'Rejected' }
          },
          companyResponseStatus: {
            INTERESTED: { label: 'We\'re interested' },
            ACCEPTED: { label: 'We\'re interested' },
            ACCEPTED_ACCEPTED: { label: 'We\'re connected' },
            REJECTED: { label: 'We rejected' }
          },
          companyResponseButtons: {
            ACCEPT: 'Show interest',
            ACCEPT_INTERESTED: 'Confirm interest',
            ACCEPT_ACCEPTED: 'Confirm sign up',
            REJECT: 'Reject'
          },
          loadError: {
            message: 'Sorry, someone (probably me) didn\'t do their job properly and now we can\'t load opportunities. {0[Try again]} or contact support at {1}.',
            homeButton: 'Home'
          },
          noOpportunitiesMessage: 'No opportunities found',
          group1Title: 'Job offers, events, talent academies...',
          group2Title: 'E-books, videos, online courses...',
          createButtonTooltip: 'Create opportunity',
          createButtonLabel: 'New',
          tabInterested: 'Interested',
          tabActiveSearch: 'Talent Discovery',
          activeSearch: {
            searchingMessage: 'Searching...',
            noMoreTalentsMessage: 'We did not find more suitable talents',
            errorMessage: 'An error occurred while searching, please try again later',
            discoverTalentsMessage: 'Discover more suitable talents and reach out to them immediately',
            discoverButton: 'Discover',
            eliminatedIncludedLabel: 'Also show talents with failed K.O. criteria',
            refreshLabel: 'Refresh',
            emailsShownLabel: 'Show email addresses',
            emailsShownHelperText: 'Only for admins',
            discoveryUnlimitedMessage: 'You can discover unlimited number of talents',
            discoveryLimitedMessage: 'You can discover up to {0} talents'
          }
        },
        companyProfile: {
          noProfileAvailable: {
            title: 'No profile available',
            message: 'Impress potential candidates with carefully crafted company profile. Just fill out the form and we\'ll help you do the rest.',
            actionButton: 'Start here'
          },
          notPublished: 'Profile is not published',
          publishedAt: 'Published {0}',
          draftUpdated: 'Profile draft updated {0}',
          nameLabel: 'Name',
          logoLabel: 'Logo',
          websiteLabel: 'Website',
          headerImageLabel: 'Header image',
          descriptionLabel: 'Description',
          locationsLabel: 'Locations',
          industriesLabel: 'Industries',
          momentsLabel: 'Moments',
          employeeSizeLabel: 'Number of employees',
          typeLabel: 'Company type',
          benefitsLabel: 'Benefits',
          personalDevelopmentAreasLabel: 'Personal development areas',
          editButton: 'Edit',
          actions: {
            preview: {
              label: 'Preview'
            },
            show: {
              label: 'Show profile'
            },
            publishDraft: {
              label: 'Publish draft'
            },
            publish: {
              label: 'Publish',
              confirmLabel: 'Publish',
              cancelLabel: 'Cancel',
              confirmMessage: 'Profile will be visible to the public. Would you like to publish it?'
            },
            unpublish: {
              label: 'Unpublish',
              confirmLabel: 'Unpublish',
              cancelLabel: 'Cancel',
              confirmMessage: 'Profile will not be visible to the public. Would you like to unpublish it?'
            },
            discard: {
              label: 'Discard draft',
              confirmLabel: 'Discard',
              cancelLabel: 'Cancel',
              confirmMessage: 'Draft will be removed, only previously published profile will be kept. Would you like to discard the draft?'
            },
            unknown: {
              confirmLabel: 'Confirm',
              cancelLabel: 'Cancel',
              confirmMessage: 'Confirm your action?'
            }
          }
        },
        companySelect: {
          currentCompanyMessage: 'Current company set to {0}',
          noCurrentCompanyMessage: 'No current company set',
          noCurrentCompanyNote: 'Select current account to continue',
          setButton: 'Set',
          unsetButton: 'Unset',
          changeButton: 'Change',
          menuUnsetButton: 'Unset current company',
          menuSearchCompany: 'Search company'
        },
        sourcingProfileBuilder: {
          title: 'Attract and engage new talents in 2 simple steps',
          message: 'You are only 15 minutes away from reaching more than 3 500 talents from the whole Czech Republic',
          profile: {
            title: 'Your company profile, which can be visited by candidates interested in your opportunities',
            description: 'Connect your brand with a community of talents, introduce them to your company culture and share your content with them',
            previewButton: 'Preview',
            NONE: {
              message: 'Create your company profile',
              button: 'Create'
            },
            PENDING: {
              message: 'You\'ve created your company profile. Now you just need to publish it.',
              button: 'Publish'
            },
            DONE: {
              message: 'Your company profile is ready',
              button: 'Edit'
            }
          },
          opportunities: {
            title: 'Your job positions, internships, or talent programs',
            NONE: {
              message: 'Create one or more opportunities',
              description: 'Introduce talents to your open job positions or talent programs you\'re offering',
              button: 'Create'
            },
            PENDING: {
              message: 'You\'ve successfully created the first opportunity. Publish it and start delivering your brand to the talents.',
              button: 'Publish'
            },
            DONE: {
              message: 'You already have 1 or more opportunities published',
              button: 'Create more'
            }
          }
        },
        newBadge: {
          label: 'New'
        }
      }
    },
    notifications: {
      default: {
        source: 'Notifications',
        buttons: {
          dismiss: 'Dismiss',
          hide: 'Hide'
        }
      },
      freeAccountBlocked: {
        title: 'Can we help you?',
        message: 'Registration was successful, we will verify your account within 24 hours. Until then you can try our DEMO'
      }
    },
    errors: {
      apiDown: 'We are currently improving {0} Dashboard and we will be back soon. If you have any questions use the chat down below or reach out to us at {1}.'
    },
    creditLimits: {
      creditsUsed: 'Used credits',
      creditsFree: 'Available credits',
      creditsLimit: 'Credit limit',
      unlimited: 'Unlimited number of credits',
      creditsInfo: '1 profile = 5 credits',
      upgrade: {
        unlock: '{0[Unlock]} locked profiles',
        raise: '{0[Upgrade]} credit limits'
      },
      expiration: {
        unset: 'Without expiration',
        set: 'Credits expire on {0}'
      }
    },
    discreteMetricChart: {
      anonymousParticipants: '{0} anonymous participant(s)',
      anonymous: 'Anonymous'
    },
    dualMetric: {
      interpretation: {
        teamTitle: 'Team',
        challengeTitle: 'Challenge',
        opportunityTitle: 'Opportunity',
        resolutionTitle: 'Recommendation'
      }
    },
    analytics: {
      metricsBubbleChart: {
        meanLabel: 'Mean',
        medianLabel: 'Median'
      },
      dualMetricsMassChart: {
        noDataMessage: 'No data available',
        sortedLabel: 'Sorted',
        legend: ['Not focused', 'Less focused', 'Rather focused', 'Strongly focused'],
        zones: ['Less oriented', 'Moderately oriented', 'Strongly oriented'],
        description: 'The chart describes motivational and work-cultural factors and the degree of their importance for individuals in a team. The more significantly the number of individuals focuses on specific areas, the stronger cultural inclination towards the area the team has as a whole.'
      }
    },
    priceList: {
      creditAmount: 'Number of credits',
      creditPrice: 'Price per credit',
      creditPriceDiscount: 'Your discount',
      numberOfTODProfiles: 'Number of profiles',
      expiry: 'Expiry',
      totalPrice: 'Total price',
      discountedPrice: 'Your discounted price',
      topUpButton: 'Choose',
      monthFormat: {
        '1': '1 month',
        '>1': '{0} months'
      },
      yearFormat: {
        '1': '1 year',
        '>1': '{0} years'
      }
    },
    datePicker: {
      okLabel: 'OK',
      cancelLabel: 'Cancel'
    },
    participant: {
      personal: {
        seekerStatusTitle: 'Status',
        salaryFreeLabel: 'FREE',
        groupTitles: {
          BIO: { title: 'Bio' },
          EDU: { title: 'Education' },
          WORK_EXPERIENCE: { title: 'Work Experience' },
          LANGUAGES: { title: 'Languages' },
          SKILLS: { title: 'Other Skills' },
          ROLES: { title: 'Roles' },
          SPECIALIZATIONS: { title: 'Specializations' },
          INDUSTRIES: { title: 'Industries' },
          PDAS: { title: 'Personal Development Areas' },
          LOCATIONS: { title: 'Locations' },
          OPPORTUNITY_TYPES: { title: 'Opportunity Types' },
          SALARY: { title: 'Salary' },
          BENEFITS: { title: 'Benefits' },
          LOVE_BRANDS: { title: 'Love Brands' }
        }
      }
    },
    copyToClipboardInput: {
      label: 'Value',
      helperText: '{0[Copy to clipboard]} or select the content and press Ctrl + C',
      helperTextCopied: 'Successfully copied to clipboard'
    },
    teams: {
      teamSelect: {
        searchPlaceholder: 'Search teams, groups...'
      },
      memberSelect: {
        invitationLinkMessage: 'Alternatively you may send out following link:',
        invitationLinkLabel: 'Invitation link',
        searchPlaceholder: 'Search or enter email address',
        autoInviteCheckboxLabel: 'Automatically invite to configured activities',
        emptyMessage: 'Look for profiles within the platform or invite people by email',
        buttonCancel: 'Cancel',
        buttonConfirm: 'Add',
        buttonConfirmSingle: 'Set',
        clickToAddLabel: 'Click to add',
        closeMenuLabel: 'Close',
        emailInvitationDisabled: {
          searchPlaceholder: 'Search',
          emptyMessage: 'Look for profiles within the platform',
        }
      },
      memberRow: {
        remindLabel: 'Remind',
        remindMaxNotificationsReached: 'Maximum number of notifications reached',
        remindDisabled: 'Reminder disabled until {0}',
        inviteLabel: 'Invite',
        highlightTooltip: 'Highlight',
        removeTooltip: 'Remove',
        detailLabel: 'Detail',
        profileLabel: 'Profile',
        activityStats: {
          totalScoreLabel: 'Total score',
          fitLabel: 'Fit'
        }
      },
      dialogs: {
        activityInvite: {
          inviteLabel: 'Invite',
          cancelLabel: 'Cancel',
          message: 'Invite {0} to following activities:',
          messageSingle: 'Invite {0} to {1} activity?'
        },
        activityRemind: {
          remindLabel: 'Remind',
          cancelLabel: 'Cancel',
          message: 'Remind {0} following activities:',
          messageSingle: 'Remind {0} activity {1}?'
        },
        memberRemove: {
          cancelLabel: 'Cancel',
          removeLabel: 'Remove',
          message: 'Remove {0} from this team?'
        }
      },
      activityAnalytics: {
        teasers: {
          addManagerMessage: '{0[Add]} team manager, platform will assess them and show their profile',
          addMembersMessage: '{0[Add]} team members, platform will assess them and show their team profile',
          addMembersMessages: {
            TEAM: '{0[Add]} team members, platform will assess them and show their team profile',
            GROUP: '{0[Add]} group members, platform will assess them and show their group profile',
            HIRING: '{0[Add]} candidates, platform will assess them and show their profile'
          },
          inviteManagerMessage: '{0} not yet invited into activity',
          inviteLabel: 'Invite',
          remindManagerMessage: '{0} unfinished',
          remindLabel: 'Remind'
        }
      },
      subGroup: {
        config: {
          message: 'Select a combination of assessments and language options',
          assessmentsLabel: 'Assessments',
          languagesLabel: 'Languages',
          primaryLanguageLabel: 'Primary language',
          otherLanguagesLabel: 'Other supported languages',
          cancelLabel: 'Cancel',
          goToProjectLabel: 'Project settings'
        },
        saveLabel: 'Save',
        changeMemberLabel: 'Change',
        setMemberLabel: 'Set',
        addMemberLabel: 'Add',
        listTooltip: 'List',
        chartTooltip: 'Chart',
        interpretationsTooltip: 'Interpretations',
        configureTooltip: 'Settings',
        configDoneLabel: 'Done',
        memberCountMessage: '{0} of {1} members',
        memberCountHelperText: 'You can change the number of displayed members',
        emptyMemberListMessage: '{0[Add or invite]} team members',
        emptyManagerListMessage: '{0[Add or invite]} team manager',
        emptyMemberListMessages: {
          TEAM: '{0[Add or invite]} team members',
          GROUP: '{0[Add or invite]} group members',
          HIRING: '{0[Add or invite]} candidates',
        },
        suggestedLabel: 'Suggested',
        titles: {
          MANAGER: {
            title: 'Manager'
          },
          MEMBER: {
            title: 'Team',
            variants: {
              TEAM: 'Team',
              GROUP: 'Group',
              HIRING: 'Candidates'
            }
          }
        }
      },
      name: {
        placeholder: 'Name your team...',
        placeholderVariants: {
          TEAM: 'Name your team...',
          GROUP: 'Name your group...',
          HIRING: 'Name your open position...'
        },
        helperText: 'Click to rename',
        focusedHelperText: 'Click away to save'
      },
      team: {
        switchLabel: 'Switch',
        newLabel: 'New',
        settingsLabel: 'Settings',
        shareLabel: 'Share',
        deleteLabel: 'Delete',
        generateMembersLabel: 'Generate members',
        changeTypeLabel: 'Change group type',
        linkHiringLabel: 'Link open position',
        typeSelect: {
          header: 'Select the group type',
          message: '...and gain unique insights into the competencies, skills, attitudes and values of {0}',
          dynamicMessageTexts: ['people in your teams', 'any individuals', 'your job candidates']
        },
        tabs: {
          overview: 'Overview',
          insights: 'Insights',
          list: 'People',
          analytics: 'Analytics',
          wellBeing: 'Well-being'
        },
        searchTooltip: 'Filter',
        reloadTooltip: 'Refresh'
      }
    },
    dualMetricsInterpretation: {
      interpretationLabel: 'Interpretation'
    }
  },
  containers: {
    common: {
      notFound: {
        message: 'Please check whether the link is valid. If you think there is something missing, reach out to our support at {0}.',
        button: 'Back to overview'
      }
    }
  },
  project: {
    active: {
      card: 'Active'
    },
    scheduled: {
      card: 'Planned'
    },
    finished: {
      card: 'Ended'
    },
    concept: {
      card: 'Draft'
    },
    edit: {
      basic: 'General settings',
      texts: 'Texts',
      notifications: 'Notifications'
    }
  },
  filter: {
    all: 'Overall score',
    graph: 'Participants chart',
    competency: 'Competency',
    order: 'Order by overall score',
    score: 'Score',
    weights: {
      title: 'Competencies settings',
      subtitle: 'Controls the settings of individual competencies',
      weightsInfo: 'They influence the way the overall score is calculated and may impact the order of the participants',
      totalScoreLabel: 'Enable overall score',
      totalScoreHelper: 'The weights are active only if the overall score is enabled',
      weightsTitle: 'Competencies weights',
      coreCompetenciesTitle: 'Core competencies',
      buttons: {
        apply: 'Apply',
        reset: 'Reset',
        resetHelper: 'Reset to last saved setting',
        level: 'Level'
      }
    },
    chart: {
      title: 'Participant chart',
      subtitle: 'Shows all participants in a bar chart'
    }
  },
  forms: {
    partContainer: {
      buttons: {
        back: 'Back',
        next: 'Next',
        cancel: 'Cancel',
        create: 'Create',
        createAndPublish: 'Create and publish',
        save: 'Save',
        saveAndPublish: 'Save and publish'
      },
      savedMessage: 'Saved'
    }
  },
  wizard: {
    step1: 'Project type',
    step2: 'General settings',
    step3: 'Customization',
    step4: 'Notifications'
  },
  matching: {
    range: {
      and: 'and',
      upper: { label: 'at most' },
      lower: { label: 'at least' }
    },
    requirements: {
      title: 'Required',
      education: {
        title: 'Education',
        level: {
          title: 'Level'
        },
        fields: {
          title: 'Fields of Study'
        },
        currentlyStudying: {
          title: 'Appropriate for Students'
        }
      },
      workExperience: {
        title: 'Work Experience',
        years: {
          title: 'Years'
        },
        previousRole: {
          title: 'Experience with Role'
        }
      },
      skills: {
        title: 'Skills',
        languages: {
          title: 'Languages'
        },
        other: {
          title: 'Other'
        }
      }
    },
    preferences: {
      title: 'Offered',
      classification: {
        title: 'Classification',
        types: {
          title: 'Opportunity Type'
        },
        roles: {
          title: 'Roles'
        },
        specializations: {
          title: 'Specializations'
        },
        industries: {
          title: 'Industries'
        },
        locations: {
          title: 'Locations'
        }
      },
      benefitsAndGrowth: {
        title: 'Benefits & Growth',
        salary: {
          title: 'Salary',
          offerLabel: 'Offered'
        },
        benefits: {
          title: 'Benefits',
          unmatchedTitle: 'They also prefer'
        },
        personalDevelopmentAreas: {
          title: 'Personal Development Areas',
          unmatchedTitle: 'They also prefer these personal development areas'
        }
      }
    }
  },
  constants: {
    internalText: {
      officeDay: {
        text: 'I know that you are our new {0} looking forward to start with {1}, but today I would need you to help us manage this extra workload instead.',
        descriptionFallback: 'I know that you are our new {0} looking forward to start right off with your agendas, but today I would need you to help us manage this extra workload instead.'
      }
    },
    positionTypes: {
      INTERNSHIP: {
        label: 'Internship or academy',
        description: 'Select this option if you are selecting interns or developing your organization\'s young talents'
      },
      ADMINISTRATIVE: {
        label: 'Assistant / Administrative',
        description: 'Suitable for hiring a personal assistant, department representative, secretary, or positions whose main job is the administration'
      },
      SALES: {
        label: 'Sales',
        description: 'Suitable for hiring a salesperson, a purchaser, an account manager, a customer support worker, or such positions whose primary focus is business activity, its support, or related agenda'
      },
      MARKETING: {
        label: 'Marketing',
        description: 'Suitable for hiring for a marketing position with either online or offline specialisation, with creative or administrative agendas'
      },
      OTHER: {
        label: 'Specific / other',
        description: 'Suitable for hiring for a position other than the ones listed above'
      }
    },
    enums: {
      EActivityStatus: {
        REGISTERED: {
          name: 'Registered'
        },
        IN_PROGRESS: {
          name: 'In progress'
        },
        FINISHED: {
          name: 'Finished'
        }
      },
      EApplicationStatus: {
        NONE: {
          name: 'None',
          setStatusLabel: 'Unset status'
        },
        REJECTED: {
          name: 'Rejected',
          setStatusLabel: 'Mark as "Rejected"'
        },
        ACCEPTED: {
          name: 'Accepted',
          setStatusLabel: 'Mark as "Accepted"'
        },
        FUTURE_FIT: {
          name: 'Future fit',
          setStatusLabel: 'Mark as "Future fit"'
        },
        IN_PROGRESS: {
          name: 'In progress',
          setStatusLabel: 'Mark as "In progres"'
        },
        WITHDRAWN: {
          name: 'Withdrawn',
          setStatusLabel: 'Mark as "Withdrawn"'
        },
        OFFER_REJECTED: {
          name: 'Offer rejected',
          setStatusLabel: 'Mark as "Offer rejected"'
        }
      },
      EEducation: {
        VOCATIONAL: {
          label: 'Vocational school',
          shortLabel: 'Vocational'
        },
        SECONDARY: {
          label: 'Secondary level with leaving exam (A-levels, International Baccalaureate)',
          shortLabel: 'Leaving exam'
        },
        POST_SECONDARY: {
          label: 'Post-Secondary Certificate / Diploma (HND, PG Cert, PG Dip, etc.)',
          shortLabel: 'Post-secondary Certificate'
        },
        UNDERGRADUATE: {
          label: 'Undergraduate level degree (Bachelors)',
          shortLabel: 'Bachelors'
        },
        GRADUATE: {
          label: 'Graduate level degree (Masters)',
          shortLabel: 'Masters'
        },
        POST_GRADUATE: {
          label: 'Postgraduate level degree (PhD)',
          shortLabel: 'PhD'
        },
        PROFESSIONAL: {
          label: 'Professional non-academic degree (MBA, LL.M)',
          shortLabel: 'MBA, LL.M'
        }
      },
      EWorkExperienceYears: {
        ZERO: {
          label: 'Without work experience',
          shortLabel: '0'
        },
        ONE: {
          label: 'Less than a year',
          shortLabel: '<1 y.'
        },
        THREE: {
          label: 'Between 1 and 3 years',
          shortLabel: '1–3 y.'
        },
        TEN: {
          label: 'Between 4 and 10 years',
          shortLabel: '4–10 y.'
        },
        MORE: {
          label: 'More than 10 years',
          shortLabel: '>10 y.'
        }
      },
      ESeekerStatus: {
        ACTIVE: {
          label: 'Active'
        },
        OPEN: {
          label: 'Open'
        },
        TENTATIVE: {
          label: 'Tentative'
        },
        INACTIVE: {
          label: 'Inactive'
        }
      },
      EWorkExperiencePosition: {
        NO_SPECIALIZATION: 'Without specialization',
        ADMINISTRATIVE: 'Administrative position',
        SALES: 'Sales position',
        EXPERT: 'Expert position',
        ENTREPRENEURIAL: 'Entrepreneurial',
        LINE_MANAGEMENT: 'Line management',
        MIDDLE_MANAGEMENT: 'Middle management',
        TOP_MANAGEMENT: 'Top management'
      },
      EFieldOfStudyCategory: {
        ARTS: 'Arts',
        HUMANITIES: 'Humanities',
        SOCIAL_SCIENCES: 'Social sciences',
        NATURAL_SCIENCES: 'Natural sciences',
        APPLIED_SCIENCES: 'Applied sciences'
      },
      EFieldOfStudy: {
        GENERAL: 'General Education',
        SERVICES: 'Services',
        BUSINESS_TRADE: 'Business & Trade',
        TECHNICAL: 'Technical',
        OTHER: 'Other',
        PERFORMING_ARTS: 'Performing arts',
        VISUAL_ARTS: 'Visual arts',
        GEOGRAPHY: 'Geography',
        HISTORY: 'History',
        LANGUAGES: 'Languages and literature',
        EDUCATION: 'Education',
        PHILOSOPHY: 'Philosophy',
        THEOLOGY: 'Theology',
        ECONOMICS: 'Economics / Business',
        LAW: 'Law',
        POLITICAL_SCIENCE: 'Political science',
        PSYCHOLOGY: 'Psychology',
        SOCIOLOGY: 'Sociology',
        BIOLOGY: 'Biology',
        CHEMISTRY: 'Chemistry',
        MATHEMATICS: 'Mathematics',
        PHYSICS: 'Physics',
        AGRICULTURE: 'Agriculture and agricultural studies',
        COMPUTER_SCIENCE: 'Computer science',
        ENGINEERING: 'Engineering and technology',
        MEDICINE: 'Medicine and health sciences'
      },
      EParticipantReport: {
        NONE: {
          label: 'None',
          description: 'Participant won\'t get the report'
        },
        STANDARD: {
          label: 'Standard',
          description: 'Participant will get basic interpretations'
        },
        ENHANCED: {
          label: 'Enhanced',
          description: 'Participant will get interpretations with recommendations and competency chart'
        },
        FULL: {
          label: 'Full',
          description: 'Participant will get interpretations with recommendations and full competency chart'
        }
      },
      EParticipantReportSimple: {
        NO: {
          label: 'No',
          description: 'Participant won\'t get the report'
        },
        YES: {
          label: 'Yes',
          description: 'Participant will get the report with interpretations and comptency chart'
        }
      },
      EPoolEndpoint: {
        NONE: {
          label: 'No',
          description: 'Project will serve as neither talent pool source nor destination'
        },
        SOURCE: {
          label: 'Source',
          description: 'Project will provide participant profiles to talent pool'
        },
        DESTINATION: {
          label: 'Destination',
          description: 'Project will be the destination for the talent pool profiles'
        }
      },
      EActivityEventType: {
        IMMEDIATE: {
          label: 'Immediately',
          description: 'On registration for the current project'
        },
        COMPLETED: {
          label: 'On completion',
          description: 'On completion of the current project\'s activity'
        }
      },
      EActivityTypeVariant: {
        CULTURE_FIT: {
          CANDIDATE: { label: 'Preferential' },
          MANAGER: { label: 'Perceptive - Manager' },
          EMPLOYEE: { label: 'Perceptive - Employee' }
        }
      },
      EBool: {
        YES: {
          label: 'Yes'
        },
        NO: {
          label: 'No'
        }
      },
      EOpportunityType: {
        JOB: {
          label: 'Job position'
        },
        EVENT: {
          label: 'Event'
        },
        EBOOK: {
          label: 'E-book'
        },
        VIDEO: {
          label: 'Video'
        },
        COACH: {
          label: 'Coach'
        },
        ONLINE_COURSE: {
          label: 'Online Course'
        },
        TALENT_ACADEMY: {
          label: 'Talent academy'
        },
        BOOK: {
          label: 'Book'
        },
        ARTICLE: {
          label: 'Article'
        }
      },
      ELanguageLevel: {
        CONVERSANT: {
          label: 'Conversant'
        },
        PROFICIENT: {
          label: 'Proficient'
        },
        FLUENT: {
          label: 'Fluent'
        },
        NATIVE: {
          label: 'Native speaker'
        }
      },
      ERank: {
        LOW: { label: 'Low level' },
        MID_LOW: { label: 'Low level' },
        MID: { label: 'Medium level' },
        MID_HIGH: { label: 'Medium level' },
        HIGH: { label: 'High level' },
        NEUTRAL: { label: 'High level' },
        LOCKED: { label: '?' }
      },
      ECompanyRejectReason: {
        NONE: { label: 'Without giving a reason', description: "When you don't want to give a reason" },
        FILLED: { label: 'Already filled', description: "If you've already filled the opportunity" },
        OVERFLOW: { label: 'Too many candidates', description: 'If you already have enough candidates' },
        BAD_FIT: { label: 'Bad fit', description: 'If the candidate does not meet your requirements' },
        CUSTOM: { label: 'Custom', description: 'Enter your own reason' }
      },
      EProjectType: {
        POSITION: {
          label: 'Open position'
        },
        GROUP_ASSESSMENT: {
          label: 'Group assessment'
        },
        TALENT_PROGRAM: {
          label: 'Talent program'
        },
        VIEW: {
          label: 'Grouping'
        },
        QUESTIONNAIRE: {
          label: 'Questionnaire'
        }
      },
      EProjectState: {
        ACTIVE: {
          label: 'Active'
        },
        SCHEDULED: {
          label: 'Planned'
        },
        FINISHED: {
          label: 'Ended'
        },
        CONCEPT: {
          label: 'Drafts'
        }
      },
      EProjectFilterSort: {
        NAME: {
          label: 'Name'
        },
        MODIFIED_AT: {
          label: 'Last modified'
        },
        LATEST_PROFILE_ADDED_AT: {
          label: 'Latest profile added'
        }
      },
      ECompanyType: {
        STARTUP: { label: 'Startup' },
        ENTERPRISE: { label: 'Large Enterprise' },
        FAMILY_OFFICE: { label: 'Family Office' },
        GLOBAL_ENTERPRISE: { label: 'Global Enterprise' },
        SME: { label: 'SME' },
        MICRO_COMPANY: { label: 'Micro Company' },
        NNO: { label: 'NNO' },
        GOVERNMENT: { label: 'Governmental Organization' },
        NGO: { label: 'NGO' },
        TEAM: { label: 'Team' },
        ASSOCIATION: { label: 'Association' }
      },
      EOpportunityParticipantStatus: {
        UNPROCESSED: {
          label: 'Waiting'
        },
        WAITING: {
          label: 'Wait for response'
        },
        CONNECTED: {
          label: 'Connected'
        },
        REJECTED: {
          label: 'Rejected'
        }
      },
      EPersonalDetailsExtent: {
        NONE: {
          label: 'None',
          description: 'No personal details - can cause problems with simulations'
        },
        MINIMAL: {
          label: 'Minimal',
          description: 'First and last name only'
        },
        LIGHT: {
          label: 'Light',
          description: 'Basic bio only'
        },
        FULL: {
          label: 'Full',
          description: 'Basic bio, education, work experience and skills'
        }
      },
      EGroupType: {
        TEAM: {
          label: 'Team'
        },
        GROUP: {
          label: 'Group'
        },
        HIRING: {
          label: 'Open position'
        }
      }
    },
    charts: {
      labels: {
        dual: {
          DISCRETE_FOUR: mirrorReversed(['Moderately', 'Significantly'])
        }
      }
    }
  },
  smartsupp: {
    offline: {
      title: 'Send us a message',
      notice: 'We are here to help you. Send us a message and we will get back to you.',
      hideTitle: 'Hide chat',
      required: '',
      name: 'Name',
      email: 'E-mail',
      number: 'Phone',
      message: 'Your message',
      submit: 'Send message',
      messageRequiredAlert: 'You have not entered a message',
      emailRequiredAlert: 'You have not entered an email address',
      emailInvalidAlert: 'Invalid email address',
      sendInfo: 'Sending email...',
      successTitle: 'Thank you for your message',
      successDesc: 'Your message was sent successfully. We will contact you soon.',
      failureTitle: 'Error',
      failureDesc: "We are sorry but your message wasn't sent"
    },
    widget: {
      offline: 'Send us a message'
    }
  }
};
