import * as Api from '../../api/entities/opportunityApi';
import { createApiCallAction } from '../apiCallAction';

import {
  OPPORTUNITY_POST_GET,
  OPPORTUNITY_POST_CREATE,
  OPPORTUNITY_POST_UPDATE,
  OPPORTUNITY_POST_CLEAR,
  OPPORTUNITY_POST_DELETE
} from '../../constants/constants';

export const clearOpportunityPost = () => ({ type: OPPORTUNITY_POST_CLEAR });

const handleGetOpportunityPost = createApiCallAction(OPPORTUNITY_POST_GET, Api.getOpportunityPost);
export const getOpportunityPost = id => handleGetOpportunityPost(id);

const handleCreateOpportunityPost = createApiCallAction(OPPORTUNITY_POST_CREATE, Api.createOpportunityPost);
export const createOpportunityPost = data => handleCreateOpportunityPost(data);

const handleUpdateOpportunityPost = createApiCallAction(OPPORTUNITY_POST_UPDATE, Api.updateOpportunityPost);
export const updateOpportunityPost = (id, data) => handleUpdateOpportunityPost(id, data);

const handleDeleteOpportunityPost = createApiCallAction(OPPORTUNITY_POST_DELETE, Api.deleteOpportunityPost);
export const deleteOpportunityPost = id => handleDeleteOpportunityPost(id);
