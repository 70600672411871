import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import AccountNewPage from '../../components/pages/public/AccountNew';

import { b64ToObject } from '../../utils/utils';
import { updateLocale } from '../../actions/options/localeActions';
import { createNewAccount } from '../../actions/entities/accountActions';
import * as talentMarketPlanActions from '../../actions/entities/talentMarketPlanActions';
import { EContractPlanType } from '../../constants/enum';

class AccountNew extends Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    updateLocale: PropTypes.func.isRequired,
    createNewAccount: PropTypes.func.isRequired,
    getTalentMarketPlans: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      selectingPlan: !props.talentMarketPlans.selectedId
    };
  }

  componentDidMount() {
    this.loadPlans();
  }

  componentDidUpdate(prevProps) {
    const { intl } = this.props;
    if (prevProps.intl !== intl) {
      this.loadPlans();
    }
  }

  loadPlans = () => {
    const {
      intl: { locale },
      route: { planType },
      getTalentMarketPlans
    } = this.props;
    if (planType === EContractPlanType.TALENT_MARKET.key) {
      getTalentMarketPlans(locale);
    }
  };

  createNewAccount = data => {
    const {
      intl: { locale },
      location: { pathname },
      route: { planType },
      talentMarketPlans: { selectedId, billingPeriod, currency, defaultCurrency }
    } = this.props;
    const account = {
      ...data,
      language: locale
    };
    if (planType === EContractPlanType.TALENT_MARKET.key) {
      account.talentMarketPlan = {
        planId: selectedId,
        billingPeriod,
        currency: currency || defaultCurrency
      };
    }
    return this.props.createNewAccount(account, pathname);
  };

  handlePlanSelect = p => {
    const { selectTalentMarketPlan } = this.props;
    selectTalentMarketPlan(p.id);
    this.setState({ selectingPlan: false });
  };
  handlePlanSelectStart = () => {
    this.setState({ selectingPlan: true });
  };
  handleCurrencySelect = c => {
    const { selectTalentMarketPlanCurrency } = this.props;
    selectTalentMarketPlanCurrency(c);
  };
  handleBillingPeriodSelect = b => {
    const { selectTalentMarketPlanBillingPeriod } = this.props;
    selectTalentMarketPlanBillingPeriod(b);
  };

  render() {
    const {
      intl,
      fields,
      route: { planType },
      talentMarketPlans: { items, map, selectedId, currency, defaultCurrency, currencies, billingPeriod, loading }
    } = this.props;
    const { selectingPlan } = this.state;
    const { updateLocale } = this.props;
    let pageProps = {
      intl,
      fields,
      updateLocale,
      createNewAccount: this.createNewAccount
    };
    if (planType) {
      pageProps = {
        ...pageProps,
        loading,
        plans: items,
        plan: selectedId ? map[selectedId] : null,
        selectingPlan,
        currencies,
        currency,
        defaultCurrency,
        billingPeriod,
        onPlanSelect: this.handlePlanSelect,
        onPlanSelectStart: this.handlePlanSelectStart,
        onCurrencySelect: this.handleCurrencySelect,
        onBillingPeriodSelect: this.handleBillingPeriodSelect
      };
    }
    return <AccountNewPage {...pageProps} />;
  }
}

const mapStateToProps = (
  { intl, entities: { talentMarketPlans } },
  {
    location: {
      query: { f }
    }
  }
) => ({ intl, talentMarketPlans, fields: b64ToObject(f) });

const actions = {
  updateLocale,
  createNewAccount,
  ...talentMarketPlanActions
};

export default connect(mapStateToProps, actions)(withRouter(AccountNew));
