import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router';
import map from 'lodash/map';
import take from 'lodash/take';
import _filter from 'lodash/filter';
import chunk from 'lodash/chunk';
import VisSensor from 'react-visibility-sensor';
import Sticky from 'react-stickynode';

import SourcingProfileBuilder from './SourcingProfileBuilder';
import BaseContainer from '../../../components/basic/BaseContainer';
import Spinner from '../../../components/spinner/Spinner';
import Card from '../../../components/mui/Card';
import CardActions from '../../../components/mui/CardActions';
import Button from '../../../components/mui/Button';
import SearchField from '../../../components/mui/DebouncedSearchField';
import ErrorPage from '../../../components/error/ErrorPage';

import OpportunityHeader from './OpportunityHeader';

import * as opportunityActions from '../../../actions/entities/opportunityActions';
import * as companyActions from '../../../actions/entities/companyActions';
import * as modalActions from '../../../actions/options/modalActions';

import {
  BRAND,
  MODAL_OPPORTUNITY_POST_PUBLICATION,
  MODAL_OPPORTUNITY_POST_DELETE,
  HEADER_SELECTOR
} from '../../../constants/constants';
import { formatMessage } from '../../../utils/utils';

import { selectOpportunitiesSplitByRegistrable } from '../../../selectors/opportunities';
import { selectAccountPermissions } from '../../../selectors/auth';
import Tooltip from '../../../components/basic/Tooltip';

const STATS_ITEMS = [
  { prop: 'displayedCount', key: 'DISPLAYED' },
  { prop: 'openedCount', key: 'OPENED' },
  { prop: 'likedCount', key: 'LIKED' },
  { prop: 'acceptedCount', key: 'ACCEPTED', registrableOnly: true },
  { prop: 'interestedCount', key: 'INTERESTED', registrableOnly: true }
];

const renderStatsItem = (item, stats, messages) => {
  const value = stats[item.prop];
  const msg = messages.stats.props[item.key];
  return (
    <div key={item.key} className={`container-flex${value > 0 ? '' : ' text-muted'}`}>
      <div style={{ width: '72px', textAlign: 'right', marginRight: '8px' }}>{(msg || {}).label}</div>
      <div className="flex1">{<span>{value}</span>}</div>
    </div>
  );
};

const OpportunityItem = props => {
  const { opportunity, talentMarketPlan, intl, handleTogglePublished, onDelete } = props;
  const { stats, typeProps } = opportunity;
  const messages = intl.messages.components.pages.private.opportunities;
  return (
    <Card style={{ marginTop: '24px' }}>
      <OpportunityHeader
        talentMarketPlan={talentMarketPlan}
        intl={intl}
        opportunity={opportunity}
        onTogglePublished={handleTogglePublished}
        onDelete={onDelete}
      />
      <div style={{ fontSize: '13px', padding: '16px 8px' }}>
        <div className="mui-label" style={{ padding: '0 8px' }}>
          {messages.statsLabel}
        </div>
        <div className="row">
          {map(chunk(STATS_ITEMS, Math.ceil(STATS_ITEMS.length / 2)), (c, idx) => (
            <div key={idx} className="col-xs-6">
              {map(
                _filter(c, i => !i.registrableOnly || typeProps.registrable),
                i => renderStatsItem(i, stats, messages)
              )}
            </div>
          ))}
        </div>
      </div>
      <CardActions className="with-background" style={{ padding: '8px', textAlign: 'center' }}>
        <Button
          primary
          containerElement={<Link to={`/sourcing/opportunities/${opportunity.id}`} />}
          label={typeProps.registrable ? messages.showDetailButton : messages.showStatsButton}
          fullWidth
          style={{ height: '56px', lineHeight: '56px' }}
        />
      </CardActions>
    </Card>
  );
};

const determineChunks = ui => {
  if (ui.width < 768) {
    return 1;
  }
  if (ui.width < 992) {
    return 2;
  }
  return 3;
};

const renderGroup = (items, chunkCount, intl, talentMarketPlan, title, handleTogglePublished, handleDelete) => {
  if (items.length === 0) {
    return null;
  }
  return (
    <div style={{ marginBottom: '32px' }}>
      {title && (
        <div className="row">
          <div className="col-xs-12">
            <label
              style={{
                margin: '16px 16px 0',
                fontSize: '12px',
                color: 'rgba(33,33,33,0.54)',
                textTransform: 'uppercase',
                letterSpacing: '0.5px',
                fontWeight: 400
              }}
            >
              {title}
            </label>
          </div>
        </div>
      )}
      {map(chunk(items, chunkCount), (row, idx) => (
        <div key={idx} className="row">
          {map(row, o => (
            <div className="col-xs-12 col-sm-6 col-md-4" key={o.id}>
              <OpportunityItem
                opportunity={o}
                talentMarketPlan={talentMarketPlan}
                intl={intl}
                handleTogglePublished={() => handleTogglePublished(o)}
                onDelete={() => handleDelete(o)}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const LOAD_COUNT = 3;
const NEW_OPPORTUNITY_URL = '/sourcing/opportunities/posts/new';

class Opportunities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadedCount: 0
    };
  }

  componentDidMount() {
    this.handleLoad();
    this.loadProfile(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentCompany !== this.props.currentCompany) {
      this.loadProfile(this.props);
    }
  }

  loadProfile = props => {
    const { getCompanyProfile, unsetCompanyProfile, currentCompany } = props;
    if (currentCompany) {
      getCompanyProfile(currentCompany.id);
    } else {
      unsetCompanyProfile();
    }
  };

  handleLoad = () => {
    this.props.getOpportunities();
  };

  handleLoadMore = () => this.setState(({ loadedCount }) => ({ loadedCount: loadedCount + LOAD_COUNT }));

  handleChangeFullText = value => {
    const { setOpportunitiesFilterFullText } = this.props;
    this.setState({ loadedCount: 0 });
    setOpportunitiesFilterFullText(value);
  };
  handleAddOpportunity = () => this.props.router.push(NEW_OPPORTUNITY_URL);
  handleSetProfile = () => {
    const { currentCompany, router } = this.props;
    const companyId = currentCompany ? currentCompany.id : null;
    router.push(companyId ? `/sourcing/profile/${companyId}/edit` : '/sourcing/profile');
  };
  handleTogglePublished = o => {
    const { switchModal } = this.props;
    const posts = o.posts;
    const post = posts && posts[0];
    if (post) {
      switchModal({ id: MODAL_OPPORTUNITY_POST_PUBLICATION, open: true, postId: post.id, reloadAll: true });
    }
  };
  handleDelete = o => {
    const { switchModal } = this.props;
    const posts = o.posts;
    const post = posts && posts[0];
    if (post) {
      switchModal({ id: MODAL_OPPORTUNITY_POST_DELETE, open: true, postId: post.id, reloadAll: true });
    }
  };
  handlePublishFirstOpportunity = () => {
    const {
      opportunities: { firstUnpublishedOpportunity }
    } = this.props;
    if (firstUnpublishedOpportunity) {
      this.handleTogglePublished(firstUnpublishedOpportunity);
    }
  };

  render() {
    const {
      opportunities: { items, loading, error, filter, totalCount, publishedCount },
      intl,
      ui,
      companyProfile,
      currentCompany,
      currentTalentMarketPlan
    } = this.props;
    if (loading) {
      return <Spinner show />;
    }
    const messages = intl.messages.components.pages.private.opportunities;
    if (error) {
      const supportEmail = BRAND.email('support');
      return (
        <ErrorPage
          message={
            <span>
              {formatMessage(messages.loadError.message, [
                val => (
                  <a role="button" onClick={this.handleLoad}>
                    {val}
                  </a>
                ),
                <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
              ])}
            </span>
          }
          statusCode="500"
          icon="sentiment_dissatisfied"
          action={
            <Button
              label={messages.loadError.homeButton}
              containerElement={<Link to="/projects" />}
              primary
              icon={<i className="material-icons">home</i>}
            />
          }
        />
      );
    }

    const profileBuilderProps = {
      intl,
      opportunityCount: totalCount,
      publishedOpportunityCount: publishedCount,
      company: currentCompany,
      talentMarketPlan: currentTalentMarketPlan,
      companyProfile: companyProfile.profile,
      //
      onProfileSet: this.handleSetProfile,
      onOpportunityAdd: this.handleAddOpportunity,
      onOpportunityPublish: this.handlePublishFirstOpportunity
    };

    const { loadedCount } = this.state;
    // const canLoadMore = split.registrable.length + split.rest.length > loadedCount;
    const canLoadMore = items.length > loadedCount;
    // const registrable =
    //   loadedCount >= split.registrable.length ? split.registrable : take(split.registrable, loadedCount);
    // const rest = loadedCount > split.registrable.length ? take(split.rest, loadedCount - split.registrable.length) : [];
    const all = loadedCount >= items.length ? items : take(items, loadedCount);
    const chunkCount = determineChunks(ui);
    return (
      <BaseContainer>
        {((currentCompany && (!companyProfile.profile || !companyProfile.profile.publishedAt)) ||
          publishedCount <= 0) && <SourcingProfileBuilder {...profileBuilderProps} />}
        {totalCount > 0 && (
          <div>
            <Sticky top={HEADER_SELECTOR} innerZ={100} activeClass="sticky-active">
              <div className="opportunities-bar">
                <div className="container-flex-row ai-center fw-yes" style={{ margin: '-8px' }}>
                  <div className="mui-padded flex1">
                    <SearchField
                      onValueChange={this.handleChangeFullText}
                      value={filter.fullText || ''}
                      debounce={300}
                      placeholder={messages.search}
                    />
                  </div>
                  <div className="mui-padded">
                    <Tooltip content={messages.createButtonTooltip}>
                      <Button
                        raised
                        secondary
                        containerElement={<Link to={NEW_OPPORTUNITY_URL} />}
                        icon={<i className="material-icons text-white">add</i>}
                        label={messages.createButtonLabel}
                        style={{ height: '48px', lineHeight: '48px' }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </Sticky>
            {items.length === 0 && <div className="text-center text-muted well">{messages.noOpportunitiesMessage}</div>}
            {renderGroup(
              all,
              chunkCount,
              intl,
              currentTalentMarketPlan,
              messages.group1Title,
              this.handleTogglePublished,
              this.handleDelete
            )}
            {/*{renderGroup(*/}
            {/*  rest,*/}
            {/*  chunkCount,*/}
            {/*  intl,*/}
            {/*  currentTalentMarketPlan,*/}
            {/*  messages.group2Title,*/}
            {/*  this.handleTogglePublished*/}
            {/*)}*/}
            <VisSensor
              key={`${currentCompany ? currentCompany.slug : 'allCompanies'}-${loadedCount}-${filter.fullText || ''}`}
              partialVisibility
              offset={{ bottom: -100 }}
              onChange={visible => visible && canLoadMore && this.handleLoadMore()}
            >
              <div style={{ minHeight: '1px', width: '100%' }} />
            </VisSensor>
          </div>
        )}
      </BaseContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    intl,
    ui,
    entities: {
      currentCompany: { item, talentMarketPlan },
      companyProfile
    }
  } = state;
  return {
    opportunities: selectOpportunitiesSplitByRegistrable(state, ownProps),
    auth: selectAccountPermissions(state, ownProps),
    intl,
    ui,
    companyProfile,
    currentCompany: item,
    currentTalentMarketPlan: talentMarketPlan
  };
};

const actions = {
  ...opportunityActions,
  getCompanyProfile: companyActions.getCompanyProfile,
  unsetCompanyProfile: companyActions.unsetCompanyProfile,
  switchModal: modalActions.switchModal
};

export default withRouter(connect(mapStateToProps, actions)(Opportunities));
